import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { FaCompass, FaPlus, FaEdit } from 'react-icons/fa';

import PkCard from '../Cards/PKCard';
import DynamicTable from '../Utils/dynamicTable';
import { Button, Container } from 'react-bootstrap';

const LocationsComponent = () => {
    const unsortedLocations = useSelector((state) => state.companies.currentCompany.locations);
    const [locations, setLocations] = useState();

    useEffect(() => {
        if (unsortedLocations) {
            const sortedLocations = [...unsortedLocations].sort((a, b) => {
                if (a.name === 'Default') return -1;
                if (b.name === 'Default') return 1;
                return a.name.localeCompare(b.name);
            });
            const locations = sortedLocations.map(({ name, guid, companyGuid, cidrs }) => {
                return { name, guid, companyGuid, cidrs };
            });
            setLocations(locations);
        }
    }, [unsortedLocations]);

    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <PkCard
            title={
                <>
                    <FaCompass /> Locations
                </>
            }
            body={
                <div className="ml-10 table-responsive">
                    {locations && locations.length > 0 ? (
                        <DynamicTable
                            style={{ marginLeft: '20%' }}
                            data={locations}
                            linkTo={`${currentPath}/location`}
                            icon={<FaEdit />}
                        />
                    ) : (
                        <div className="d-flex align-items-center justify-content-center">
                            No Locations are configured.
                        </div>
                    )}
                </div>
            }
            footer={
                <Container>
                    <Link
                        to={{
                            pathname: `${currentPath}/location/new`,
                            state: { returnPath: currentPath }
                        }}>
                        <Button variant="success" size="sm">
                            <FaPlus />
                            Add New Location
                        </Button>
                    </Link>
                </Container>
            }
        />
    );
};

export default LocationsComponent;
