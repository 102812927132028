import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, render, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    render ? (
                        render(props)
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

export default PrivateRoute;
