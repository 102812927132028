import { createSlice } from '@reduxjs/toolkit';

const blankCompany = {
    guid: '',
    name: '',
    domain: '',
    users: {}
};
const initialState = {
    companies: [],
    currentCompany: blankCompany
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        clearCompany(state) {
            state.currentCompany = blankCompany;
        },
        getCompanies(state, action) {
            state.companies = action.payload;
        },
        selectCompany(state, action) {
            const providers = state.currentCompany?.serviceProviders;
            const locations = state.currentCompany?.locations;
            const users = state.currentCompany?.users;
            state.currentCompany = action.payload;
            if (providers) {
                state.currentCompany.serviceProviders = providers;
            }
            if (locations) {
                state.currentCompany.locations = locations;
            }
            if (users) {
                state.currentCompany.users = users;
            }
        },
        fetchCompanyUsers(state, action) {
            if (!state.currentCompany.users.indices) {
                state.currentCompany.users.indices = [null];
            }
            if (action.payload.isPrevious) {
                if (!action.payload.indexUsed) {
                    //we can't go further back
                    state.currentCompany.users.indices = [null];
                } else {
                    state.currentCompany.users.indices.splice(-2);
                }
            } else if (!action.payload.indexUsed) {
                state.currentCompany.users.indices = [null];
            }
            state.currentCompany.users.indices.push(action.payload.nextIndex);
            state.currentCompany.users.records = action.payload.users;
        },
        fetchCompanyUserUploads(state, action) {
            state.currentCompany.userUploads = action.payload;
        },
        fetchCompanyUserUpload(state, action) {
            const upload = state.currentCompany.userUploads.find(
                (upload) => upload.guid === action.payload.guid
            );
            if (upload) {
                upload.status = action.payload.status;
            }
        },
        createUser(state, action) {
            if (!state.currentCompany.users.records) {
                state.currentCompany.users.records = [];
            }
            state.currentCompany.users.records.push(action.payload);
        },
        createCompany(state, action) {
            state.companies.push(action.payload);
            state.currentCompany = { guid: action.payload.guid, users: [] };
        },
        editCompany(state, action) {
            let index = state.companies.findIndex(
                (company) => company.guid === action.payload.guid
            );
            if (index !== -1) {
                state.companies[index] = action.payload;
            }
            state.currentCompany = action.payload;
        },
        editCompanyUserProfileInfo(state, action) {
            const company = state.companies.find((company) => company.guid === action.payload.guid);
            if (company) {
                company.userProfileInfo = action.payload.userProfileInfo;
            }
            //sanity check
            if (state.currentCompany.guid === action.payload.guid) {
                state.currentCompany.userProfileInfo = action.payload.userProfileInfo;
            }
        },
        createLocation(state, action) {
            if (!state.currentCompany.locations) {
                state.currentCompany.locations = [];
            }
            state.currentCompany.locations.push(action.payload);
        },
        getLocations(state, action) {
            state.currentCompany.locations = action.payload;
        },
        updateLocation(state, action) {
            const { name, guid, cidrs } = action.payload;
            // Find the location in the state's currentCompany.locations array
            const updatedLocations = state.currentCompany.locations.map((location) => {
                if (location.guid === guid) {
                    // Update the location's properties
                    return {
                        ...location,
                        name,
                        cidrs
                    };
                }
                return location;
            });
            // Return the updated state
            state.currentCompany.locations = updatedLocations;
        },
        deleteLocation(state, action) {
            const updatedLocationsAfterDelete = state.currentCompany.locations.filter(
                (location) => location.guid !== action.payload
            );
            state.currentCompany.locations = updatedLocationsAfterDelete;
        },
        createServiceProvider(state, action) {
            if (!state.currentCompany.serviceProviders) {
                state.currentCompany.serviceProviders = [];
            }
            state.currentCompany.serviceProviders.push(action.payload);
        },
        getServiceProviders(state, action) {
            state.currentCompany.serviceProviders = action.payload;
        }
    }
});

export const actions = companySlice.actions;
export default companySlice.reducer;
