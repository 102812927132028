import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { createUsers } from '../../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

import ability from '../../../ability';

import { Container, Form, FormGroup, Button, Row, Alert } from 'react-bootstrap';
import TopNav from '../../../components/Navigation/TopNav';
import PkCard from '../../../components/Cards/PKCard';
import { FaBackward, FaFileDownload, FaUser, FaInfoCircle, FaTimesCircle } from 'react-icons/fa';
import { fetchUserUploads } from '../../../actions/userActions';
import DynamicTable from '../../../components/Utils/dynamicTable';
import { ScaleLoader } from 'react-spinners';

const NewUsers = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const company = useSelector((state) => state.companies.currentCompany);

    const downloadCSVTemplate = () => {
        // Create CSV Template
        const customProfileFields = company.userProfileInfo;
        const standardFields = ['FirstName', 'LastName', 'Email'];
        let hasAlternate = false;
        const formattedCustomFields = customProfileFields.map((obj) => {
            const key = obj.key.charAt(0).toUpperCase() + obj.key.slice(1);
            if (key === 'AlternateEmail') {
                hasAlternate = true;
            }
            return key;
        });
        if (!hasAlternate) {
            standardFields.push('AlternateEmail');
        }

        const profileFields = [...standardFields, ...formattedCustomFields];
        const csvContent = profileFields.join(',');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'bulkuploadtemplate.csv'; // You can specify the filename here
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    // const history = useHistory();
    const priorPagePath = location?.state?.returnPath;
    const backTo = location?.state?.backTo;

    const [errors, setErrors] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userUploads, setUserUploads] = useState();
    const [pendingUploads, setPendingUploads] = useState([]);

    const [alert, setAlert] = useState(null);
    const [alertVisible, setAlertVisible] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        document.title = `Admin - ${company?.name} User Uploads`;
        if (company?.guid) {
            dispatch(fetchUserUploads(company.guid));
        }
    }, [dispatch, company.guid, company.name]);

    useEffect(() => {
        if (alertVisible) {
            const timeout = setTimeout(() => {
                setAlertVisible(false);
                setAlert(null);
            }, 5000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [alertVisible]);

    useEffect(() => {
        if (company.userUploads) {
            setUserUploads(company.userUploads);
            const pendingUploads = company.userUploads.filter(
                (upload) => upload.status === 'pending'
            );
            setPendingUploads(pendingUploads);
        }
    }, [company.userUploads]);

    const checkStatus = useCallback(async (companyGuid, dispatch) => {
        setTimeout(() => {
            dispatch(fetchUserUploads(companyGuid));
        }, 5000);
    }, []);

    useEffect(() => {
        if (pendingUploads?.length > 0) {
            // if (pollTimeout) {
            //     clearTimeout(pollTimeout);
            // }
            checkStatus(company.guid, dispatch);
        }
    }, [pendingUploads, company.guid, checkStatus, dispatch]);

    useEffect(() => {
        if (userUploads) {
            setLoading(false);
        }
    }, [userUploads]);

    // Change Handlers

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // Submit Handler

    const handleSubmit = async (e) => {
        e.preventDefault();
        formRef.current.reset();
        if (selectedFile) {
            try {
                await createUsers(selectedFile, company.guid);

                const alert = {
                    message: `Successfully uploaded file.`,
                    variant: 'success'
                };
                // history.push(priorPagePath, { alert });
                // setAlert(alert);
                // setAlertVisible(true);
                dispatch(fetchUserUploads(company.guid));
            } catch (error) {
                console.log(error);
                if (error.response?.status === 422 && error.response?.data?.message) {
                    setErrors({ email: error.response.data.message });
                }
            }
        }
    };

    const handleDownload = (filename, data, type, evt) => {
        evt.preventDefault();
        const blob = new Blob([data], { type });

        // Generate a URL for the blob
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // Append the link to the document and click it
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const shouldDisplayDownload = (upload) => {
        return upload.status === 'errors' && upload.failedRecords?.length > 0;
    };

    const selectedUserUpload = (upload, evt) => {
        //create and download csv with errors
        if (upload.failedRecords.length === 0) {
            return;
        }
        //flatten objects
        // const flat = upload.failedRecords.map((obj) => {});
        const headers = Object.keys(upload.failedRecords[0].record);
        headers.push('Error');

        const csv = [
            headers.join(','),
            ...upload.failedRecords.map((obj) =>
                headers
                    .map((key) => {
                        if (key === 'Error') {
                            return obj.error;
                        } else {
                            return obj.record[key];
                        }
                    })
                    .join(',')
            )
        ].join('\n');
        handleDownload('erroredRecords.csv', csv, 'text/csv', evt);
    };

    const authorized = ability.can('actAs', 'superAdmin') || ability.can('actAs', 'admin');

    // Help Content

    const [showHelpContent, setShowHelpContent] = useState(false);
    const handleHelpClose = () => {
        setShowHelpContent(false);
    };

    const helpContent = (
        <>
            <PkCard
                title={<div className="col text-left">Bulk Upload Users Help</div>}
                link={
                    <span onClick={() => handleHelpClose()}>
                        <FaTimesCircle color="red" />
                    </span>
                }>
                <p> Before uploading users you will need to create a CSV file.</p>
                <p>
                    When creating the file you must include all required values for each user. If a
                    required value is missing from a user row then the user will not be added.
                </p>
                <p>
                    Upon submitting the file the system will process any valid records. If the file
                    or any individual records are invalid you will see an indicator. Review the
                    provide file to assess the error.
                </p>
                <p>
                    {' '}
                    Download a blank template on your existing user profile fields:{' '}
                    <span className="text-primary" onClick={downloadCSVTemplate}>
                        <FaFileDownload /> Download CSV
                    </span>
                </p>
                <p>
                    {' '}
                    For more information, visit{' '}
                    <a
                        target="blank"
                        href="https://help.privakey.com/ssoadministration/addingUsers/">
                        Privakey SSO Support - Add / Manage Users
                    </a>
                </p>
            </PkCard>
        </>
    );

    return (
        <>
            <TopNav />
            <Container className="main-container">
                <div className="d-flex justify-content-between mt-5 mb-5">
                    <div>
                        <h4>
                            <FaUser /> Add New Users to the {company.name} SSO.
                        </h4>
                    </div>
                    <div className="text-right">
                        <Link to={priorPagePath}>
                            <>
                                <FaBackward /> Back{backTo && ` to ${backTo} `}
                            </>
                        </Link>
                    </div>
                </div>
                {authorized && (
                    <>
                        {showHelpContent ? (
                            helpContent
                        ) : (
                            <>
                                <PkCard
                                    link={
                                        <span onClick={() => setShowHelpContent(true)}>
                                            <FaInfoCircle />
                                        </span>
                                    }
                                    title="Bulk Upload Users">
                                    {alertVisible && alert && (
                                        <Alert variant={alert.variant}>{alert.message}</Alert>
                                    )}
                                    <Row>
                                        <h5> Steps.</h5>
                                        <div className="ms-2">
                                            <ol>
                                                <li>
                                                    {' '}
                                                    Download a blank template of your existing user
                                                    profile fields:{' '}
                                                    <span
                                                        style={{ cursor: 'pointer' }}
                                                        className="text-primary"
                                                        onClick={downloadCSVTemplate}>
                                                        <FaFileDownload /> Download CSV Template
                                                    </span>
                                                </li>
                                                <li>
                                                    Populate CSV with the user's you'd like to add.
                                                    Make sure to provide values for all required
                                                    values (including FirstName, LastName, Email,
                                                    and any custom fields you have specified as
                                                    required.).
                                                </li>
                                                <li>
                                                    Upload list by choosing the file and clicking
                                                    Add Users.
                                                </li>
                                                <li>
                                                    Review and remediate any errors. Any valid users
                                                    will be added and invalid users will be included
                                                    in an output file indicating why they were not
                                                    added.
                                                </li>
                                            </ol>
                                        </div>

                                        <Form
                                            className="ms-4"
                                            onSubmit={handleSubmit}
                                            ref={formRef}>
                                            <FormGroup>
                                                <Form.Control
                                                    type="file"
                                                    id="users"
                                                    name="users"
                                                    onChange={handleFileChange}
                                                />
                                            </FormGroup>
                                            <Button className="mt-3" type="submit">
                                                Add Users
                                            </Button>
                                            {'   '}
                                            <Button
                                                className="mt-3"
                                                variant="outline-danger"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    formRef.current.reset();
                                                }}>
                                                Cancel
                                            </Button>
                                        </Form>
                                    </Row>
                                    <Row>
                                        {!loading ? (
                                            <>
                                                {userUploads?.length > 0 && (
                                                    <>
                                                        <p></p>
                                                        <hr></hr>
                                                        <h6>Uploads</h6>
                                                        <DynamicTable
                                                            useHeaders={true}
                                                            style={{ marginLeft: '20%' }}
                                                            data={userUploads}
                                                            selectedColumns={[
                                                                'createdDate',
                                                                'lastModifiedDate',
                                                                'processedRecordCount',
                                                                'failedRecordCount',
                                                                'status'
                                                            ]}
                                                            columnHeaderMap={{
                                                                processedRecordCount: 'addedUsers',
                                                                failedRecordCount: 'badRecords'
                                                            }}
                                                            columnValueMap={{
                                                                status: {
                                                                    errors: 'Errors Found',
                                                                    allerrors:
                                                                        'All Records Invalid',
                                                                    pending: 'Pending',
                                                                    complete: 'Processed',
                                                                    failed: 'Invalid CSV'
                                                                }
                                                            }}
                                                            action={{
                                                                action: selectedUserUpload,
                                                                check: shouldDisplayDownload
                                                            }}
                                                            icon={<FaFileDownload />}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-center">
                                                <ScaleLoader />
                                            </div>
                                        )}
                                    </Row>
                                </PkCard>
                            </>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default NewUsers;
