import axios from 'axios';
import { getGuidFromUrl } from './utils';

import { actions as companyReducerActions } from '../reducers/companyReducer';

export const getCompanies = () => async (dispatch) => {
    const res = await axios.get(`/companies`);
    if (res.status === 200) {
        dispatch(companyReducerActions.getCompanies(res.data));
    }
    return res;
};

export const getCompany = (guid) => async (dispatch) => {
    const res = await axios.get(`/companies/${guid}`);
    if (res.status === 200) {
        dispatch(companyReducerActions.selectCompany(res.data));
    }
    return res;
};

export const validateDomain = async (guid) => {
    const res = await axios.get(`/companies/${guid}/validate`);
    return res.status === 200 && res.data.verified;
};

export const fetchCompanyUsers =
    (companyGuid, maxUsers = 10, index = null, isPrevious = false) =>
    async (dispatch) => {
        const res = await axios.get(
            `/companies/${companyGuid}/users?maxUsers=${maxUsers}${
                index ? `&index=${encodeURIComponent(JSON.stringify(index))}` : ''
            }`
        );
        if (res.status === 200) {
            const data = res.data;
            data.isPrevious = isPrevious;
            data.indexUsed = index;
            dispatch(companyReducerActions.fetchCompanyUsers(data));
        }
        return res;
    };

export const createCompany = (name, domain, seats) => async (dispatch) => {
    let postData = { name, domain, seats };
    const res = await axios.post(`/companies/new`, postData);
    if (res.status === 201) {
        let guid = getGuidFromUrl(res.headers.location);
        console.log(res.headers);
        dispatch(companyReducerActions.createCompany({ name, guid, domain, seats }));
        return guid;
    }
    return null;
};

export const createSelfSignupCompany =
    (name, domain, seats, firstName, lastName, email, phone) => async (dispatch) => {
        let postData = { name, domain, firstName, lastName, email, phone };
        const res = await axios.post(`/companies/newSelf`, postData);
        if (res.status === 201) {
            let guid = getGuidFromUrl(res.headers.location);
            console.log(res.headers);
            dispatch(companyReducerActions.createCompany({ name, guid, domain, seats }));
            return guid;
        }
        return null;
    };

export const editCompany = (guid, name) => async (dispatch) => {
    let patchData = { name };
    const res = await axios.patch(`/companies/${guid}`, patchData);
    if (res.status === 204) {
        let payload = { guid, name };
        dispatch(companyReducerActions.editCompany(payload));
    }
    return res;
};

export const editCompanyUserProfileInfo = (guid, userProfileInfo) => async (dispatch) => {
    let patchData = { userProfileInfo };
    const res = await axios.patch(`/companies/${guid}/userProfileInfo`, patchData);
    if (res.status === 204) {
        let payload = { guid, userProfileInfo };
        dispatch(companyReducerActions.editCompanyUserProfileInfo(payload));
    }
    return res;
};

export const createLocation = (name, cidrs, providers, companyGuid) => async (dispatch) => {
    let postData = { name, cidrs };

    if (providers) {
        postData.serviceProviders = providers;
    }
    console.log('Post Data from create Location', postData);

    try {
        const response = await axios.post(`/companies/${companyGuid}/locations/new`, postData);
        const location = response.headers.location;
        const locationGuid = location.substring(location.lastIndexOf('/') + 1);
        dispatch(
            companyReducerActions.createLocation({ name, companyGuid, guid: locationGuid, cidrs })
        );
    } catch (error) {
        // Dispatch error action
        console.log(error);
    }
};
export const updateLocation =
    (name, cidrs, providers, companyGuid, locationGuid) => async (dispatch) => {
        let postData = { name, cidrs };

        if (providers) {
            console.log('Providers from Update Action', providers);
            postData.serviceProviders = providers;
        }

        try {
            await axios.patch(`/companies/${companyGuid}/locations/${locationGuid}`, postData);
            dispatch(
                companyReducerActions.updateLocation({ name, guid: locationGuid, cidrs, providers })
            );
        } catch (error) {
            // Dispatch error action
            console.log(error);
        }
    };

export const deleteLocation = (companyGuid, locationGuid) => (dispatch) => {
    try {
        axios.delete(`companies/${companyGuid}/locations/${locationGuid}`).then((res) => {
            dispatch(companyReducerActions.deleteLocation(locationGuid));
        });
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};
export const getMetaData = async (companyGuid) => {
    try {
        const url = `/idp/${companyGuid}/metadata`;
        const res = await axios.request({
            method: 'get',
            url: url,
            baseURL: '',
            responseType: 'text'
        });
        const xml = {
            url,
            xml: res.data
        };
        return xml;
    } catch (error) {
        console.error('Error retrieving XML data: ', error);
    }
};

export const getCompanyServiceProvider = async (companyGuid, serviceProviderGuid) => {
    try {
        const res = await axios.get(
            `/companies/${companyGuid}/serviceProviders/${serviceProviderGuid}`
        );
        return res.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};

export const configureServiceProvider = async ({
    name,
    systemServiceProviderGuid,
    configuration,
    locations,
    companyGuid,
    status
}) => {
    let postData = {
        name,
        systemServiceProviderGuid,
        configuration,
        configured: true,
        status,
        locations
    };

    console.log(postData);

    try {
        await axios.post(`/companies/${companyGuid}/serviceProviders/new`, postData);
    } catch (error) {
        // Dispatch error action
        console.log(error);
    }
};

export const updateServiceProvider = async ({
    configuration,
    locations,
    companyGuid,
    companyServiceProviderGuid,
    status
}) => {
    let patchData = {
        configuration,
        locations,
        status
    };
    try {
        await axios.patch(
            `/companies/${companyGuid}/serviceProviders/${companyServiceProviderGuid}`,
            patchData
        );
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};

export const getCompanyServiceProviders = (companyGuid) => async (dispatch) => {
    try {
        const res = await axios.get(`/companies/${companyGuid}/serviceProviders/`);
        dispatch(companyReducerActions.getServiceProviders(res.data));
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCompanyLocations = (companyGuid) => async (dispatch) => {
    try {
        const res = await axios.get(`/companies/${companyGuid}/locations/`);
        dispatch(companyReducerActions.getLocations(res.data));
        return res.data;
    } catch (error) {
        return console.log(error);
    }
};

export const getCompanyLocation = async (companyGuid, locationGuid) => {
    try {
        const res = await axios.get(`/companies/${companyGuid}/locations/${locationGuid}`);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const getPaymentSecret = async (companyGuid) => {
    try {
        const res = await axios.get(`/companies/${companyGuid}/paymentSecret`);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const savePaymentInfo = (companyGuid, userGuid, paymentInfo) => async (dispatch) => {
    let patchData = { userGuid, paymentInfo };
    const res = await axios.post(`/companies/${companyGuid}/paymentInfo`, patchData);
    if (res.status === 204) {
        dispatch(getCompany(companyGuid));
    }
    return res;
};

export const deletePaymentInfo = (companyGuid, userGuid) => async (dispatch) => {
    let patchData = { userGuid };
    const res = await axios.delete(`/companies/${companyGuid}/paymentInfo`, patchData);
    if (res.status === 204) {
        dispatch(getCompany(companyGuid));
    }
    return res;
};
