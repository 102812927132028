import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PkCard from '../../../components/Cards/PKCard';
import DynamicTable from '../../../components/Utils/dynamicTable';
import { ScaleLoader } from 'react-spinners';
import { useLocation, useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Alert, Button } from 'react-bootstrap';
import { FaPlus, FaEye, FaCog, FaUserPlus, FaUsers } from 'react-icons/fa';

import TopNav from '../../../components/Navigation/TopNav';

import { fetchCompanyUsers } from '../../../actions/companyActions';
import { actions as userActions } from '../../../reducers/userReducer';

function Users() {
    const dispatch = useDispatch();
    const currentCompany = useSelector((state) => state.companies.currentCompany);
    const companyGuid = useSelector((state) => state.companies.currentCompany.guid);
    const companyName = useSelector((state) => state.companies.currentCompany.name);
    const rawUsers = useSelector((state) => state.companies.currentCompany.users.records);
    const indices = useSelector((state) => state.companies.currentCompany.users.indices);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const currentPath = location.pathname + location.search;
    const state = { returnPath: currentPath, backTo: 'User List' };
    const paymentOnFile = currentCompany?.paymentInfo?.stripeInfo?.hasPaymentMethod ? true : false;
    const canAddUser = currentCompany.userCount < 6 || paymentOnFile;

    const alert = location.state?.alert;
    const [alertVisible, setAlertVisible] = useState(true);

    const [users, setUsers] = useState();

    useEffect(() => {
        dispatch(userActions.clear());
    }, [dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAlertVisible(false);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        document.title = `Admin - ${companyName} Users`;
        if (companyGuid) {
            dispatch(fetchCompanyUsers(companyGuid, 10, null));
        }
    }, [dispatch, companyGuid, companyName]);

    useEffect(() => {
        if (users) {
            setLoading(false);
        }
    }, [users]);

    // Exclude Profile Oject from List View
    useEffect(() => {
        if (rawUsers) {
            const newArrayWithoutProfile = rawUsers.map((obj) => {
                const { profile, ...newOjb } = obj;
                return newOjb;
            });
            setUsers(newArrayWithoutProfile);
        }
    }, [rawUsers]);

    const selectedUser = async (user) => {
        await dispatch(userActions.select({ companyGuid: user.companyGuid, guid: user.guid }));
        history.push('/admin/users/view');
    };

    const hasNext = () => {
        return indices?.length > 1 && indices[indices.length - 1];
    };

    const getNext = (evt) => {
        evt.preventDefault();
        if (companyGuid && hasNext()) {
            const index = indices.length > 1 && indices[indices.length - 1];
            dispatch(fetchCompanyUsers(companyGuid, 10, index));
        }
    };

    const hasPrevious = () => {
        return indices?.length > 2;
    };

    const getPrevious = (evt) => {
        evt.preventDefault();
        if (companyGuid && hasPrevious()) {
            const index = indices.length > 2 && indices[indices.length - 3];
            dispatch(fetchCompanyUsers(companyGuid, 10, index, true));
        }
    };

    return (
        <>
            <TopNav />
            <Container className="main-container">
                <h4 className="mt-5 mb-5">{companyName} Admin - Manage Users</h4>
                {!loading ? (
                    <PkCard
                        className="w-100"
                        title={<>Users</>}
                        link={
                            <Link to={{ pathname: '/admin/users/settings', state: state }}>
                                <>
                                    <FaCog /> User Profile Settings
                                </>
                            </Link>
                        }
                        footer={
                            <>
                                <div className="d-flex justify-content-start">
                                    <div className="ms-4">
                                        <Link
                                            to={{
                                                pathname: `/admin/users/new`,
                                                state: state
                                            }}
                                            onClick={(e) => !canAddUser && e.preventDefault()}>
                                            <>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    disabled={!canAddUser}>
                                                    {' '}
                                                    <FaUserPlus /> Add A User
                                                </Button>
                                            </>
                                        </Link>
                                    </div>
                                    <div className="ms-4 me-4">
                                        <Link
                                            to={{
                                                pathname: '/admin/users/bulk',
                                                state: state
                                            }}
                                            onClick={(e) => !paymentOnFile && e.preventDefault()}>
                                            <>
                                                <Button
                                                    variant="outline-success"
                                                    size="sm"
                                                    disabled={!paymentOnFile}>
                                                    {' '}
                                                    <FaUsers />
                                                    <FaPlus size="6px" /> Bulk Add Users
                                                </Button>
                                            </>
                                        </Link>
                                    </div>
                                </div>
                                {(!paymentOnFile || !canAddUser) && (
                                    <div className="ms-4 pt-2">
                                        <p className="p-1 border border-danger">
                                            <strong className="text-danger">Note:</strong>{' '}
                                            {!paymentOnFile && 'Bulk Add Users'}{' '}
                                            {!paymentOnFile && !canAddUser && 'and'}{' '}
                                            {!canAddUser && 'Add a User'} functionality is disabled
                                            until a Payment Method is entered.{' '}
                                            <Link to="/admin#payment">Enter Payment Info</Link>
                                        </p>
                                    </div>
                                )}
                            </>
                        }>
                        {alertVisible && alert && (
                            <Alert variant={alert.variant}>{alert.message}</Alert>
                        )}
                        <Row>
                            <DynamicTable
                                // style={{ marginLeft: '20%' }}
                                data={users}
                                useHeaders={true}
                                action={selectedUser}
                                icon={<FaEye />}
                                hasNext={hasNext}
                                getNext={getNext}
                                hasPrevious={hasPrevious}
                                getPrevious={getPrevious}
                            />
                        </Row>
                    </PkCard>
                ) : (
                    <div className="d-flex align-items-center justify-content-center">
                        <ScaleLoader />
                    </div>
                )}
            </Container>
        </>
    );
}

export default Users;
