import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Navbar } from 'react-bootstrap';
import logo from '../images/securedByPrivakeyNoKeyhole.png';
import PkCard from '../components/Cards/PKCard';

function ErrorPage(props) {
    let pageTitle = '';
    let errorMessage = '';
    console.log(props);
    const history = useHistory();
    const handleGoHome = () => {
        history.push('/');
    };
    switch (props.type) {
        case 401:
            pageTitle = '401: Unauthorized';
            errorMessage = (
                <>
                    <p>Whoa! You are not authorized to access that page.</p>
                    <p>Go back home and behave next time.</p>
                </>
            );
            break;
        case 404:
            if (props.subType === 'spError') {
                pageTitle = '404: Service Provider Not Configured';
                errorMessage = (
                    <>
                        <p>
                            It looks like the service you are trying to reach is not configured or
                            is misconfigured.
                        </p>
                        <p>Your administrator must review the Service Provider Configuration.</p>
                    </>
                );
            } else {
                pageTitle = '404: Page Not Found';
                errorMessage = (
                    <>
                        <p>We tried, but we could not find that page.</p>
                        <p>Go back home and try again.</p>
                    </>
                );
            }
            break;
        // Add more cases for other types if needed
        default:
            pageTitle = 'Something went wrong';
            errorMessage = 'An unknown error occurred.';
            break;
    }

    return (
        <body className="d-flex align-items-center vh-66">
            <Container>
                <Navbar className="pk-navbar" fixed="top">
                    <Container
                        fluid
                        className="pk-navbar-container d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center">
                            <img src={logo} height="40" alt="Secured by Privakey" />
                        </div>
                    </Container>
                </Navbar>
                <PkCard
                    title={<h2 className="pk-text">{pageTitle}</h2>}
                    className="pk-card"
                    border="danger">
                    <Card.Body>
                        <Card.Text>
                            <p>{errorMessage}</p>

                            <Button onClick={handleGoHome} variant="primary">
                                Go Home
                            </Button>
                        </Card.Text>
                    </Card.Body>
                </PkCard>
            </Container>
        </body>
    );
}

export default ErrorPage;
