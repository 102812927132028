import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Modal, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { FaCopy, FaDownload, FaFile, FaQuestionCircle } from 'react-icons/fa';
import PkCard from '../Cards/PKCard';
import logo from '../../images/securedByPrivakeyNoKeyhole.png';

import {
    configureServiceProvider,
    updateServiceProvider,
    getCompanyServiceProviders,
    getCompanyServiceProvider
} from '../../actions/companyActions';

function ConfigureServiceProviderModal({ show, handleClose, selectedProvider }) {
    const dispatch = useDispatch();
    const companyLocations = useSelector((state) => state.companies.currentCompany.locations);

    const companyGuid = useSelector((state) => state.companies.currentCompany.guid);
    const companyUserProfileInfo = useSelector(
        (state) => state.companies.currentCompany.userProfileInfo
    );

    const [formValues, setFormValues] = useState({});
    const [mapValues, setMapValues] = useState({});
    const [formDefaults, setFormDefaults] = useState({});
    const [serviceProviderData, setServiceProviderData] = useState();
    const [formLocations, setFormLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState(null);
    const [metadataName, setMetadataName] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedProvider?.companyProviderGuid) {
                try {
                    const queriedCompanyServiceProvider = await getCompanyServiceProvider(
                        companyGuid,
                        selectedProvider?.companyProviderGuid
                    );
                    setFormLocations(queriedCompanyServiceProvider.locations);
                    setLoading(false);
                } catch (error) {
                    console.error('Error while querying company service provider:', error);
                }
            } else if (companyLocations) {
                const configuredLocations = companyLocations.map(({ name, guid }) => ({
                    name,
                    guid,
                    isSSOAllowed: true
                }));
                setFormLocations(configuredLocations);
                setLoading(false);
            }
        };
        setLoading(true);
        fetchData();
    }, [companyLocations, companyGuid, selectedProvider]);

    useEffect(() => {
        if (selectedProvider?.companyProviderGuid) {
            selectedProvider.configuration.entryRequired.map((entry) => {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    [entry.field]: entry.value
                }));
                return entry;
            });

            if (selectedProvider.configuration.userMapping) {
                Object.keys(selectedProvider.configuration.userMapping).forEach((key) => {
                    setMapValues((prevValues) => ({
                        ...prevValues,
                        [key]: selectedProvider.configuration.userMapping[key].value
                    }));
                });
            }
        } else if (selectedProvider) {
            selectedProvider.configuration.entryRequired.map((entry) => {
                setFormDefaults((prevValues) => ({
                    ...prevValues,
                    [entry.field]: `[${entry.displayName}]`
                }));
                return entry;
            });

            if (selectedProvider.configuration.userMapping) {
                Object.keys(selectedProvider.configuration.userMapping).forEach((key) => {
                    setMapValues((prevValues) => ({
                        ...prevValues,
                        [key]: selectedProvider.configuration.userMapping[key].value
                    }));
                });
            }
        }
    }, [selectedProvider]);

    useEffect(() => {
        const initialSamlElements = selectedProvider?.configuration?.samlElements || [];
        const serviceProviderDataToSet = initialSamlElements.map((samlElement) => ({
            serviceProviderDisplayName: samlElement.serviceProviderDisplayName,
            serviceProviderConfig: replaceStringVars(
                samlElement.serviceProviderConfig,
                formValues,
                formDefaults
            )
        }));
        setServiceProviderData(serviceProviderDataToSet);
    }, [selectedProvider, formValues, formDefaults, show]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleValueSelect = (key, value) => {
        // Logic to update the value when a dropdown value is selected
        // For example, update the 'selectedProvider.configuration.userMapping' object

        setMapValues((prevValues) => ({
            ...prevValues,
            [key]: value
        }));
    };

    const copyToClipboard = (content) => () => {
        navigator.clipboard.writeText(content);
    };

    const handleDownload = (filename, data, type) => (evt) => {
        evt.preventDefault();
        const blob = new Blob([data], { type });

        // Generate a URL for the blob
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // Append the link to the document and click it
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleFileRead = (event) => {
        const content = event.target.result;
        setMetadata(content);
        console.log(content); // Do something with the file content
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setMetadataName(file.name);
            const reader = new FileReader();

            reader.onload = handleFileRead;
            reader.readAsText(file);
        }
    };

    const handleCheckboxChange = (guid) => {
        setFormLocations((prevLocations) =>
            prevLocations.map((location) =>
                location.guid === guid
                    ? { ...location, isSSOAllowed: !location.isSSOAllowed }
                    : location
            )
        );
    };

    // const renderTooltip = (content) => <Tooltip id="tooltip">{content}</Tooltip>;

    const replaceStringVars = (originalString, values, defaults) => {
        let re = /\${([^}]+)}/g;
        return originalString.replace(re, (_match, p1) => {
            if (values.hasOwnProperty(p1) && values[p1]) {
                return values[p1];
            } else if (defaults?.hasOwnProperty(p1)) {
                return defaults[p1] ? defaults[p1] : '';
            } else {
                return _match;
            }
        });
    };

    const handleSubmit = (status) => async (event) => {
        event.preventDefault();
        console.log(formValues);
        console.log(selectedProvider);
        let newStatus = status ? status : 'active';
        const manipulatedProvider = JSON.parse(JSON.stringify(selectedProvider));
        if (metadata) {
            manipulatedProvider.configuration.metadata.name = metadataName;
            manipulatedProvider.configuration.metadata.data = metadata;
        } else if (manipulatedProvider.configuration.metadata?.parameterizedUrl) {
            manipulatedProvider.configuration.metadata.url = replaceStringVars(
                manipulatedProvider.configuration.metadata.parameterizedUrl,
                formValues
            );
        }
        manipulatedProvider.configuration.samlElements.forEach((samlElement) => {
            samlElement.samlValue = replaceStringVars(
                samlElement.serviceProviderConfig,
                formValues
            );
        });

        manipulatedProvider.configuration.entryRequired.forEach((entry) => {
            if (formValues.hasOwnProperty(entry.field)) {
                entry.value = formValues[entry.field];
            }
        });

        if (manipulatedProvider.configuration.userMapping) {
            Object.keys(manipulatedProvider.configuration.userMapping).forEach((key) => {
                if (mapValues.hasOwnProperty(key)) {
                    manipulatedProvider.configuration.userMapping[key].value = mapValues[key];
                }
            });
        }

        if (manipulatedProvider.companyProviderGuid) {
            const patchData = {
                configuration: manipulatedProvider.configuration,
                locations: formLocations,
                companyGuid: companyGuid,
                companyServiceProviderGuid: manipulatedProvider.companyProviderGuid,
                status: newStatus
            };
            console.log('Patch Data', patchData);
            try {
                await updateServiceProvider(patchData);
            } catch (err) {
                console.log(err);
                return;
            }
        } else {
            const postData = {
                name: manipulatedProvider.name,
                systemServiceProviderGuid: manipulatedProvider.guid,
                configuration: manipulatedProvider.configuration,
                locations: formLocations,
                companyGuid: companyGuid,
                status: newStatus
            };
            console.log(postData);

            try {
                await configureServiceProvider(postData);
            } catch (err) {
                console.log(err);
                return;
            }
        }
        dispatch(getCompanyServiceProviders(companyGuid));
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setFormValues({});
        setMapValues({});
        handleClose();
    };

    const cardTitle = (
        <>
            {selectedProvider && (
                <>
                    Configure {selectedProvider.name}{' '}
                    <a
                        href={`https://help.privakey.com/ssoadministration/serviceproviderconfiguration/${selectedProvider.guid}`}
                        target="_blank"
                        rel="noreferrer">
                        <FaQuestionCircle />
                    </a>
                </>
            )}
        </>
    );

    return (
        <Modal show={show} onHide={handleCloseModal} size="xl">
            {selectedProvider && (
                <Container>
                    <Modal.Body>
                        <Container fluid>
                            <PkCard title={cardTitle}>
                                <div className="border p-3 mb-5 bg-body rounded">
                                    <Row>
                                        <Col>
                                            <p>
                                                Please provide the below required fields to enable
                                                SSO for {selectedProvider.name}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form>
                                                {selectedProvider.configuration.entryRequired.map(
                                                    (entry) => (
                                                        <div key={entry.field}>
                                                            <Form.Label htmlFor={entry.field}>
                                                                {entry.displayName}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                id={entry.field}
                                                                name={entry.field}
                                                                value={formValues[entry.field]}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                                {selectedProvider.configuration.userMapping && (
                                    <div className="border p-3 mb-5 bg-body rounded">
                                        <Row>
                                            <Col>
                                                <p>Set up mapping</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form>
                                                    {Object.keys(
                                                        selectedProvider.configuration.userMapping
                                                    ).map((key) => (
                                                        <div key={key}>
                                                            <Form.Label htmlFor={key}>
                                                                {key}:{' '}
                                                                {
                                                                    selectedProvider.configuration
                                                                        .userMapping[key]
                                                                        .description
                                                                }
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                id={key}
                                                                name={key}
                                                                value={mapValues[key]}
                                                                onChange={(e) =>
                                                                    handleValueSelect(
                                                                        key,
                                                                        e.target.value
                                                                    )
                                                                }>
                                                                <option value="">
                                                                    Company Email
                                                                </option>
                                                                {companyUserProfileInfo &&
                                                                    companyUserProfileInfo.map(
                                                                        (entry) => (
                                                                            <option
                                                                                key={entry.key}
                                                                                value={`profile:${entry.key}`}>
                                                                                {entry.displayName}
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </Form.Control>
                                                        </div>
                                                    ))}
                                                </Form>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                {selectedProvider.configuration.metadata &&
                                    typeof selectedProvider.configuration.metadata.data !==
                                        'undefined' && (
                                        <div className="border p-3 mb-5 bg-body rounded">
                                            <Row className="mt-1">
                                                <Col>
                                                    <p>
                                                        {' '}
                                                        <span className="fw-bold mb-2">
                                                            Metadata:
                                                        </span>{' '}
                                                        {selectedProvider.configuration.metadata
                                                            .name && (
                                                            <>
                                                                <FaFile />{' '}
                                                                {
                                                                    selectedProvider.configuration
                                                                        .metadata.name
                                                                }
                                                            </>
                                                        )}
                                                    </p>
                                                    <div className="ml-2" key="metadata">
                                                        {selectedProvider.configuration.metadata
                                                            .data && (
                                                            <div>
                                                                <Button
                                                                    size="sm"
                                                                    onClick={handleDownload(
                                                                        selectedProvider
                                                                            .configuration.metadata
                                                                            .name,
                                                                        selectedProvider
                                                                            .configuration.metadata
                                                                            .data,
                                                                        'text/xml'
                                                                    )}>
                                                                    <FaDownload /> Download Metadata
                                                                </Button>
                                                                {'  '}
                                                                <Button
                                                                    onClick={copyToClipboard(
                                                                        selectedProvider
                                                                            .configuration.metadata
                                                                            .data
                                                                    )}
                                                                    size="sm">
                                                                    <FaCopy /> Copy Metadata
                                                                </Button>
                                                                <p />
                                                            </div>
                                                        )}
                                                        <Form.Control
                                                            type="file"
                                                            id="metadata"
                                                            name="metadata"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                <div className="border p-3 mb-5 bg-body rounded">
                                    <Row className="mt-1">
                                        <Col>
                                            <p className="fw-bold mb-2">
                                                Service Provider Configurations:
                                            </p>
                                            <div className="ml-2">
                                                <Table responsive className="mt-2 pl-2 table-sm">
                                                    <thead>
                                                        <tr>
                                                            <td>SAML Parameter</td>
                                                            <td>Configuration</td>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {serviceProviderData?.map((data) => (
                                                            <tr
                                                                key={
                                                                    data.serviceProviderDisplayName
                                                                }>
                                                                <td>
                                                                    {
                                                                        data.serviceProviderDisplayName
                                                                    }
                                                                </td>
                                                                <td
                                                                    className="text-truncate"
                                                                    style={{
                                                                        maxWidth: '200px'
                                                                    }}>
                                                                    {data.serviceProviderConfig}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="border p-3 mb-5 bg-body rounded">
                                    <Row className="mt-1">
                                        <Col>
                                            <p className="fw-bold mb-2">Location Configurations:</p>
                                            {!loading && (
                                                <div className="ml-2">
                                                    <Table
                                                        responsive
                                                        className="mt-2 pl-2 table-sm">
                                                        <thead>
                                                            <tr>
                                                                <td>Location</td>
                                                                <td className="text-center">
                                                                    SSO Enabled?
                                                                </td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {formLocations.map((location) => (
                                                                <tr key={location.guid}>
                                                                    <td>{location.name}</td>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                location.isSSOAllowed
                                                                            }
                                                                            onChange={() =>
                                                                                handleCheckboxChange(
                                                                                    location.guid
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                {selectedProvider.status !== 'active' && (
                                    <Button
                                        onClick={handleSubmit('draft')}
                                        className="mt-2 mb-2"
                                        type="submit">
                                        {selectedProvider.status !== 'notConfigured' &&
                                        selectedProvider.status !== 'disabled'
                                            ? 'Update draft'
                                            : 'Save as draft'}
                                    </Button>
                                )}

                                <Button
                                    onClick={handleSubmit()}
                                    className="mt-2 mb-2"
                                    type="submit">
                                    {selectedProvider.status === 'active'
                                        ? 'Update'
                                        : selectedProvider.status !== 'notConfigured'
                                        ? 'Set Active'
                                        : 'Submit'}
                                </Button>
                                {selectedProvider.status === 'active' && (
                                    <Button
                                        onClick={handleSubmit('disabled')}
                                        className="mt-2 mb-2 btn-warning"
                                        type="submit">
                                        Disable Provider
                                    </Button>
                                )}
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={logo} height="40" alt="Secured by Privakey" />
                                </div>
                            </PkCard>
                        </Container>
                    </Modal.Body>
                </Container>
            )}
        </Modal>
    );
}

export default ConfigureServiceProviderModal;
