import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import DynamicTable from '../Utils/dynamicTable';
import PkCard from '../Cards/PKCard';
import { FaFingerprint, FaTrash, FaPlus, FaInfoCircle } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';
import { createNewAuthenticatorByEmail, deleteAnAuthenticator } from '../../actions/userActions';

import AddAuthenticatorModal from './AddAuthenticatorModal';
import AdminAddAuthenticatorModal from './AdminAddAuthenticatorModal';

const UserAuthenticators = ({ context }) => {
    const viewingUser = useSelector((state) => state.users.viewingUser);
    const dispatch = useDispatch();

    const [showRevocationModal, setShowRevocationModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(null);
    const valueMap = { type: { pkauth: 'Privakey', passkey: 'Passkey' } };

    useEffect(() => {
        if (viewingUser?.authenticators) {
            setLoading(false);
        }
    }, [viewingUser]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAlert(null);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [alert]);

    const handleRevoke = (item) => {
        console.log('Handle Revoke Called. Item: ', item);
        console.log(item);
        setSelectedItem(item);
        console.log('Selected Item:', selectedItem);
        setShowRevocationModal(true);
    };

    const [showAddModal, setShowAddModal] = useState(false);
    const [showAdminAddAuthenticatorModal, setShowAdminAddAuthenticatorModal] = useState(false);

    const handleAddAuthenticator = () => {
        if (context === 'accessmanager') {
            setShowAddModal(true);
        } else if (context === 'admin' || context === 'superadmin') {
            setShowAdminAddAuthenticatorModal(true);
        }
    };

    const handleCloseAddAuthenticator = () => setShowAddModal(false);

    const handleCloseAdminAddAuthenticatorModal = () => {
        setShowAdminAddAuthenticatorModal(false);
    };

    const handleAddAdminAuthenticator = () => {
        createNewAuthenticatorByEmail(viewingUser.companyGuid, viewingUser.guid);
        setAlert({
            message: `${viewingUser.firstName} ${viewingUser.lastName} has been sent an email with instructions on how to set up their new authenticator`,
            variant: 'success'
        });
        setShowAdminAddAuthenticatorModal(false);
    };

    const handleCloseRevocationModal = () => setShowRevocationModal(false);

    const handleConfirm = async () => {
        try {
            await dispatch(
                deleteAnAuthenticator(
                    selectedItem.companyGuid,
                    selectedItem.userGuid,
                    selectedItem.guid
                )
            );
            setShowRevocationModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const [showHelpContent, setShowHelpContent] = useState(false);
    const handleHelpClose = () => {
        setShowHelpContent(false);
    };

    const helpContent = (
        <>
            <PkCard
                title={<div className="col text-left">Authenticators Help</div>}
                link={<span onClick={() => handleHelpClose()}>X</span>}>
                <p> These are the current authenticators you have bound to the SSO.</p>
                <p>
                    To add a new Authenticator click on the + Add new Authenticator button. You will
                    be given the opportunity to set up a Privakey Authenticator or Passkey
                    authenticator.
                </p>
                <p>
                    To revoke an Authenticator click on the <FaTrash /> icon next to the
                    authenticator. If you delete all of your authenticators you will not be able to
                    log in. Be careful.
                </p>
                <p>
                    {' '}
                    For more information, visit{' '}
                    <a
                        target="blank"
                        href="https://support.privakey.com/usingthesso/3accessManager">
                        Privakey SSO Support - Access Manager
                    </a>
                </p>
            </PkCard>
        </>
    );

    return (
        <>
            {showHelpContent ? (
                helpContent
            ) : (
                <PkCard
                    title={
                        <>
                            <FaFingerprint /> Authenticators
                        </>
                    }
                    link={
                        <span onClick={() => setShowHelpContent(true)}>
                            <FaInfoCircle />
                        </span>
                    }
                    footer={
                        <Container>
                            <Row>
                                <Col>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={handleAddAuthenticator}>
                                        <FaPlus /> Add New Authenticator
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    }>
                    <>
                        {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
                        {viewingUser && (
                            <>
                                <AddAuthenticatorModal
                                    show={showAddModal}
                                    handleCloseAddAuthenticator={handleCloseAddAuthenticator}
                                />
                                <AdminAddAuthenticatorModal
                                    show={showAdminAddAuthenticatorModal}
                                    handleCloseAdminAddAuthenticatorModal={
                                        handleCloseAdminAddAuthenticatorModal
                                    }
                                    handleAddAdminAuthenticator={handleAddAdminAuthenticator}
                                />
                            </>
                        )}

                        {loading ? (
                            <div className="d-flex align-items-center justify-content-center">
                                <ScaleLoader />
                            </div>
                        ) : viewingUser?.authenticators?.length > 0 ? (
                            <>
                                <DynamicTable
                                    data={viewingUser.authenticators}
                                    icon={<FaTrash />}
                                    action={handleRevoke}
                                    color="danger"
                                    useHeaders={true}
                                    columnValueMap={valueMap}
                                />
                                {/* Revocation / Delete Authenticator Modal */}
                                {selectedItem && selectedItem.name && selectedItem.guid && (
                                    <Modal
                                        centered
                                        show={showRevocationModal}
                                        onHide={handleCloseRevocationModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Revocation</Modal.Title>{' '}
                                        </Modal.Header>

                                        <Modal.Body>
                                            Are You sure you want to revoke{' '}
                                            <span className="text-danger">{selectedItem.name}</span>
                                            ? If you click Revoke you will no longer be able to use
                                            this Authenticator.
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="secondary"
                                                onClick={handleCloseRevocationModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="danger" onClick={handleConfirm}>
                                                Revoke
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                )}
                            </>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                No Authenticators have been set up.
                            </div>
                        )}
                    </>
                </PkCard>
            )}
        </>
    );
};

export default UserAuthenticators;
