import React, { useEffect, useState } from 'react';
import PkCard from '../Cards/PKCard';
import { FaFingerprint, FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
const AuthenticatorsComponent = () => {
    const [maxAuthenticators, setMaxAuthenticators] = useState('5');
    const currentCompany = useSelector((state) => state.companies.currentCompany);
    useEffect(() => {
        if (currentCompany?.maxAuthenticators) {
            setMaxAuthenticators(currentCompany.maxAuthenticators);
        }
    }, [currentCompany]);

    return (
        <PkCard
            title={
                <>
                    <FaFingerprint /> Authenticators
                </>
            }
            body={
                <div>
                    <p>
                        <span className="me-3">Number of allowed Authenticators:</span>{' '}
                        <span className="me-3">{maxAuthenticators}</span>
                        <FaEdit className="blue small" />
                    </p>
                </div>
            }
        />
    );
};

export default AuthenticatorsComponent;
