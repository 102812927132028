import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PkCard from '../Cards/PKCard';
import DynamicTable from '../Utils/dynamicTable';
import { FaBoxes, FaPlus, FaEye, FaInfoCircle } from 'react-icons/fa';
import { MdOutlineClose } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

function ServiceProvidersComponent() {
    const [providers, setProviders] = useState();
    const location = useLocation();
    const providersState = useSelector((state) => state.companies.currentCompany.serviceProviders);
    const currentPath = location.pathname;
    useEffect(() => {
        if (providersState) {
            const displayProviders = providersState
                // .filter((provider) =>
                //     provider.configuration.entryRequired.every((entry) => entry.value)
                // )
                .map(({ name, guid }) => ({ name, guid }));

            setProviders(displayProviders);
        }
    }, [providersState]);

    const [showHelpContent, setShowHelpContent] = useState(false);
    const helpContent = (
        <PkCard
            title={
                <>
                    <FaBoxes /> Configured Service Providers - Help{' '}
                </>
            }
            link={
                <span style={{ cursor: 'pointer' }} onClick={() => setShowHelpContent(false)}>
                    <MdOutlineClose />
                </span>
            }>
            <p>
                {' '}
                Service Providers are the services (e.g. Google Workspace, Box, AWS, etc.) that can
                be configured to leverage the Privakey SSO.
            </p>
            <p>
                Before the SSO can be used by users, a Company Administrator must enter
                configuration inofrmation to associate the Privakey SSO to your instance of a
                particular service; in many cases it is just rquires entering a simple identifier.
            </p>
            <p>
                You can learn how to configure each available Service Provider here:{' '}
                <a href="https://help.privakey.com/ssoadministration">
                    Privakey SSO Help: SSO Administration.
                </a>
            </p>
            <p>
                To begin configuring Service Providers click{' '}
                <Link
                    to={{
                        pathname: '/admin/serviceproviders',
                        state: { returnPath: { currentPath } }
                    }}
                    onClick={() => {
                        setShowHelpContent(false);
                    }}>
                    Configure New Service Provider
                </Link>
            </p>
        </PkCard>
    );

    return (
        <>
            {showHelpContent ? (
                helpContent
            ) : (
                <PkCard
                    className="h-100"
                    title={
                        <>
                            <FaBoxes /> Configured Service Providers
                        </>
                    }
                    link={
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowHelpContent(true)}>
                            <FaInfoCircle />
                        </span>
                    }
                    footer={
                        <Container>
                            <Row>
                                <Col>
                                    <Link
                                        to={{
                                            pathname: '/admin/serviceproviders',
                                            state: { returnPath: { currentPath } }
                                        }}
                                        onClick={() => {
                                            setShowHelpContent(false);
                                        }}>
                                        <Button variant="success" size="sm">
                                            <FaPlus /> Configure New Service Provider
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    }>
                    <div className="ml-10">
                        {providers && providers.length > 0 ? (
                            <DynamicTable
                                style={{ marginLeft: '20%' }}
                                data={providers}
                                linkTo="/admin/serviceproviders"
                                icon={<FaEye />}
                            />
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                No Service Providers Configured.
                            </div>
                        )}
                    </div>
                </PkCard>
            )}
        </>
    );
}

export default ServiceProvidersComponent;
