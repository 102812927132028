import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TopNav from '../../../components/Navigation/TopNav';
import { Container } from 'react-bootstrap';
import ability from '../../../ability';
import ViewUserCard from '../../../components/User/ViewUserCard';
import UserAuthenticators from '../../../components/User/UserAuthenticators';
import UserSessions from '../../../components/User/UserSessions';
import CompanyServiceProviders from '../../../components/Company/CompanyServiceProviders';
function ViewUser({ context }) {
    // const dispatch = useDispatch();
    const user = useSelector((state) => state.users.viewingUser);
    const authorized = ability.can('actAs', 'superAdmin') || ability.can('actAs', 'admin');
    const [loading, setLoading] = useState(!user);

    useEffect(() => {
        if (user) {
            setLoading(false);
        }
    }, [user]);

    return (
        <>
            <TopNav />
            {authorized && (
                <>
                    <Container className="main-container">
                        <h4 className="mt-5 mb-5">
                            {user && !loading && (
                                <>
                                    Viewing {user.firstName} {user.lastName}
                                </>
                            )}
                        </h4>
                        {user && (
                            <>
                                <ViewUserCard />

                                <CompanyServiceProviders />

                                <UserAuthenticators context={context} />

                                <UserSessions />
                            </>
                        )}
                    </Container>
                </>
            )}
        </>
    );
}

export default ViewUser;
