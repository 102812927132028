import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Container,
    Card,
    Row,
    Form,
    FormGroup,
    FormLabel,
    InputGroup,
    Button,
    FormControl,
    Table,
    Col,
    Modal
} from 'react-bootstrap';

import { useHistory, useLocation, Link } from 'react-router-dom';

import TopNav from '../../../components/Navigation/TopNav';
import PkCard from '../../../components/Cards/PKCard';

import {
    FaBuilding,
    FaCompass,
    FaNetworkWired,
    FaTrash,
    FaPlus,
    FaInfoCircle
} from 'react-icons/fa';
import {
    updateLocation,
    getCompanyLocation,
    deleteLocation,
    getCompanyServiceProviders
} from '../../../actions/companyActions';

const ViewEditLocation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const companyGuid = useSelector((state) => state.companies.currentCompany.guid);
    const serviceProviders = useSelector(
        (state) => state.companies.currentCompany.serviceProviders
    );

    const [cidrs, setCidrs] = useState([]);
    const [currentLocationName, setCurrentLocationName] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [currentCidr, setCurrentCidr] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [providers, setProviders] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const priorPagePath = location.state?.returnPath;
    const locationGuid = location.state?.id;

    useEffect(() => {
        document.title = 'Admin - Configure Location';
        if (!serviceProviders) {
            dispatch(getCompanyServiceProviders(companyGuid));
        }
        if (locationGuid) {
            (async () => {
                try {
                    let foundLocation = await getCompanyLocation(companyGuid, locationGuid);

                    setCurrentLocationName(foundLocation.name);
                    setCidrs(foundLocation.cidrs);
                    setProviders(foundLocation.serviceProviders);
                    setIsDefault(foundLocation.isDefault);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [location.state, companyGuid, dispatch, location, locationGuid, serviceProviders]);

    useEffect(() => {
        if (serviceProviders) {
            const structuredProviders = serviceProviders.map(({ name, guid }) => ({
                name,
                guid,
                isSSOAllowed: true
            }));
            setProviders(structuredProviders);
        }
    }, [serviceProviders]);

    const locationNameRef = useRef(null);
    useEffect(() => {
        locationNameRef.current.focus();
    }, []);

    const cidrRegex =
        /^(\d{1,2}|1\d{2}|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d{2}|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d{2}|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d{2}|2[0-4]\d|25[0-5])(\/(\d|[12]\d|3[0-2]))?$/;

    const handleAddCidr = () => {
        if (cidrRegex.test(currentCidr)) {
            setCidrs([...cidrs, currentCidr]);
            setCurrentCidr('');
            setAlertMessage('');
        } else {
            setAlertMessage('Invalid CIDR format');
        }
    };

    const handledChangedCidr = (event) => {
        setAlertMessage('');
        setCurrentCidr(event.target.value);
    };
    const handleInputKeyDown = (event) => {
        if (event.keyCode === 13) {
            // check if the return key was pressed
            event.preventDefault(); // prevent the default form submission
            handleAddCidr();
        }
    };
    const handleDeleteCidr = (index) => {
        const newCidrs = [...cidrs];
        newCidrs.splice(index, 1);
        setCidrs(newCidrs);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const name = currentLocationName;
        if (cidrs.length === 0 || !name) {
            setAlertMessage('You must provide a name and configure at least one CIDR Definition');
            return;
        } else {
            dispatch(updateLocation(name, cidrs, providers, companyGuid, locationGuid))
                .then(() => {
                    console.log('Created.');
                })
                .then(() => {
                    const alert = {
                        message: `Successfuly updated ${name} location.`,
                        variant: 'success'
                    };
                    history.push(priorPagePath, { alert });
                });
        }
    };

    const handleProviderToggle = (guid) => {
        setProviders((prevState) =>
            prevState.map((provider) =>
                provider.guid === guid
                    ? { ...provider, isSSOAllowed: !provider.isSSOAllowed } // Toggle the isSSOAllowed value
                    : provider
            )
        );
    };

    const handleDelete = async () => {
        if (!isDefault) {
            try {
                await dispatch(deleteLocation(companyGuid, locationGuid));
                history.push(priorPagePath);
            } catch (error) {
                console.log('error', error);
            }
        }
    };

    return (
        <>
            <TopNav />

            <Container className="main-container">
                <h4 className="mt-5 mb-5">
                    <FaBuilding /> Configure Location
                </h4>
                <PkCard title="Location Configuration">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <FormGroup className="col-md-6">
                                <FormLabel htmlFor="name">Location Name</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaCompass />
                                    </InputGroup.Text>

                                    <FormControl
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter location name"
                                        ref={locationNameRef}
                                        value={currentLocationName}
                                        onChange={(event) =>
                                            setCurrentLocationName(event.target.value)
                                        }
                                        disabled={isDefault}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Row>
                        <Row className="mt-4">
                            <Form.Label>CIDR Definition</Form.Label>
                            <FormGroup>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FaNetworkWired />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        value={currentCidr}
                                                        onChange={handledChangedCidr}
                                                        placeholder="Enter CIDR"
                                                        isInvalid={alertMessage !== ''}
                                                        className="w-25"
                                                        onKeyDown={handleInputKeyDown}
                                                        disabled={isDefault}
                                                    />
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        disabled={isDefault}
                                                        onClick={handleAddCidr}>
                                                        <FaPlus />
                                                    </Button>
                                                </InputGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-danger">{alertMessage}</td>
                                        </tr>

                                        {cidrs.map((cidr, index) => (
                                            <tr key={index}>
                                                <td>{cidr}</td>
                                                <td>
                                                    {!isDefault ? (
                                                        <FaTrash
                                                            onClick={() => handleDeleteCidr(index)}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </FormGroup>
                        </Row>
                        <Row>
                            <PkCard title="SSO Configuration">
                                {providers && providers.length > 0 && (
                                    <>
                                        <Card
                                            className="col-sm-6 h-100 w-100"
                                            style={{
                                                backgroundColor: 'white',
                                                minWidth: '350px'
                                            }}>
                                            <Card.Body>
                                                <p>
                                                    <FaInfoCircle /> Enabling SSO for a service at
                                                    this location you will allow your users to
                                                    access the service after logging to any SSO
                                                    connected service. Not enabling it means they
                                                    will have to login each time they visit the
                                                    resource.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Service Provider</th>{' '}
                                                    <th className="text-center">SSO Enabled</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {providers.map((provider) => (
                                                    <tr key={provider.id}>
                                                        <td>{provider.name}</td>
                                                        <td className="text-center">
                                                            <Form.Check
                                                                id={provider.guid}
                                                                checked={provider.isSSOAllowed}
                                                                className="custom-form-check"
                                                                onChange={() =>
                                                                    handleProviderToggle(
                                                                        provider.guid
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                )}
                            </PkCard>
                        </Row>
                        <Row className="mt-4 ">
                            <Col xs={12} md={4} lg={4}>
                                <Button
                                    variant="primary"
                                    disabled={
                                        !providers || !cidrs.length > 0 || !currentLocationName
                                    }
                                    type="submit"
                                    className="mr-2  mb-2 mb-md-0">
                                    {providers ? <>Update Location</> : <>...</>}
                                </Button>
                            </Col>

                            <Col xs={12} md={4} lg={4}>
                                <Link to={priorPagePath}>
                                    <Button className="mb-2 mb-md-0" variant="secondary">
                                        Cancel
                                    </Button>
                                </Link>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                {!isDefault && (
                                    <Button
                                        disabled={isDefault}
                                        onClick={() => setShowDeleteModal(true)}
                                        variant="danger"
                                        className="ml-2">
                                        Delete
                                    </Button>
                                )}
                                <Modal
                                    centered
                                    show={showDeleteModal}
                                    onHide={() => setShowDeleteModal(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Location Deletion</Modal.Title>{' '}
                                    </Modal.Header>

                                    <Modal.Body>
                                        Are you sure you want to delete{' '}
                                        <span className="text-danger">{currentLocationName}</span>?
                                        You will no longer be able to customize SSO behavior for
                                        this location.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={() => setShowDeleteModal(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={handleDelete}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                        </Row>
                    </Form>
                </PkCard>
            </Container>
        </>
    );
};

export default ViewEditLocation;
