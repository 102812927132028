import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button, Navbar, Alert } from 'react-bootstrap';
import logo from '../../images/securedByPrivakeyNoKeyhole.png';
import { startLogin, refreshUser, getLoginOptions } from '../../actions/authActions';
import { actions as authActions } from '../../reducers/authReducer';
import { FaQuestionCircle } from 'react-icons/fa';
import { supported } from '@github/webauthn-json/browser-ponyfill';
import ReactGa from 'react-ga4';

// Temp Dev Imports

function Login(props) {
    //Access Query Params if any
    const { location, history } = props;

    const searchParams = new URLSearchParams(location.search);

    const company = searchParams.get('company') || null;
    const companyGuid = searchParams.get('companyGuid') || null;
    const domain = searchParams.get('domain') || null;
    const resource = searchParams.get('resource') || null;
    const samlRequest = searchParams.get('SAMLRequest');
    const zendeskRequest = searchParams.get('zendeskRequest');
    const relayState = searchParams.get('RelayState');
    const requestId = searchParams.get('requestId');
    const samlType = searchParams.get('samlType');

    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [error, setError] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(validateEmail(email));
    const loginOptions = useSelector((state) => state.user.loginOptions);
    const [passkeySupported, setPasskeySupported] = useState(false);
    const [primaryLoginType, setPrimaryLoginType] = useState(null);
    const [primaryLoginText, setPrimaryLoginText] = useState('Log in');
    const [secondaryLoginType, setSecondaryLoginType] = useState(null);
    const [secondaryLoginText, setSecondaryLoginText] = useState('Log in other');
    const [showSecondaryLogin, setShowSecondaryLogin] = useState(false);
    const defaultLoginMessage = 'Please enter a valid company email address.';
    const [loginMessage, setLoginMessage] = useState(defaultLoginMessage);

    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const alertMessage = location.state?.alertMessage;

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setAlertVisible(false);
    //     }, 10000);

    //     return () => {
    //         clearTimeout(timeout);
    //     };
    // }, []);

    //Google Analytics

    useEffect(() => {
        ReactGa.send({ hitType: 'pageview', page: '/', title: 'Login' });
    }, []);

    useEffect(() => {
        const isSupported = supported();
        console.log('Passkey is' + !isSupported ? ' not supported' : ' supported');
        setPasskeySupported(isSupported);
    }, []);

    const getCsrfToken = async () => {
        try {
            await axios.get('/', { baseURL: '/api' });
            console.log('CSRF token fetched');
        } catch (error) {
            console.log(error);
        }
    };

    const primarySubmitButtonRef = useRef(null);
    const storedEmail = window.localStorage?.getItem('lastEmailUsed');

    const inputRef = useRef(null);

    useEffect(() => {
        if (!email && storedEmail) {
            setEmail(storedEmail);
            setButtonEnabled(true);
            primarySubmitButtonRef.current?.focus();
            // Sets cursor to end of email input
        }
    }, [storedEmail]);

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = email || storedEmail || ''; // Set the input value

            // Temporarily change the input type to 'text' to set cursor position
            inputRef.current.type = 'text';
            inputRef.current.selectionStart = inputRef.current.value.length;
            inputRef.current.selectionEnd = inputRef.current.value.length;

            // Change it back to 'email' type
            inputRef.current.type = 'email';

            inputRef.current.focus(); // Focus on the input field
        }
    }, [storedEmail]);

    useEffect(() => {
        if (samlRequest) {
            console.log('SAML request exists');
            dispatch(
                authActions.initiateSamlRequest({
                    samlRequest,
                    relayState,
                    companyGuid,
                    companyName: company,
                    samlType,
                    resource
                })
            );
        } else if (zendeskRequest) {
            dispatch(
                authActions.initiateZendeskRequest({
                    returnTo: zendeskRequest ? zendeskRequest : 'none',
                    resource
                })
            );
        } else {
            if (isAuthenticated) {
                history.push('/accessmanager');
            } else {
                console.log('Refresh User being called');
                const validateStatus = (status) => {
                    return status < 400 || status === 404 || status === 401 || status === 500;
                };
                dispatch(refreshUser(validateStatus)).then((res) => {
                    if (res.status === 200) {
                        history.push('/accessmanager');
                    }
                });
            }
        }

        document.title = 'Log In'; // Set the document title
        getCsrfToken();
    }, [
        company,
        companyGuid,
        dispatch,
        history,
        isAuthenticated,
        relayState,
        resource,
        samlRequest,
        samlType,
        zendeskRequest
    ]);

    function handleLogin(loginType, retryCount = 0) {
        const isValidEmail = validateEmail(email);

        if (!isValidEmail) {
            setError('Please enter a valid, company email address');
            return;
        }
        if (buttonEnabled) {
            setButtonEnabled(false);
            dispatch(startLogin(email, loginType, requestId, resource))
                .then(() => {
                    window.localStorage?.setItem('lastEmailUsed', email);
                    history.push('/pending');
                })
                .catch((err) => {
                    setButtonEnabled(true);
                    console.log('err: ', err.response.status);
                    if (err.response) {
                        if (err.response.status === 401) {
                            setError(
                                'The authentication information is incorrect. Did you use your company email?'
                            );
                        } else if (err.response.status === 403) {
                            //csrf error
                            if (retryCount < 3) {
                                getCsrfToken().then(() => {
                                    handleLogin(loginType, retryCount + 1);
                                });
                            } else {
                                setError(
                                    'An error occurred during authentication. Please try to refresh your browser'
                                );
                            }
                        } else {
                            setError('An error occurred during authentication.');
                        }
                    }
                });
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        // sanity check to make sure the form does not submit
    }

    function handleEmailChange(event) {
        console.log('handleEmailChange');
        const newEmail = event.target.value;
        setError(null);
        setEmail(newEmail);
        const isValidEmail = validateEmail(newEmail);
        if (!isValidEmail) {
            setLoginMessage(defaultLoginMessage);
            setButtonEnabled(false);
        }
    }

    useEffect(() => {
        const validEmail = validateEmail(email);
        if (validEmail && (!loginOptions || loginOptions.email !== email)) {
            dispatch(getLoginOptions(email));
        } else if (!validEmail) {
            dispatch(authActions.clearLoginOptions());
        }
    }, [email, loginOptions, dispatch]);

    useEffect(() => {
        if (loginOptions && loginOptions.email === email) {
            let hasPasskey = false;
            let usePasskey = false;

            if (passkeySupported && loginOptions.passkeys?.length > 0) {
                hasPasskey = true;
                const authInfoStr = window.localStorage.getItem(email);
                if (authInfoStr) {
                    const authInfo = JSON.parse(authInfoStr);
                    //look for a matching authenticator
                    if (authInfo.authenticators) {
                        for (const authenticator of authInfo.authenticators)
                            if (
                                loginOptions.passkeys.some((passkey) => {
                                    return authenticator.guid === passkey;
                                })
                            ) {
                                usePasskey = true;
                                break;
                            }
                    }
                }
            }
            if (usePasskey) {
                setPrimaryLoginType('passkey');
                setPrimaryLoginText('Log in with Passkey');
                if (loginOptions.hasPkAuth) {
                    setSecondaryLoginType('pkauth');
                    setShowSecondaryLogin(true);
                    setSecondaryLoginText('Log in with Privakey');
                    setLoginMessage('Login with Passkey or Privakey.');
                } else {
                    setShowSecondaryLogin(false);
                    setLoginMessage('Login with Privakey.');
                }
            } else if (loginOptions.hasPkAuth) {
                setPrimaryLoginType('pkauth');
                setPrimaryLoginText('Log in with Privakey');
                if (hasPasskey) {
                    setSecondaryLoginType('passkey');
                    setShowSecondaryLogin(true);
                    setSecondaryLoginText('Log in with Passkey');
                    setLoginMessage('Login with Privakey (or Passkey).');
                } else {
                    setShowSecondaryLogin(false);
                    setLoginMessage('Login with Privakey.');
                }
            } else {
                //we don't have a passkey match, but that's our only option
                setPrimaryLoginType('passkey');
                setPrimaryLoginText('Log in with Passkey');
                setShowSecondaryLogin(false);
                setLoginMessage('Login in with Passkey.');
            }
            setButtonEnabled(true);
        } else {
            validateEmail(email) && setLoginMessage('Email not found.');
            setButtonEnabled(false);
        }
    }, [loginOptions, email, passkeySupported]);

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    return (
        <Container>
            <Navbar>{}</Navbar>
            <Row>
                <div className="jumbotron">
                    <h4 className="text-center flex-md-row">
                        Privakey Passwordless Single Sign On{' '}
                        {(company || domain) && <strong> for {company || domain} </strong>}
                    </h4>
                    <hr className="mb-8" />
                    {alertMessage && (
                        <Row className="row-cols-1 justify-content-center g-4">
                            <Col>
                                <Alert variant="success">{alertMessage}</Alert>
                            </Col>
                        </Row>
                    )}
                    <Row className="row-cols-1 row-cols-lg-2 d-flex justify-content-center g-4">
                        <Col className="order-lg-1">
                            <Card
                                className="col-md-6 h-100 w-100"
                                style={{
                                    backgroundColor: 'white',
                                    minWidth: '350px'
                                }}>
                                <Card.Body>
                                    <Row>
                                        <Col className="col-11">
                                            <h4>Login {resource && <>to {resource}.</>}</h4>
                                            <p className="text-muted small">
                                                You must have an account with Privakey SSO
                                                {(company || domain) && (
                                                    <strong> for {company || domain}</strong>
                                                )}
                                                {resource && resource !== 'Privakey Support' && (
                                                    <> and {resource}</>
                                                )}{' '}
                                                to log in.
                                            </p>
                                        </Col>
                                        <Col className="d-flex justify-content-end col-1">
                                            <a
                                                href="https://help.privakey.com"
                                                target="_blank"
                                                rel="noreferrer">
                                                {' '}
                                                <FaQuestionCircle />
                                            </a>
                                        </Col>
                                    </Row>

                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="privakeySsoEmail">
                                            <Form.Label>Company Email address</Form.Label>

                                            <Form.Control
                                                type="email"
                                                defaultValue={email}
                                                autoFocus
                                                onBlur={handleEmailChange}
                                                onChange={handleEmailChange}
                                                onInput={handleEmailChange}
                                                data-lpignore="true"
                                                placeholder="Enter company email"
                                                ref={inputRef}
                                            />
                                            <Form.Text className="text-muted">
                                                <p>
                                                    {error ? (
                                                        <span className="text-danger">{error}</span>
                                                    ) : (
                                                        <>
                                                            {loginMessage}
                                                            {domain && (
                                                                <span className="italic-text">
                                                                    {'(<'}user{'>'}@{domain}
                                                                    {')'}
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </p>
                                            </Form.Text>
                                        </Form.Group>

                                        {loginOptions && (
                                            <>
                                                <button
                                                    name={primaryLoginType}
                                                    ref={primarySubmitButtonRef}
                                                    className={
                                                        primaryLoginType === 'passkey'
                                                            ? 'btn passkey-btn-solid '
                                                            : 'btn privakey-btn-solid'
                                                    }
                                                    disabled={!buttonEnabled}
                                                    data-bs-toggle="button"
                                                    type="submit"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleLogin(primaryLoginType);
                                                    }}>
                                                    {primaryLoginText}
                                                </button>
                                                {showSecondaryLogin && (
                                                    <>
                                                        {' '}
                                                        <button
                                                            name={secondaryLoginType}
                                                            className={
                                                                secondaryLoginType === 'passkey'
                                                                    ? 'btn passkey-btn-outlined '
                                                                    : 'btn privakey-btn-outlined'
                                                            }
                                                            disabled={!buttonEnabled}
                                                            data-bs-toggle="button"
                                                            type="submit"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                handleLogin(secondaryLoginType);
                                                            }}>
                                                            {secondaryLoginText}
                                                        </button>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {!loginOptions && (
                                            <Button disabled={true} variant="outline">
                                                Login
                                            </Button>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        {!company && !domain && !resource && (
                            // <Col className="d-flex col-md-6 justify-content-center">
                            <Col>
                                <Card
                                    className="col-sm-6 h-100 w-100"
                                    style={{
                                        backgroundColor: 'white',
                                        minWidth: '350px'
                                    }}>
                                    <Card.Body>
                                        <p>
                                            {' '}
                                            Privakey Passwordless SSO lets businesses get rid of
                                            passwords.
                                        </p>
                                        <p>
                                            Connect to Google Workplace, AWS, and Microsoft 365
                                            using advanced biometric passkeys.
                                        </p>

                                        <ul>
                                            <li>Never forget a password</li>
                                            <li>Phishing Proof</li>
                                            <li>Use your phone and biometric</li>
                                            <li>
                                                Configurable to require step-up authentication when
                                                out of the office.
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    <hr />
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <img src={logo} height="60" alt="Secured by Privakey" />
                        </Col>
                    </Row>
                </div>
            </Row>
            {!company && !domain && !resource && (
                <Row className="row-cols-1 row-cols-lg-2 d-flex g-4">
                    <Col className="col-lg-6">
                        <h4>Privakey SSO Access Manager</h4>
                        <hr />
                        <p>
                            Log in here to authenticate to the Privakey Access Manager. From the
                            Access Manager you can access your connected services and manage
                            authenticators. Interested in signing your company up for Privakey
                            Passwordless SSO.
                        </p>
                    </Col>
                    <Col className="col-lg-6">
                        <h4>Free Beta - Sign Up</h4>
                        <hr />
                        <p>
                            Interested in getting rid of passwords? Get started by signing up for a
                            Free Beta account. Simply signing up gets you 5 free seats. Don't worry
                            - more seats are readily available if needed.{' '}
                            {/* <a href="https://help.privakey.com">
                                    Learn more about Privakey Passwordless SSO.
                                </a> */}
                            <a href="/signup">Sign up now</a>
                        </p>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default withRouter(Login);
