import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createCompany } from '../../../actions/companyActions';
import { createUser } from '../../../actions/userActions';
import {
    Container,
    Row,
    Form,
    FormGroup,
    FormLabel,
    InputGroup,
    Button,
    FormControl
} from 'react-bootstrap';
import TopNav from '../../../components/Navigation/TopNav';
import PkCard from '../../../components/Cards/PKCard';
import { FaBuilding, FaEnvelope, FaChair, FaUserTie } from 'react-icons/fa';
import { Can } from '../../../ability-context';

const AddNewCompany = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [company, setCompany] = useState({
        name: '',
        seats: 6,
        adminFirstName: '',
        adminLastName: '',
        adminUserId: '',
        domain: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = name === 'seats' ? parseInt(value) : value; // Parse seats value as an integer
        setCompany((prevCompany) => ({
            ...prevCompany,
            [name]: parsedValue
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(createCompany(company.name, company.domain, company.seats))
            .then((companyGuid) => {
                if (companyGuid) {
                    dispatch(
                        createUser(
                            company.adminFirstName,
                            company.adminLastName,
                            `${company.adminUserId}@${company.domain}`,
                            null,
                            ['Admin'],
                            companyGuid
                        )
                    ).then(() => {
                        history.push(`/superadmin/company`);
                    });
                }
                //handle company creation error
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });
    };

    const companyNameRef = useRef(null);
    useEffect(() => {
        companyNameRef.current.focus();
    }, []);

    return (
        <Can I="actAs" a="superAdmin">
            <TopNav />
            <Container className="main-container">
                <h4 className="mt-5 mb-5">
                    <FaBuilding /> Add a New Company
                </h4>
                <PkCard>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <FormGroup className="col-md-6 mt-4">
                                <FormLabel htmlFor="name">Company Name</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaBuilding />
                                    </InputGroup.Text>

                                    <FormControl
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter company name"
                                        ref={companyNameRef}
                                        value={company.name}
                                        onChange={handleInputChange}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="col-md-6 mt-4">
                                <FormLabel htmlFor="seats">Seats</FormLabel>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaChair />
                                    </InputGroup.Text>

                                    <FormControl
                                        as="select"
                                        name="seats"
                                        id="seats"
                                        value={company.seats}
                                        disabled
                                        onChange={handleInputChange}>
                                        <option value={6}>6</option>
                                    </FormControl>
                                </InputGroup>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="col-md-6 mt-4">
                                <FormLabel>Admin Name</FormLabel>

                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaUserTie />
                                    </InputGroup.Text>

                                    <FormControl
                                        type="text"
                                        name="adminFirstName"
                                        id="adminFirstName"
                                        placeholder="First name"
                                        value={company.adminFirstName}
                                        onChange={handleInputChange}
                                    />
                                    <FormControl
                                        type="text"
                                        name="adminLastName"
                                        id="adminLastName"
                                        placeholder="Last name"
                                        value={company.adminLastName}
                                        onChange={handleInputChange}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="col-md-6 mt-4">
                                <FormLabel>Admin Email</FormLabel>

                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaEnvelope />
                                    </InputGroup.Text>
                                    <FormControl
                                        type="adminUserId"
                                        name="adminUserId"
                                        id="adminUserId"
                                        placeholder="Enter admin email"
                                        value={company.adminUserId}
                                        onChange={handleInputChange}
                                        isInvalid={
                                            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                                `${company.adminUserId}@${company.domain}`
                                            )
                                        }
                                    />
                                    <InputGroup.Text>@</InputGroup.Text>
                                    <FormControl
                                        type="text"
                                        name="domain"
                                        id="domain"
                                        placeholder="Enter Company Email Domain"
                                        value={company.domain}
                                        onChange={handleInputChange}
                                        isInvalid={
                                            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                                `${company.adminUserId}@${company.domain}`
                                            )
                                        }
                                    />
                                    <FormControl.Feedback type="invalid">
                                        Please provide a valid email address.
                                    </FormControl.Feedback>
                                </InputGroup>
                            </FormGroup>
                        </Row>
                        <Button variant="primary" type="submit" className="mt-4">
                            Add Company
                        </Button>
                    </Form>
                </PkCard>
            </Container>
        </Can>
    );
};

export default AddNewCompany;
