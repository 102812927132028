import axios from 'axios';

import { actions as usersActions } from '../reducers/userReducer';
import { actions as companyActions } from '../reducers/companyReducer';

const handleError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        console.log('Response Data:', error.response.data);
        console.log('Response Status:', error.response.status);
        console.log('Response Headers:', error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        console.log('Request:', error.request);
    } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error:', error.message);
    }
    console.log('Error Config:', error.config);
};

export const getUser = (companyGuid, userGuid) => async (dispatch) => {
    try {
        let response = await axios.get(`/companies/${companyGuid}/users/${userGuid}`);

        let user = response.data;
        const filteredAuthenticators = user.authenticators.filter(
            (item) => item.privakeyDeviceGuid !== undefined
        );
        user.authenticators = filteredAuthenticators;
        dispatch(usersActions.select(user));
    } catch (error) {
        handleError(error);
    }
};

export const validateUserAuthenticators = (companyGuid, userGuid) => async (dispatch) => {
    try {
        const response = await axios.get(
            `/companies/${companyGuid}/users/${userGuid}/authenticators/valid`
        );
        const filteredAuthenticators = response.data.filter(
            (item) => item.privakeyDeviceGuid !== undefined
        );
        dispatch(usersActions.getValidAuthenticators(filteredAuthenticators));
    } catch (error) {
        handleError(error);
    }
};

export const getAllActiveSessions = (companyGuid, userGuid) => async (dispatch) => {
    try {
        const response = await axios.get(
            `companies/${companyGuid}/users/${userGuid}/sessions/active`
        );
        const updatedData = response.data.map(({ isActive, ...rest }) => rest);
        dispatch(usersActions.getSessions(updatedData));
    } catch (error) {
        handleError(error);
    }
};
export const getUserSessionInfo = async (companyGuid, userGuid) => {
    try {
        const response = await axios.get(
            `/companies/${companyGuid}/users/${userGuid}/sessions/current`
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};

export const getUserLastAccessed = async (companyGuid, userGuid) => {
    try {
        const response = await axios.get(
            `/companies/${companyGuid}/users/${userGuid}/sessions/lastAccessed`
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};

export const createUser =
    (firstName, lastName, email, profile, roles, companyGuid, emailTo = 'both') =>
    async (dispatch) => {
        let postData = { firstName, lastName, email, profile, roles, emailTo };
        console.log('Post Data from userActions/createUser: ', postData);
        try {
            const response = await axios.post(`/companies/${companyGuid}/users/new`, postData);
            console.log('createUser Action Post Response', response);
            const location = response.headers.location;
            const userGuid = location.substring(location.lastIndexOf('/') + 1);

            dispatch(
                companyActions.createUser({
                    firstName,
                    lastName,
                    email,
                    profile,
                    companyGuid,
                    guid: userGuid,
                    status: 'active'
                })
            );
        } catch (error) {
            // Handle error
            if (error.response) {
                // The request was made and the server responded with a status code
                console.log('Response Data:', error.response.data);
                console.log('Response Status:', error.response.status);
                console.log('Response Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log('Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an error
                console.log('Error:', error.message);
            }
            console.log('Error Config:', error.config);
            throw error;
        }
    };

export const fetchUserUploads = (companyGuid) => async (dispatch) => {
    const res = await axios.get(`/companies/${companyGuid}/users/bulk`);
    if (res.status === 200) {
        dispatch(companyActions.fetchCompanyUserUploads(res.data));
    }
    return res;
};

export const fetchUserUpload = (companyGuid, userUploadGuid) => async (dispatch) => {
    const res = await axios.get(`/companies/${companyGuid}/users/bulk/${userUploadGuid}`);
    if (res.status === 200) {
        dispatch(companyActions.fetchCompanyUserUpload(res.data));
    }
    return res.data;
};

export const createUsers = async (file, companyGuid) => {
    if (file) {
        const formData = new FormData();
        formData.append('users', file);

        try {
            const response = await axios.post(`/companies/${companyGuid}/users/bulk`, formData, {
                // Optional headers if needed, such as authentication token
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Check response status
            if (response.status === 201) {
                // File uploaded successfully
                console.log('File uploaded!');
            } else {
                // Handle other response statuses
                console.error('Failed to upload file');
            }
        } catch (error) {
            // Handle error
            console.error('Error uploading file:', error);
        }
    } else {
        console.error('No file selected.');
    }
};

export const updateUser =
    (userGuid, firstName, lastName, email, profile, rolesRemoved, rolesAdded, companyGuid) =>
    async (dispatch) => {
        let postData = { firstName, lastName, email, profile, rolesRemoved, rolesAdded };
        console.log('Post Data from userActions/createUser: ', postData);
        try {
            const response = await axios.patch(
                `/companies/${companyGuid}/users/${userGuid}`,
                postData
            );
            console.log('updateUser Action Patch Respose', response);

            dispatch(getUser(companyGuid, userGuid));
        } catch (error) {
            // Handle error
            if (error.response) {
                // The request was made and the server responded with a status code
                console.log('Response Data:', error.response.data);
                console.log('Response Status:', error.response.status);
                console.log('Response Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log('Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an error
                console.log('Error:', error.message);
            }
            console.log('Error Config:', error.config);
            throw error;
        }
    };

export const updateUserStatus = (userGuid, status, companyGuid) => async (dispatch) => {
    let postData = { status };
    try {
        const response = await axios.patch(`/companies/${companyGuid}/users/${userGuid}`, postData);
        console.log('updateUserStatus Action Patch Respose', response);

        dispatch(getUser(companyGuid, userGuid));
    } catch (error) {
        // Handle error
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
        throw error;
    }
};

export const deleteAnAuthenticator =
    (companyGuid, userGuid, authenticatorGuid) => async (dispatch) => {
        console.log('Delete an Authenticator action called');
        try {
            const res = await axios.delete(
                `companies/${companyGuid}/users/${userGuid}/authenticators/${authenticatorGuid}`
            );
            console.log(res);
            dispatch(usersActions.deleteAuthenticator(authenticatorGuid));
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                console.log('Response Data:', error.response.data);
                console.log('Response Status:', error.response.status);
                console.log('Response Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log('Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an error
                console.log('Error:', error.message);
            }
            console.log('Error Config:', error.config);
        }
    };

export const logoutSession = async (companyGuid, userGuid, sessionGuid) => {
    try {
        const res = await axios.patch(
            `companies/${companyGuid}/users/${userGuid}/sessions/${sessionGuid}/logout`
        );
        if (res) {
            return res.data.result;
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
    return false;
};

export const createNewAuthenticatorByEmail = async (companyGuid, userGuid, emailTo = 'both') => {
    try {
        let res = await axios.post(`/companies/${companyGuid}/users/${userGuid}/bind`, { emailTo });
        console.log(res);
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};
