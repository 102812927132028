import React, { useState, useEffect, useCallback } from 'react';
import ability from '../../../ability';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Container, Card, Col, Row, Button, Accordion } from 'react-bootstrap';
import { FaCheckSquare, FaEdit } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';

import TopNav from '../../../components/Navigation/TopNav';
import ConfigureServiceProviderModal from '../../../components/Company/ConfigureServiceProviderModal';
import MetaData from '../../../components/Company/MetaData';
import Beta from '../../../components/Beta';

const ServiceProviders = () => {
    const location = useLocation();
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedProviderId, setSelectedProviderId] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const systemProviders = useSelector((state) => state.system.serviceProviders);
    const currentCompany = useSelector((state) => state.companies.currentCompany);

    const mergeServiceProviders = useCallback((allSystemProviders, companyServiceProviders) => {
        const mergedServiceProviders = allSystemProviders.map((systemProvider) => {
            const matchingProvider = companyServiceProviders.find(
                (companyProvider) =>
                    companyProvider.systemServiceProviderGuid === systemProvider.guid
            );

            if (matchingProvider) {
                return {
                    ...systemProvider,
                    companyProviderGuid: matchingProvider.guid,
                    status: matchingProvider.status,
                    configuration: {
                        ...systemProvider.configuration,
                        samlElements: matchingProvider.configuration.samlElements,
                        entryRequired: matchingProvider.configuration.entryRequired,
                        metadata: matchingProvider.configuration.metadata,
                        userMapping: matchingProvider.configuration.userMapping
                    }
                };
            }

            return {
                ...systemProvider,
                status: 'notConfigured'
            };
        });

        return mergedServiceProviders;
    }, []);

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedProvider(null);
    };

    useEffect(() => {
        setSelectedProviderId(location.state?.id || null);
    }, [location.state?.id]);

    useEffect(() => {
        document.title = 'Admin - Service Providers'; // Set the document title
        setLoading(true);

        try {
            console.log('company providers', currentCompany?.serviceProviders);
            console.log('systemProviders', systemProviders);
            if (currentCompany?.serviceProviders && systemProviders?.length > 0) {
                // if (currentCompany?.serviceProviders && systemProviders) {
                const providers = mergeServiceProviders(
                    systemProviders,
                    currentCompany.serviceProviders
                );
                console.log('merged providers ', providers);

                providers.sort(compareObjects('name'));
                setFilteredData(providers);
                console.log(providers);
                if (selectedProviderId) {
                    const showItem = providers.find(
                        (item) => item.companyProviderGuid === selectedProviderId
                    );
                    setSelectedProvider(showItem);
                    setShowModal(true);
                    setSelectedProviderId(null);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [currentCompany, systemProviders, selectedProviderId, mergeServiceProviders]);

    function compareObjects(key) {
        return function (a, b) {
            const propA = a[key].toUpperCase();
            const propB = b[key].toUpperCase();

            if (propA < propB) {
                return -1;
            }

            if (propA > propB) {
                return 1;
            }

            return 0;
        };
    }

    // const handleFilterChange = (event) => {
    //     const searchTerm = event.target.value.toLowerCase();
    //     const filteredData = systemProviders.filter((item) =>
    //         item.name.toLowerCase().includes(searchTerm)
    //     );
    //     setSearchTerm(searchTerm);
    //     setFilteredData(filteredData);
    // };

    const handleEnableClick = (provider) => {
        console.log(provider);
        setSelectedProvider(provider);
        setShowModal(true);
    };

    const authorized = ability.can('actAs', 'superAdmin') || ability.can('actAs', 'admin');
    return (
        <>
            <TopNav />
            {loading && <ScaleLoader />}
            {!loading && systemProviders && authorized && (
                <Container className="main-container">
                    <h4 className="mt-5 mb-5">
                        {' '}
                        Configure Service Providers for: {currentCompany.name}
                    </h4>
                    <Beta />
                    <Accordion className="mb-4">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Help</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Setting up a Service Provider Requires configuration on both the
                                    Service Provider site and here. To learn more visitingng our{' '}
                                    <a
                                        href="https://help.privakey.com/ssoadministration"
                                        target="_blank"
                                        rel="noreferrer">
                                        help documentation.
                                    </a>
                                    .
                                </p>
                                <p>
                                    When configuirng Privakey Passwordless Single Sign-on at the
                                    Service Provider you will need to provide your SAML metadata.
                                    You may need to manually enter the data, upload a file or
                                    provide a link. Click on Show SAML Metadata below to view this
                                    data.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <MetaData />

                    <div className="shadow p-3 mb-5 bg-body rounded">
                        {/* <Row className="mb-2">
                            <Col sm={{ span: 6, offset: 3 }}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaFilter />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Filter..."
                                        value={searchTerm}
                                        onChange={handleFilterChange}
                                    />
                                </InputGroup>
                            </Col>
                        </Row> */}

                        <Row xs={1} lg={2} className="g-4">
                            {filteredData.length > 0 ? (
                                filteredData.map((provider) => (
                                    <Col key={provider.guid}>
                                        <Card key={provider.guid} className="h-100">
                                            <Card.Body>
                                                <div
                                                    className={`d-flex justify-content-between alert ${
                                                        provider.status === 'active'
                                                            ? `alert-success`
                                                            : provider.status === 'draft'
                                                            ? `alert-caution`
                                                            : 'alert-secondary'
                                                    }`}>
                                                    <Card.Title>{provider.name}</Card.Title>

                                                    {provider.status === 'active' ? (
                                                        <span className="text-success">
                                                            Enabled
                                                        </span>
                                                    ) : provider.status === 'draft' ? (
                                                        <span className="text-danger">Draft</span>
                                                    ) : provider.status === 'disabled' ? (
                                                        <span className="text-danger">
                                                            Disabled
                                                        </span>
                                                    ) : provider.status === 'invalid' ? (
                                                        <span className="text-danger">
                                                            Invalid Configuration
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <Card.Text>{provider.description}</Card.Text>
                                            </Card.Body>
                                            <Card.Body>
                                                {provider.status !== 'notConfigured' ? (
                                                    <Button
                                                        className="btn-sm btn-primary"
                                                        onClick={() => handleEnableClick(provider)}>
                                                        <FaEdit /> Edit Config
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="btn-sm btn-secondary"
                                                        onClick={() => handleEnableClick(provider)}>
                                                        <FaCheckSquare /> Configure
                                                    </Button>
                                                )}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <>
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <div className="d-flex alert alert-warning">
                                                    <Card.Title>Nothing Found</Card.Title>
                                                </div>
                                            </Card.Body>
                                            <div className="d-flex justify-content-center mb-4">
                                                No Service Providers match the search term.
                                            </div>
                                        </Card>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>
                    <ConfigureServiceProviderModal
                        show={showModal}
                        handleClose={handleModalClose}
                        selectedProvider={selectedProvider}
                    />
                </Container>
            )}
        </>
    );
};

export default ServiceProviders;
