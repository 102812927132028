import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActiveSessions, logoutSession } from '../../actions/userActions';
import { FaSignOutAlt, FaGlobe, FaInfoCircle } from 'react-icons/fa';
import PkCard from '../Cards/PKCard';
import DynamicTable from '../Utils/dynamicTable';
import { ScaleLoader } from 'react-spinners';
import { Button, Modal } from 'react-bootstrap';

const UserSessions = () => {
    const dispatch = useDispatch();
    const viewingUser = useSelector((state) => state.users.viewingUser);
    const currentUser = useSelector((state) => state.user.currentUser);
    const [loading, setLoading] = useState(true);
    const [fetchingSessions, setFetchingSessions] = useState(false);
    const [message, setMessage] = useState('No Active Sessions');

    const fetchSessionData = useCallback(async () => {
        try {
            setFetchingSessions(true);
            await dispatch(getAllActiveSessions(viewingUser.companyGuid, viewingUser.guid));
            setFetchingSessions(false);
            setLoading(false);
        } catch (error) {
            setMessage('Error loading Sessions');
        }
    }, [dispatch, viewingUser]);
    const [displayName, setDisplayName] = useState();

    useEffect(() => {
        if (
            viewingUser?.email &&
            !viewingUser?.sessions &&
            currentUser?.guid &&
            !fetchingSessions
        ) {
            fetchSessionData();
            if (currentUser.guid === viewingUser.guid) {
                setDisplayName('your self');
            } else {
                setDisplayName(`${viewingUser.firstName} ${viewingUser.lastName}`);
            }
        }
    }, [viewingUser, currentUser, fetchingSessions, fetchSessionData]);

    const [sessionsWithActions, setSessionsWithActions] = useState();
    useEffect(() => {
        let manipulatedSessions = [];
        if (viewingUser?.sessions?.length > 0) {
            manipulatedSessions = viewingUser.sessions.map((session) => {
                const obj = { ...session };
                const sessionInfo = obj.sessionInfo;
                if (sessionInfo) {
                    delete obj.sessionInfo;
                    delete obj.expiration;

                    obj.sessionInformation = {
                        data: sessionInfo.location ? sessionInfo.location : sessionInfo.ip
                    };
                    if (sessionInfo.platform) {
                        obj.sessionInformation.data += ` (${sessionInfo.platform.replace(
                            /["']/g,
                            ''
                        )})`;
                    } else {
                        //check for mobile device info
                        const pattern = /(Android)|(iPhone)|(Macintosh)|(iPad)|(Windows)/;
                        const match = sessionInfo.userAgent.match(pattern);

                        if (match) {
                            let os = match[0];
                            os = os === 'Macintosh' ? 'Mac' : os;
                            obj.sessionInformation.data += ` (${os})`;
                        }
                    }
                }
                if (session.createdDate) {
                    delete obj.createdDate;
                    obj.sessionInformation.tooltip = {
                        date: session.createdDate,
                        prefix: 'Logged in at:'
                    };
                }
                if (session.guid === currentUser.sessionGuid) {
                    obj.sessionInformation.data = 'This Session';
                    obj.action = '';
                } else {
                    obj.action = (
                        <span onClick={handleEndSession(session)} className="pk-link">
                            <FaSignOutAlt color="red" />
                        </span>
                    );
                }
                return obj;
            });
        }
        setSessionsWithActions(manipulatedSessions);
    }, [viewingUser, currentUser]);

    const [selectedItem, setSelectedItem] = useState();

    const handleEndSession = (item) => () => {
        setSelectedItem(item);
        console.log('Selected Item:', item);
        setShowEndSessionModal(true);
    };

    const handleCloseEndSessionModal = () => setShowEndSessionModal(false);

    const handleConfirm = async () => {
        try {
            await logoutSession(viewingUser.companyGuid, viewingUser.guid, selectedItem.guid);

            await fetchSessionData();
            setShowEndSessionModal(false);
        } catch (error) {
            console.log(error);
        }
    };
    const [showHelpContent, setShowHelpContent] = useState(false);

    const helpContent = (
        <>
            <PkCard
                title={<div className="col text-left">Active Sessions Help</div>}
                link={<span onClick={() => handleHelpClose()}>X</span>}>
                <p> These are the current open sessions for {viewingUser?.email}.</p>
                <p>
                    You can log the user out of a session by clicking the <FaSignOutAlt />
                </p>
            </PkCard>
        </>
    );

    const handleHelpClose = () => {
        setShowHelpContent(false);
    };

    const [showEndSessionModal, setShowEndSessionModal] = useState(false);

    return (
        <>
            {showHelpContent ? (
                helpContent
            ) : (
                <PkCard
                    title={
                        <>
                            <FaGlobe /> Active Sessions
                        </>
                    }
                    link={
                        <span onClick={() => setShowHelpContent(true)}>
                            <FaInfoCircle />
                        </span>
                    }>
                    <div className="ml-10">
                        {loading ? (
                            <ScaleLoader />
                        ) : sessionsWithActions?.length > 0 ? (
                            <>
                                <DynamicTable
                                    style={{ marginLeft: '20%' }}
                                    data={sessionsWithActions}
                                    useHeaders={true}
                                />
                                {selectedItem && (
                                    <Modal
                                        centered
                                        show={showEndSessionModal}
                                        onHide={handleCloseEndSessionModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Session Termination</Modal.Title>{' '}
                                        </Modal.Header>

                                        <Modal.Body>
                                            Are you sure you want to log {displayName} out of this
                                            session? If you click End Session the session will be
                                            terminated.
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="secondary"
                                                onClick={handleCloseEndSessionModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="danger" onClick={handleConfirm}>
                                                End Session
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                )}
                            </>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                {message}
                            </div>
                        )}
                    </div>
                </PkCard>
            )}
        </>
    );
};
export default UserSessions;
