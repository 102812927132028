import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSelfSignupCompany } from '../../actions/companyActions';
import {
    Container,
    Row,
    Form,
    FormGroup,
    FormLabel,
    InputGroup,
    Button,
    FormControl,
    FormCheck
} from 'react-bootstrap';
import TopNav from '../../components/Navigation/TopNav';
import PkCard from '../../components/Cards/PKCard';
import {
    FaBuilding,
    FaEnvelope,
    FaUserTie,
    FaEnvelopeOpen,
    FaHeart,
    FaPhone
} from 'react-icons/fa';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

import ReactGa from 'react-ga4';

const SelfNewCompany = (props) => {
    const dispatch = useDispatch();

    const [company, setCompany] = useState({
        name: '',
        seats: 5,
        adminFirstName: '',
        adminLastName: '',
        adminUserId: '',
        domain: '',
        adminPhone: ''
    });
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [errors, setErrors] = useState({});
    const formFilled = Object.values(company).every((value) => !!value);

    useEffect(() => {
        if (alertMessage) {
            setTimeout(() => {
                setAlertMessage('');
            }, 7000);
        }
    }, [alertMessage]);

    const handleInputChange = (e) => {
        setErrors({});
        const { name, value } = e.target;
        const parsedValue = name === 'seats' ? parseInt(value) : value; // Parse seats value as an integer
        setCompany((prevCompany) => ({
            ...prevCompany,
            [name]: parsedValue
        }));
    };
    const domainRegex =
        /^(?=.{1,253})(?=.{1,63}\.)[a-z\d](?:[a-z\d-]*[a-z\d])?(?:\.[a-z\d](?:[a-z\d-]*[a-z\d])?)*$/;

    const isValidPhoneNumber = (phoneNumber) => {
        const phone = parsePhoneNumberFromString(phoneNumber, 'US');
        return phone ? phone.isValid() : false;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let validationErrors = {};
        if (!company.name) validationErrors.name = 'Company name is required.';
        if (!company.adminFirstName || !company.adminLastName)
            validationErrors.adminName = 'Admin name (First and Last) is required.';
        if (!company.adminUserId) validationErrors.adminUserId = 'Admin email ID is required.';
        if (!company.adminPhone || !isValidPhoneNumber(company.adminPhone))
            validationErrors.adminPhone = 'A valid Phone number is required.';
        if (!company.domain || !domainRegex.test(company.domain))
            validationErrors.domain = 'A valid domain is required.';
        if (!termsAccepted) {
            validationErrors.terms = 'You must accept the terms to proceed.';
        }
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            dispatch(
                createSelfSignupCompany(
                    company.name,
                    company.domain,
                    company.seats,
                    company.adminFirstName,
                    company.adminLastName,
                    `${company.adminUserId}@${company.domain}`,
                    company.adminPhone
                )
            )
                .then((companyGuid) => {
                    if (companyGuid) {
                        setSubmitted(true);
                        ReactGa.event({
                            category: 'Signup',
                            action: 'Signed Up',
                            label: 'Self Sign Up'
                        });
                    }
                    //handle company creation error
                })
                .catch((error) => {
                    setAlertMessage(error.response.data.message);
                    console.log(error.response.data.message);
                });
        }
    };

    const companyNameRef = useRef(null);
    useEffect(() => {
        companyNameRef.current?.focus();
    }, []);

    useEffect(() => {
        ReactGa.send({ hitType: 'pageview', page: '/signup', title: 'Self Sign Up' });
    }, []);

    return (
        <>
            <TopNav />
            {!submitted && (
                <Container className="main-container">
                    <h4 className="mt-5 mb-5">
                        <FaBuilding /> Passwordless SSO - Free Beta
                    </h4>
                    <PkCard title="Sign up for the Free Privakey Passwordless SSO Beta  (The beta period ends May 31, 2024).">
                        <div>
                            <h5>
                                Thank you for your interest in the Privakey SSO FREE Beta. We just
                                need a few bits of information to get you started.
                            </h5>
                            <p className="text-danger">
                                It's important to use your company email and Domain. The Domain you
                                enter needs to be the domain used by your company.
                            </p>
                            <p>
                                {' '}
                                In a subsequent step you will need to verify it by entering a DNS{' '}
                                <code>text </code>
                                entry at your domain provider. Need help? Contact{' '}
                                <a href="mailto:support@privakey.com?subject=Sign Up Page Support">
                                    support@privakey.com
                                </a>
                            </p>
                        </div>
                    </PkCard>
                    <PkCard>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <FormGroup className="col-md-12 mt-4">
                                    <FormLabel htmlFor="name">Company Name</FormLabel>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaBuilding />
                                        </InputGroup.Text>

                                        <FormControl
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Enter company name"
                                            ref={companyNameRef}
                                            value={company.name}
                                            onChange={handleInputChange}
                                        />
                                    </InputGroup>
                                    {errors.name && (
                                        <div className="text-danger">{errors.name}</div>
                                    )}
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup className="col-md-6 mt-4">
                                    <FormLabel>Admin Name</FormLabel>

                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaUserTie />
                                        </InputGroup.Text>

                                        <FormControl
                                            type="text"
                                            name="adminFirstName"
                                            id="adminFirstName"
                                            placeholder="First name"
                                            value={company.adminFirstName}
                                            onChange={handleInputChange}
                                        />
                                        <FormControl
                                            type="text"
                                            name="adminLastName"
                                            id="adminLastName"
                                            placeholder="Last name"
                                            value={company.adminLastName}
                                            onChange={handleInputChange}
                                        />
                                    </InputGroup>
                                    {errors.adminName && (
                                        <div className="text-danger">{errors.adminName}</div>
                                    )}
                                </FormGroup>
                                <FormGroup className="col-md-6 mt-4">
                                    <FormLabel>Admin Email</FormLabel>

                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaEnvelope />
                                        </InputGroup.Text>
                                        <FormControl
                                            type="adminUserId"
                                            name="adminUserId"
                                            id="adminUserId"
                                            placeholder="Enter admin email"
                                            value={company.adminUserId}
                                            onChange={handleInputChange}
                                        />
                                        <InputGroup.Text>@</InputGroup.Text>
                                        <FormControl
                                            type="text"
                                            name="domain"
                                            id="domain"
                                            placeholder="Enter Company Email Domain"
                                            value={company.domain}
                                            onChange={handleInputChange}
                                        />
                                    </InputGroup>
                                    {errors.adminUserId && (
                                        <div className="text-danger">{errors.adminUserId}</div>
                                    )}
                                    {errors.domain && (
                                        <div className="text-danger">{errors.domain}</div>
                                    )}
                                </FormGroup>
                                <FormGroup className="col-md-6 mt-4">
                                    <FormLabel>Admin Phone Number</FormLabel>

                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FaPhone />
                                        </InputGroup.Text>

                                        <FormControl
                                            type="text"
                                            name="adminPhone"
                                            id="adminPhone"
                                            placeholder="555-555-1212"
                                            value={company.adminPhone}
                                            onChange={handleInputChange}
                                        />
                                    </InputGroup>
                                    {errors.adminPhone && (
                                        <div className="text-danger">{errors.adminPhone}</div>
                                    )}
                                </FormGroup>

                                <FormGroup className="mt-4">
                                    <FormCheck>
                                        <FormCheck.Input
                                            type="checkbox"
                                            id="termsCheckbox"
                                            value={termsAccepted}
                                            onChange={(e) => setTermsAccepted(e.target.checked)}
                                        />
                                        <FormCheck.Label htmlFor="termsCheckbox">
                                            I have read and agree to the{' '}
                                            <a href="/terms" target="_blank">
                                                Terms of Service
                                            </a>
                                            .
                                        </FormCheck.Label>
                                    </FormCheck>
                                    {errors.terms && (
                                        <div className="text-danger mt-2">{errors.terms}</div>
                                    )}
                                </FormGroup>
                            </Row>
                            <div className="d-flex align-middle mt-4">
                                {' '}
                                {/* This ensures vertical alignment */}
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={!termsAccepted || !formFilled}>
                                    Add Company
                                </Button>
                                {alertMessage && (
                                    <p className="text-danger ms-3 align-self-center">
                                        Woops! {alertMessage}
                                    </p>
                                )}
                                {/* Typo corrected & added margin for spacing */}
                            </div>
                        </Form>
                    </PkCard>
                </Container>
            )}
            {submitted && (
                <Container className="main-container">
                    <h2 className="mt-5 mb-5 text-center pk-text">
                        <FaHeart /> Thank you!
                    </h2>
                    <PkCard title="Welcome to Privakey Passwordless SSO">
                        <p>
                            Thanks for signing up. We look forward to helping you migrate away from
                            passwords. If you have any questions or concerns please contact{' '}
                            <a href="mailto:support@privakey.com">support@privakey.com</a>
                        </p>
                        <hr />
                        <h4 className="pk-text">
                            <FaEnvelopeOpen className="me-3" />
                            Check your email!
                        </h4>
                        <p>
                            We have sent you an email to verify your email address and continue the
                            process.
                        </p>
                        <p>You'll be up and running in no time. Here's what to expect:</p>
                        <ol className="ms-4">
                            <li>
                                <strong>You'll get an email.</strong> We already mentioned that
                                didn't we? This is used to confirm you possess the email you
                                entered: {company.adminUserId}@{company.domain}. It also is the
                                start point for you to create a secure login.
                            </li>
                            <li>
                                <strong>You'll create an authenticator.</strong> Privakey SSO is
                                build around passwordless technologies. The email will have
                                instructions on how to create your authenticator.{' '}
                            </li>
                            <li>
                                <strong>You'll verify Domain ownership.</strong> After you create
                                your Authenticator you will need to verify your domain. You'll be
                                prompted to start the process the first time you login.
                            </li>
                        </ol>
                    </PkCard>
                </Container>
            )}
        </>
    );
};

export default SelfNewCompany;
