import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTable from '../Utils/dynamicTable';
import PkCard from '../Cards/PKCard';
import { FaBoxes, FaExternalLinkAlt, FaInfoCircle } from 'react-icons/fa';

import { getCompanyServiceProviders } from '../../actions/companyActions';
import { getUserSessionInfo, getUserLastAccessed } from '../../actions/userActions';
import { useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';

// Dummy Data

const CompanyServiceProviders = () => {
    const dispatch = useDispatch();
    const providers = useSelector((state) => state.companies.currentCompany.serviceProviders);
    const [providersArray, setProvidersArray] = useState([]);
    const [activeSessions, setActiveSessions] = useState([]);
    const [lastAccessed, setLastAccessed] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = useSelector((state) => state.users.viewingUser);
    const [pageUser, setPageUser] = useState({ companyGuid: '', guid: '' });

    const location = useLocation();
    const { pathname } = location;
    const isAdminView = pathname.includes('admin');

    useEffect(() => {
        if (user && user.guid !== pageUser.guid && user.companyGuid !== pageUser.companyGuid) {
            setPageUser(user);
            const fetchData = async () => {
                dispatch(getCompanyServiceProviders(user.companyGuid));
                let sessions = await getUserSessionInfo(user.companyGuid, user.guid);
                let lastAccessed = await getUserLastAccessed(user.companyGuid, user.guid);
                setActiveSessions(sessions);
                setLastAccessed(lastAccessed);
                setLoading(false);
            };
            fetchData();
        }
    }, [dispatch, user, pageUser]);

    const [showHelpContent, setShowHelpContent] = useState(false);
    const helpContent = (
        <>
            <PkCard
                title={<div className="col text-left">Service Provider Help</div>}
                link={<span onClick={() => handleHelpClose()}>X</span>}>
                <p>
                    {' '}
                    These are the current services that your company has configured for SSO. You can
                    access these services with Privakey Passwordless SSO. Depending on your location
                    you will not need to login again to access the service.
                </p>
                <p>
                    If there are site you expect to see here but do not please contact your Privakey
                    SSO Administrator.
                </p>
                <p>
                    {' '}
                    For more information, visit{' '}
                    <a
                        target="blank"
                        href="https://help.privakey.com/usingthesso/theaccessManager/">
                        Privakey SSO Support - Access Manager
                    </a>
                </p>
            </PkCard>
        </>
    );
    const handleHelpClose = () => {
        setShowHelpContent(false);
    };

    const replace = useCallback((user, startPoint) => {
        let re = /\${([^}]+)}/g;
        let objMap = { user };
        return startPoint.replace(re, (_match, p1) => {
            const props = p1.split('.');
            const obj = objMap[props[0]];
            return obj ? (obj[props[1]] ? obj[props[1]] : '') : '';
        });
    }, []);

    useEffect(() => {
        if (providers && activeSessions && lastAccessed) {
            const enabledServiceProviders = providers
                .filter((provider) => provider.status === 'active')
                .map(({ name, guid, startPoint }) => {
                    let row = {
                        name,
                        guid
                    };
                    const hasSession = activeSessions.find(
                        (session) => session.serviceProviderGuid === guid
                    );
                    row.name = {
                        data: name,
                        tooltip: hasSession ? { date: hasSession.timeStamp } : ''
                    };

                    const beenAccessed = lastAccessed.find(
                        (accessed) => accessed.serviceProviderGuid === guid
                    );
                    row.lastAccessed = beenAccessed ? { date: beenAccessed.timeStamp } : '';
                    if (!isAdminView) {
                        if (startPoint) {
                            let link = replace(user, startPoint);
                            row.quickAccess = {
                                data: (
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        <FaExternalLinkAlt />
                                    </a>
                                ),
                                tooltip: `Visit ${name}`
                            };
                        } else {
                            row.quickAccess = { data: '-', tooltip: 'Not configured' };
                        }
                    }

                    return row;
                });

            setProvidersArray(enabledServiceProviders);
        }
    }, [providers, activeSessions, lastAccessed, isAdminView, user, replace]);

    if (!providersArray) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                No Service Providers have been set up.
            </div>
        );
    }

    return (
        <>
            {showHelpContent ? (
                helpContent
            ) : (
                <PkCard
                    title={
                        <>
                            <FaBoxes /> Service Providers{' '}
                        </>
                    }
                    link={
                        <span onClick={() => setShowHelpContent(true)}>
                            <FaInfoCircle />
                        </span>
                    }>
                    <div className="ml-10">
                        {loading ? (
                            <ScaleLoader />
                        ) : providersArray && providersArray.length > 0 ? (
                            <DynamicTable
                                style={{ marginLeft: '20%' }}
                                data={providersArray}
                                useHeaders={true}
                            />
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                Your Privakey SSO administrator has not set up any Service
                                Providers.
                            </div>
                        )}
                    </div>
                </PkCard>
            )}
        </>
    );
};

export default CompanyServiceProviders;
