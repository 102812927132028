import { combineReducers } from 'redux';
import { LOG_OUT } from '../actions/types';
import userReducer from './userReducer';
import authReducer from './authReducer';
import companyReducer from './companyReducer';
import systemReducer from './systemReducer';

const appReducer = combineReducers({
    users: userReducer,
    user: authReducer,
    companies: companyReducer,
    system: systemReducer
});

// ** SELECTORS ** //
export const getCompanyGuid = (state) => {
    return state.companies.currentCompany.guid;
};

export const getLoginRequest = (state) => {
    return state.user.loginRequest;
};

export const getLoggedInUser = (state) => {
    return state.user.currentUser;
};

const rootReducer = (state, action) => {
    if (action.type === LOG_OUT) {
        state = undefined;
    }
    return appReducer(state, action);
};
export default rootReducer;
