import React, { useState, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import logo from '../images/securedByPrivakeyNoKeyhole.png';
import { ScaleLoader } from 'react-spinners';
import { refreshUser } from '../actions/authActions';
import Login from './authentication/Login';
import { useHistory } from 'react-router-dom';

function Home() {
    const history = useHistory();

    const [showLogin, setShowLogin] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const validateStatus = (status) => {
            return status < 400 || status === 404 || status === 401 || status === 500;
        };

        const handleRefreshUser = async () => {
            try {
                const action = refreshUser(validateStatus);
                const res = await dispatch(action);

                if (
                    res.status === 401 ||
                    res.status === 504 ||
                    res.status === 404 ||
                    res.status === 500
                ) {
                    setShowLogin(true);
                } else {
                    //SSO Login Information Needs to Go here.
                    history.push('/accessmanager');
                }
            } catch (error) {
                console.log(error);
                // Handle any errors that occur during the dispatch
            }
        };

        handleRefreshUser();
    }, [dispatch, history]);

    return (
        <>
            {showLogin && <Login />}
            {!showLogin && (
                <div className="d-flex align-items-center vh-66">
                    <Container>
                        <Card className="pk-card vh-30 text-center">
                            <Card.Header>
                                <p>Checking for existing session, please wait.</p>
                            </Card.Header>
                            <Card.Body className="d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <ScaleLoader color={'orange'} className="mt-auto" />
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <div className="d-flex justify-content-center">
                                    <img src={logo} height="60" alt="Secured by Privakey" />
                                </div>
                            </Card.Footer>
                        </Card>
                    </Container>
                </div>
            )}
        </>
    );
}

export default Home;
