import React, { useCallback, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { ScaleLoader } from 'react-spinners';
import { create, parseCreationOptionsFromJSON } from '@github/webauthn-json/browser-ponyfill';
import { registerPasskey } from '../../actions/authActions';
import { Button, Form } from 'react-bootstrap';

const Passkey = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasCalled, setHasCalled] = useState(false);
    const [description, setDescription] = useState('Passkey Authenticator');
    const [buttonClicked, setButtonClicked] = useState(false);
    const submitButtonRef = useRef(null);

    const getGuidFromUrl = (url) => {
        let guidStartPoint = url.lastIndexOf('/') + 1;
        return url.substr(guidStartPoint);
    };

    const register = useCallback(
        async (description) => {
            if (
                props.passkeyConfig &&
                props.token &&
                props.user &&
                props.successCallback &&
                !hasCalled
            ) {
                setHasCalled(true);
                const options = parseCreationOptionsFromJSON({
                    publicKey: props.passkeyConfig
                });
                let response;
                try {
                    response = await create(options);
                } catch (err) {
                    props.failureCallback(err);
                }
                if (response) {
                    let postBody = {
                        name: description,
                        credential: response,
                        st: props.token
                    };
                    const res = await registerPasskey(
                        props.user.guid,
                        props.user.companyGuid,
                        postBody
                    );
                    if (res) {
                        let user = props.user.email;

                        let storageObj = window.localStorage.getItem(user);
                        if (storageObj) {
                            storageObj = JSON.parse(storageObj);
                        } else {
                            storageObj = { authenticators: [] };
                        }
                        storageObj.authenticators.push({
                            guid: getGuidFromUrl(res.location),
                            companyGuid: props.user.companyGuid
                        });

                        window.localStorage.setItem(user, JSON.stringify(storageObj));
                        setIsLoading(false);
                        props.successCallback(res);
                    }
                }
            }
        },
        [props, hasCalled]
    );

    // useEffect(() => {
    //     register();
    // }, [register]);

    function handleDescriptionChange(event) {
        const newDesc = event.target.value;
        setDescription(newDesc);
    }

    function handleSubmit(event) {
        event.preventDefault();
        setButtonClicked(true);
        register(description);
    }

    return (
        <div>
            {/* {!isLoading && (
                <Card className="pk-card" border="success">
                    <Card.Header>
                        <InlineIcon icon="exclamation-circle" />
                        Credential Ready
                    </Card.Header>
                    <Card.Body className="center">
                        {props.descriptionTextComplete && (
                            <Card.Text>{props.descriptionTextComplete}</Card.Text>
                        )}
                        {!props.descriptionTextComplete && (
                            <Card.Text>Bound this device using passkey</Card.Text>
                        )}
                    </Card.Body>
                </Card>
            )} */}
            {/* {isLoading && ( */}
            <Card className="pk-card">
                <Card.Body className="center">
                    <h5>Adding a Passkey</h5>
                    {props.descriptionText && <Card.Text>{props.descriptionText}</Card.Text>}
                    {!props.descriptionText && (
                        <Card.Text>Optional: Give the Passkey a Nickname</Card.Text>
                    )}
                    {!buttonClicked && (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="privakeyAuthenticatorDesc">
                                <Form.Label>Authenticator Nickname</Form.Label>

                                <Form.Control
                                    type="text"
                                    autoFocus
                                    defaultValue={description}
                                    data-lpignore="true"
                                    placeholder="Enter description"
                                    onBlur={handleDescriptionChange}
                                    onChange={handleDescriptionChange}
                                />
                                {/* <Form.Text className="text-muted">
                                                    <p>
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error}
                                                            </span>
                                                        ) : (
                                                            <>
                                                                Please use your Company Email
                                                                Address{' '}
                                                                {domain && (
                                                                    <span className="italic-text">
                                                                        {'(<'}user{'>'}@{domain}
                                                                        {')'}
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </p>
                                                </Form.Text> */}
                            </Form.Group>
                            <Button
                                ref={submitButtonRef}
                                variant="success"
                                type="submit"
                                disabled={buttonClicked}>
                                Register
                            </Button>
                        </Form>
                    )}
                    {buttonClicked && isLoading && <ScaleLoader />}
                </Card.Body>
            </Card>
            {/* )} */}
        </div>
    );
};

export default Passkey;
