import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { createUser } from '../../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';

import { Can } from '../../../ability-context';
import ability from '../../../ability';

import {
    Container,
    Form,
    FormGroup,
    FormLabel,
    Button,
    FormControl,
    FormCheck
} from 'react-bootstrap';
import TopNav from '../../../components/Navigation/TopNav';
import PkCard from '../../../components/Cards/PKCard';
import { FaUser } from 'react-icons/fa';

const CustomForm = ({ profileFields, changeHandler, errors }) => {
    return (
        <div>
            {profileFields.map((field) => {
                const fieldKey = field.key;
                const errorKey = `profile.${fieldKey}`;
                const error = errors[errorKey] || null;

                return (
                    <CustomFormField
                        field={field}
                        key={fieldKey}
                        value={profileFields[fieldKey]}
                        onChange={changeHandler}
                        error={error}
                    />
                );
            })}
        </div>
    );
};

const CustomFormField = ({ field, value, onChange, error }) => {
    const { key, displayName } = field;

    return (
        <FormGroup className="mt-3">
            <FormLabel>{displayName}</FormLabel>
            <FormControl type="text" name={key} value={value} onChange={onChange} />
            {error && <div className="text-danger">{error}</div>} {/* Render error message */}
        </FormGroup>
    );
};

const NewUser = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const company = useSelector((state) => state.companies.currentCompany);
    const history = useHistory();
    const priorPagePath = location.state.returnPath;
    const [customProfileFields, setCustomProfileFields] = useState([]);

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        profile: {},
        roles: []
    });

    useEffect(() => {
        const filteredProfileFields = company.userProfileInfo.filter(
            (item) => item.key !== 'alternateEmail'
        );

        const updatedProfile = {};

        filteredProfileFields.forEach((keyObj) => {
            const { key } = keyObj;
            updatedProfile[key] = '';
        });
        setCustomProfileFields(filteredProfileFields);

        setUser((prevUser) => ({
            ...prevUser,
            profile: {
                ...prevUser.profile,
                ...updatedProfile
            }
        }));
    }, [company]);

    const [errors, setErrors] = useState({});

    //Static Field Validation
    const staticSchema = yup.object().shape({
        firstName: yup.string().required('First Name is Required'),
        lastName: yup.string().required('Last Name is Required'),
        email: yup
            .string()
            .email('Invalid email')
            .required('Email is required')
            .test('companyDomain', 'Email must end with company domain', (value) => {
                if (!value) return false; // Return false if value is empty
                const emailDomain = value.split('@')[1]; // Get the domain part of the email
                return emailDomain === company.domain; // Return true if the domain matches the company domain
            }),
        profile: yup.object().shape({
            alternateEmail: yup.string().email('Invalid Email.')
        })
    });

    // Custom Field 'is Required' Validation
    const dynamicSchemaObject = {
        profile: yup.object().shape({})
    };

    customProfileFields.forEach((field) => {
        const { displayName, key, required } = field;

        const fieldSchema = required
            ? yup.string().required(`${displayName} is Required`)
            : yup.string();

        dynamicSchemaObject.profile = dynamicSchemaObject.profile.shape({
            ...dynamicSchemaObject.profile.fields,
            [key]: fieldSchema
        });
    });
    const validationSchema = staticSchema.concat(yup.object().shape(dynamicSchemaObject));

    // Change Handlers

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined // Clear the error for the changed field
        }));
    };

    const handleCustomFieldInputChange = (e) => {
        const { name, value } = e.target;

        const updatedUser = {
            ...user,
            profile: {
                ...user.profile, // Keep the existing properties in the profile object
                [name]: value // Update the specified property
            }
        };

        setUser(updatedUser);
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            roles: checked
                ? [...prevUser.roles, value]
                : prevUser.roles.filter((role) => role !== value)
        }));
    };

    // Submit Handler

    const handleSubmit = (e) => {
        e.preventDefault();
        validationSchema
            .validate(user, { abortEarly: false })
            .then(() => {
                setErrors({});
                dispatch(
                    createUser(
                        user.firstName,
                        user.lastName,
                        user.email,
                        user.profile,
                        user.roles,
                        company.guid
                    )
                )
                    .then(() => {
                        const alert = {
                            message: `Successfully Added User:  ${user.firstName} ${user.lastName}.`,
                            variant: 'success'
                        };
                        history.push(priorPagePath, { alert });
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response?.status === 422 && error.response?.data?.message) {
                            setErrors({ email: error.response.data.message });
                        }
                    });
            })
            .catch((validationErrors) => {
                // Validation failed, update the errors state
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    const path = error.path; // Get the error path
                    if (path.startsWith('profile.')) {
                        // If the error path starts with 'profile.', it's for the profile object
                        newErrors[path] = error.message;
                    } else {
                        // Otherwise, it's for the top-level fields
                        newErrors[path] = error.message;
                    }
                });
                setErrors(newErrors);
            });
    };

    const authorized = ability.can('actAs', 'superAdmin') || ability.can('actAs', 'admin');

    return (
        <>
            {authorized && (
                <>
                    <TopNav />
                    <Container className="main-container">
                        <h4 className="mt-5 mb-5">
                            <FaUser /> Add a New User to the {company.name} SSO.
                        </h4>
                        <PkCard>
                            <Form onSubmit={handleSubmit}>
                                <hr></hr>
                                <h5>Standard Profile Fields</h5>
                                <FormGroup controlId="firstName">
                                    <FormLabel>First Name:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="firstName"
                                        value={user.firstName}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.firstName}
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <FormGroup className="mt-3" controlId="lastName">
                                    <FormLabel>Last Name:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="lastName"
                                        value={user.lastName}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastName}
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <FormGroup className="mt-3" controlId="email">
                                    <FormLabel>Email:</FormLabel>
                                    <FormControl
                                        type="email"
                                        name="email"
                                        value={user.email}
                                        placeholder={`<userid>@${company.domain}`}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <FormGroup className="mt-3" controlId="alternateEmail">
                                    <FormLabel>Alternate Email:</FormLabel>
                                    <FormControl
                                        type="alternateEmail"
                                        name="alternateEmail"
                                        value={user.profile.alternateEmail}
                                        onChange={handleCustomFieldInputChange}
                                        isInvalid={!!errors['profile.alternateEmail']}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors['profile.alternateEmail']}{' '}
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <hr className="mt-4"></hr>
                                {customProfileFields.length > 0 && (
                                    <h6>Custom Company Profile Fields</h6>
                                )}
                                <CustomForm
                                    profileFields={customProfileFields}
                                    changeHandler={handleCustomFieldInputChange}
                                    errors={errors || {}}
                                />

                                <FormGroup className="bg-light  mt-3 p-3" controlId="roles">
                                    <FormLabel>Roles:</FormLabel>
                                    <FormCheck
                                        type="checkbox"
                                        id="adminCheckbox"
                                        label="Admin"
                                        value="Admin"
                                        checked={user.roles.includes('Admin')}
                                        onChange={handleCheckboxChange}
                                    />
                                    <Can I="actAs" a="superAdmin">
                                        <FormCheck
                                            type="checkbox"
                                            id="superAdminCheckbox"
                                            label="Super Admin"
                                            value="SuperAdmin"
                                            checked={user.roles.includes('SuperAdmin')}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Can>
                                </FormGroup>

                                <Button className="mt-3" type="submit">
                                    Create User
                                </Button>
                                {'   '}
                                <Link to={priorPagePath}>
                                    <Button className="mt-3" variant="danger">
                                        Cancel
                                    </Button>
                                </Link>
                            </Form>
                        </PkCard>
                    </Container>
                </>
            )}
        </>
    );
};

export default NewUser;
