import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentUser: {
        companyName: ''
    },
    isAuthenticated: false,
    sessionExpired: false,
    loginRequest: {
        email: '',
        requestGuid: ''
    }
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initiateSamlRequest(state, action) {
            state.samlRequest = action.payload;
        },
        initiateZendeskRequest(state, action) {
            state.zendeskRequest = action.payload;
        },
        initiateLoginRequest(state, action) {
            state.loginRequest = {
                email: action.payload.email,
                requestGuid: action.payload.requestGuid,
                type: action.payload.loginType,
                assertionOptions: action.payload.assertionOptions
            };
            state.currentUser.companyName = action.payload.companyName;
        },
        setLoginOptions(state, action) {
            state.loginOptions = action.payload;
        },
        clearLoginRequest(state) {
            state.loginRequest = {
                email: '',
                requestGuid: ''
            };
        },
        clearLoginOptions(state) {
            delete state.loginOptions;
        },
        cancelLoginRequest(state, action) {
            state.loginRequest = {
                loginType: action.payload.loginType,
                loginResult: action.payload.loginResult
            };
        },
        setCurrentUser(state, action) {
            state.currentUser = {
                ...state.currentUser,
                ...action.payload
            };
            state.isAuthenticated = true;
            state.sessionExpired = false;
        },
        setDashboard(state, action) {
            state.dashboard = action.payload;
        },
        setSessionExpired(state) {
            state.sessionExpired = true;
        }
    }
});
export const actions = authSlice.actions;
export default authSlice.reducer;
