import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import QRCode from 'qrcode.react';
import { Alert } from 'react-bootstrap';
import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';

const QRComponent = (props) => {
    // const bindQRData = useSelector((state) => state.bindQRData); // replace 'state.bindQRData' with the actual state property name

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < 768); // Set the mobile state based on the window width
        };

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('Bind data copied to clipboard');
        });
    };

    const url = `authwallet://st=${props.sessionToken}&appSpaceGuid=${props.appSpaceGuid}&appSpaceName=${props.appSpaceName}`;

    const encodedUrl = encodeURI(url);
    console.log(encodedUrl);

    return (
        <div>
            <Card className="pk-card">
                <Card.Header>
                    <p>
                        You'll need the Privakey App to set up your authenticator. If you don't have
                        the app you can find it on:{'  '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="pk-link click-here"
                            href="https://play.google.com/store/apps/details?id=com.privakey.authwallet">
                            <FaGooglePlay /> Google Play
                        </a>{' '}
                        or the{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="pk-link click-here"
                            href="https://apps.apple.com/us/app/authwallet/id1552057206">
                            <FaAppStoreIos /> iOS App Store
                        </a>{' '}
                        now!
                    </p>
                </Card.Header>
                <Card.Body className="text-center">
                    <Card.Text>
                        {isMobile && (
                            <Alert variant="info">
                                On a mobile device?{' '}
                                <a className="pk-link click-here" href={encodedUrl}>
                                    Open Privakey
                                </a>{' '}
                                to set up your authenticator.
                            </Alert>
                        )}

                        {!isMobile && <>Use your phone to scan the QR code below.</>}
                    </Card.Text>
                    <div style={{ maxWidth: '100%', width: '100%', overflow: 'auto' }}>
                        <QRCode
                            value={encodedUrl}
                            size={200}
                            includeMargin={true}
                            level={'H'}
                            onClick={() => copyUrl(encodedUrl)}
                        />
                    </div>
                    <Card.Footer>
                        <p>
                            Don't have the app? Get it on:{'  '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="pk-link click-here"
                                href="https://play.google.com/store/apps/details?id=com.privakey.authwallet">
                                Google Play
                            </a>{' '}
                            or{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="pk-link click-here"
                                href="https://apps.apple.com/us/app/authwallet/id1552057206">
                                the App Store
                            </a>{' '}
                            now!
                        </p>
                    </Card.Footer>
                </Card.Body>
            </Card>
        </div>
    );
};

export default QRComponent;
