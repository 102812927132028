import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';

import TopNav from '../../../components/Navigation/TopNav';
import PkCard from '../../../components/Cards/PKCard';
import DynamicTable from '../../../components/Utils/dynamicTable';

import ServiceProvidersComponent from '../../../components/Company/ServiceProvidersComponent';
import LocationsComponent from '../../../components/Company/LocationsComponent';
import AuthenticatorsComponent from '../../../components/Company/AuthenticatorsComponent';

import { FaBuilding, FaPlus, FaEdit, FaCog, FaInfo } from 'react-icons/fa';
import { Can } from '../../../ability-context';

import {
    fetchCompanyUsers,
    getCompany,
    getCompanyLocations,
    getCompanyServiceProviders
} from '../../../actions/companyActions';
import { actions as userActions } from '../../../reducers/userReducer';
import { getAllSystemServiceProviders } from '../../../actions/systemActions';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function Company() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname + location.search;
    const state = { returnPath: currentPath };
    const [alert, setAlert] = useState(location.state?.alert || { message: '', variant: '' });
    const [alertVisible, setAlertVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAlertVisible(false);
            setAlert({ message: '', variant: '' });
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const company = useSelector((state) => state.companies.currentCompany);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCompanyData = async () => {
            if (company) {
                document.title = 'Super Admin - View Company';

                if (!company.name) {
                    setLoading(true);

                    try {
                        if (!company.name) {
                            dispatch(getCompany(company.guid));
                        }
                        if (!company.users?.records) {
                            dispatch(fetchCompanyUsers(company.guid, 10));
                        }

                        if (!company.locations) {
                            dispatch(getCompanyLocations(company.guid));
                        }

                        if (!company.serviceProviders) {
                            dispatch(getCompanyServiceProviders(company.guid));
                        }
                        dispatch(getAllSystemServiceProviders());
                    } catch (error) {
                        console.log('Error fetching company data:', error);
                        setLoading(false);
                    }
                } else if (company.users?.records) {
                    //if we have users, this was a refresh
                    setLoading(false);
                }
            }
        };

        fetchCompanyData();
    }, [dispatch, company]);

    useEffect(() => {
        if (company.name && company.users?.records && company.locations) {
            setLoading(false);
        }
    }, [company]);

    const selectedUser = async (user) => {
        dispatch(userActions.select({ companyGuid: user.companyGuid, guid: user.guid }));
        state.linkText = `${company.name} Dashboard`;
        history.push('/superadmin/company/users/view', state);
    };

    const hasNext = () => {
        const indices = company.users?.indices;
        return indices?.length > 1 && indices[indices.length - 1];
    };

    const getNext = (evt) => {
        evt.preventDefault();
        const indices = company.users?.indices;
        if (company?.guid && hasNext()) {
            const index = indices.length > 1 && indices[indices.length - 1];
            dispatch(fetchCompanyUsers(company.guid, 10, index));
        }
    };

    const hasPrevious = () => {
        const indices = company.users?.indices;
        return indices?.length > 2;
    };

    const getPrevious = (evt) => {
        evt.preventDefault();
        const indices = company.users?.indices;
        if (company?.guid && hasPrevious()) {
            const index = indices.length > 2 && indices[indices.length - 3];
            dispatch(fetchCompanyUsers(company.guid, 10, index, true));
        }
    };

    const CompanyInfo = () => {
        if (!company?.owner) {
            return;
        }
        const tosDate = new Date(company.tOSConsent.Timesamp);
        const tosDateDisplay = tosDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        let formattedNumber;
        try {
            const phoneNumber = parsePhoneNumberFromString(company.owner.Phone, 'US');
            console.log('phonenumber ', phoneNumber);
            if (phoneNumber && phoneNumber.isValid()) {
                formattedNumber = phoneNumber.formatInternational();
            } else {
                console.log('else hit');
                formattedNumber = phoneNumber.formatNational();
            }
        } catch (error) {
            formattedNumber = company.owner.Phone;
        }
        return (
            <PkCard
                title={
                    <>
                        <FaInfo /> Company Information
                    </>
                }>
                <h5>Account Owner</h5>
                <div className="ms-4">
                    <p>
                        <strong>Name:</strong> {company.owner.FirstName} {company.owner.LastName}
                    </p>
                    <p>
                        <strong>Email:</strong> {company.owner.Email}
                    </p>
                    <p>
                        <strong>Phone Number:</strong> {formattedNumber}
                    </p>
                </div>
                {company.tOSConsent && (
                    <>
                        <h5>Terms Agreement</h5>
                        <div className="ms-4">
                            <p>
                                <strong>Agreed By</strong> {company.tOSConsent.FirstName}{' '}
                                {company.tOSConsent.LastName}, {company.tOSConsent.Email}{' '}
                                <strong>on {tosDateDisplay}</strong>
                            </p>
                        </div>
                    </>
                )}
            </PkCard>
        );
    };

    return (
        <Can I="actAs" a="superAdmin">
            <TopNav />
            <Container className="main-container">
                <div className="d-flex justify-content-between">
                    <h4 className="mt-5 mb-5 text-danger">Super Admin</h4>
                    <h5 className="mt-5 mb-5">
                        <FaBuilding /> Company: {company.name}{' '}
                    </h5>
                </div>
                <Row xs={1} md={2} lg={3} className="g-4">
                    <Col className="w-100">
                        {alertVisible && alert.message && (
                            <Alert variant={alert.variant || 'success'}>{alert.message}</Alert>
                        )}
                    </Col>
                    {!loading && company.owner && (
                        <Col className="w-100">
                            <CompanyInfo />
                        </Col>
                    )}
                    <Col className="w-100">{!loading && <ServiceProvidersComponent />}</Col>
                    <Col className="w-100">{!loading && <LocationsComponent />}</Col>

                    <Col className="w-100">
                        {/* Authenticators */}
                        {!loading && <AuthenticatorsComponent />}
                    </Col>
                    {!loading && (
                        <Col className="w-100">
                            <PkCard
                                className="w-100"
                                title={<>{company.name} Users</>}
                                link={
                                    <Link
                                        to={{
                                            pathname: `/superadmin/company/users/new`,
                                            state: state
                                        }}>
                                        <>
                                            <FaPlus /> Add User
                                        </>
                                    </Link>
                                }
                                footer={
                                    <div className="d-flex justify-content-end me-10">
                                        <Link
                                            to={{
                                                pathname: '/superadmin/company/users/settings',
                                                state: state
                                            }}>
                                            <>
                                                <FaCog /> User Profile Settings
                                            </>
                                        </Link>
                                    </div>
                                }>
                                <Row>
                                    {company.users?.records && company.users?.records.length > 0 ? (
                                        <DynamicTable
                                            // style={{ marginLeft: '20%' }}
                                            useHeaders={true}
                                            data={company.users.records}
                                            action={selectedUser}
                                            icon={<FaEdit />}
                                            selectedColumns={[
                                                'firstName',
                                                'lastName',
                                                'email',
                                                'status'
                                            ]}
                                            hasNext={hasNext}
                                            getNext={getNext}
                                            hasPrevious={hasPrevious}
                                            getPrevious={getPrevious}
                                        />
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p>No Users Found.</p>
                                        </div>
                                    )}
                                </Row>
                            </PkCard>
                        </Col>
                    )}
                </Row>
            </Container>
        </Can>
    );
}

export default Company;
