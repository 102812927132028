import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { logoutUser, refreshUser, sessionExpired, getCurrentUser } from '../actions/authActions';
import LoadingCard from './Cards/LoadingCard';

const ErrorBoundary = ({
    isAuthenticated,
    logoutUser,
    refreshUser,
    sessionExpired,
    getCurrentUser,
    children
}) => {
    const [error, setError] = useState(null);
    const [errorInfo, setErrorInfo] = useState(null);
    const [button, setButton] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const setupButton = (link, onClick, text) => {
        const newButton = { link, onClick, text };
        setButton(newButton);
    };

    const resetState = () => {
        setError('');
        setErrorInfo('');
    };

    useEffect(() => {
        const logout = () => {
            resetState();
            logoutUser(false);
        };

        const fullLogout = () => {
            resetState();
            logoutUser(true);
        };

        const handleSessionExpired = () => {
            sessionExpired(true);
        };
        const requestInterceptor = axios.interceptors.request.use((req) => {
            return req;
        });

        const responseInterceptor = axios.interceptors.response.use(
            (res) => {
                return res;
            },
            (error) => {
                let message = '';
                let info = '';

                if (error.response) {
                    // We only want to generically handle *some* errors, otherwise they should be
                    // handled by the individual component
                    if (error.response.status === 500 || error.response.status === 404) {
                        message = error.message;
                        info = error.response.data;
                        if (info.message) {
                            info = info.message;
                        }
                        setupButton('/', resetState, 'Return to Home');
                    } else if (error.response.status === 401 && isAuthenticated) {
                        message = 'Your session has expired, please log in again';

                        let buttonLink = '/';
                        setupButton(buttonLink, logout, 'Return to Login');

                        handleSessionExpired();
                    } else if (error.response.status === 403 && isAuthenticated) {
                        setIsLoading(true);
                        refreshUser().then((res) => {
                            if (res.data?.status === 'suspended') {
                                message = 'Your account has been suspended.';
                                info =
                                    'If you were not expecting this error, please contact your System Administrator.';
                                setupButton('/', fullLogout, 'Return to Login');
                            } else {
                                message =
                                    'You no longer have permission to perform that action. Your permissions have been re-loaded.';
                                info =
                                    'Someone else may have changed your role(s). If you were not expecting this error, please contact your System Administrator.';
                                setupButton('/', resetState, 'Return to Home');
                            }
                            setError(message);
                            setErrorInfo(info);
                            setIsLoading(false);
                        });
                    }
                } else if (error.request) {
                    message = error.message;
                }

                if (message) {
                    setError(message);
                    setErrorInfo(info);
                }

                throw error;
            }
        );

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [isAuthenticated, getCurrentUser, logoutUser, refreshUser, sessionExpired]);

    if (error) {
        return (
            <div className="d-flex align-items-center vh-66">
                <Container>
                    <LoadingCard
                        className="pk-card"
                        border="danger"
                        isLoading={isLoading}
                        spinnerColor="red">
                        <Card.Header>Something went wrong</Card.Header>
                        <Card.Body>
                            <Card.Text>{error}</Card.Text>
                            {errorInfo && <details>{errorInfo}</details>}
                        </Card.Body>
                        <Card.Footer>
                            <Link to={button.link} onClick={button.onClick} className="pk-link">
                                <Button variant="danger">{button.text}</Button>
                            </Link>
                        </Card.Footer>
                    </LoadingCard>
                </Container>
            </div>
        );
    }

    return children;
};

const mapState = (state) => ({
    isAuthenticated: state.user.isAuthenticated
});
export default connect(mapState, { logoutUser, refreshUser, sessionExpired, getCurrentUser })(
    ErrorBoundary
);
