import React from 'react';
import axios from 'axios';

// Routing
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Casl
import { AbilityContext } from './ability-context';
import ability from './ability';

//Pages
import Home from './pages/Home';
import Bind from './pages/authentication/Bind';
import Login from './pages/authentication/Login';
import AccessManager from './pages/accessmanager/AccessManager';
import Admin from './pages/admin/AdminDashboard';
import NewLocation from './pages/admin/locations/NewLocation';
import ViewEditLocation from './pages/admin/locations/ViewEditLocation';
import Users from './pages/admin/users/ViewUsers';
import UserSettings from './pages/admin/users/UserSettings';
import NewUser from './pages/admin/users/NewUser';
import UploadUsers from './pages/admin/users/UploadUsers';
import ViewUser from './pages/admin/users/ViewUser';
import ServiceProviders from './pages/admin/serviceproviders/ServiceProviders';
import PendingAuth from './pages/authentication/pending';
import CompanyList from './pages/superadmin/CompanyList';
import ViewCompany from './pages/superadmin/company/ViewCompany';
import AddNewCompany from './pages/superadmin/company/NewCompany';
import ErrorPage from './pages/errorPage';
import SelfNewCompany from './pages/signup/SelfSignup';
import Terms from './pages/signup/Terms';
import ReactGA from 'react-ga4';

// Components
import ErrorBoundary from './components/ErrorBoundary';

import './App.css';

const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS;
ReactGA.initialize(gaTrackingId);

// Set up Default Axios Parameters.
let updateAxios = () => {
    axios.defaults.baseURL = '/api';
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
    axios.defaults.xsrfHeaderName = 'CSRF-TOKEN';
    axios.defaults.validateStatus = (status) => {
        return status >= 200 && status < 400;
    };
};

updateAxios();

function App(props) {
    return (
        <Provider store={store}>
            <AbilityContext.Provider value={ability}>
                <Router>
                    <ErrorBoundary>
                        <Switch>
                            {/* Base Routes */}
                            <Route exact path="/" component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/bind" component={Bind} />
                            <Route exact path="/pending" component={PendingAuth} />
                            <Route exact path="/signup" component={SelfNewCompany} />
                            <Route exact path="/terms" component={Terms} />

                            {/* AccessManager */}
                            <PrivateRoute exact path="/accessmanager" component={AccessManager} />

                            {/* Company Admin Routes */}
                            <PrivateRoute exact path="/admin/" component={Admin} />
                            <PrivateRoute
                                exact
                                path="/admin/serviceproviders"
                                component={ServiceProviders}
                            />
                            <PrivateRoute
                                exact
                                path="/admin/location"
                                component={ViewEditLocation}
                            />
                            <PrivateRoute
                                exact
                                path="/admin/location/new"
                                component={NewLocation}
                            />
                            <PrivateRoute exact path="/admin/users/" component={Users} />
                            <PrivateRoute exact path="/admin/users/new" component={NewUser} />
                            <PrivateRoute exact path="/admin/users/bulk" component={UploadUsers} />
                            <PrivateRoute
                                exact
                                path="/admin/users/settings"
                                component={UserSettings}
                            />
                            <PrivateRoute
                                exact
                                path="/admin/users/view"
                                render={() => <ViewUser context={'admin'} />}
                            />

                            {/* Super Admin PrivateRoutes */}
                            <PrivateRoute exact path="/superadmin/" component={CompanyList} />
                            <PrivateRoute
                                exact
                                path="/superadmin/company"
                                component={ViewCompany}
                            />
                            <PrivateRoute
                                exact
                                path="/superadmin/company/new"
                                component={AddNewCompany}
                            />
                            <PrivateRoute
                                exact
                                path="/superadmin/company/users/settings"
                                component={UserSettings}
                            />
                            <PrivateRoute
                                exact
                                path="/superadmin/company/user"
                                component={ViewUser}
                            />
                            <PrivateRoute
                                exact
                                path="/superadmin/company/location"
                                component={ViewEditLocation}
                            />
                            <PrivateRoute
                                exact
                                path="/superadmin/company/location/new"
                                component={NewLocation}
                            />

                            <PrivateRoute
                                exact
                                path="/superadmin/company/users/new"
                                component={NewUser}
                            />
                            <PrivateRoute
                                exact
                                path="/superadmin/company/users/view"
                                render={() => <ViewUser context={'superadmin'} />}
                            />
                            {/* Error Routes */}
                            <Route
                                exact
                                path="/error"
                                render={(props) => (
                                    <ErrorPage {...props} type={404} message="Page not found." />
                                )}
                            />
                            <Route
                                exact
                                path="/sperror"
                                render={(props) => (
                                    <ErrorPage
                                        {...props}
                                        type={404}
                                        subType="spError"
                                        message="Page not found."
                                    />
                                )}
                            />
                            <Redirect to="/error" />
                        </Switch>
                    </ErrorBoundary>
                </Router>
            </AbilityContext.Provider>
        </Provider>
    );
}

export default App;
