import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopNav from '../../components/Navigation/TopNav';
import { Container, Card } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { FaBuilding, FaEdit, FaPlus } from 'react-icons/fa';
import DynamicTable from '../../components/Utils/dynamicTable';
import PkCard from '../../components/Cards/PKCard';
import ability from '../../ability';

import { getCompanies } from '../../actions/companyActions';
import { actions as companyActions } from '../../reducers/companyReducer';

function CompanyList() {
    const companies = useSelector((state) => state.companies.companies);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        document.title = 'Super Admin';
        dispatch(companyActions.clearCompany());
        dispatch(getCompanies());
    }, [dispatch]);

    const selectCompany = async (company) => {
        await dispatch(companyActions.selectCompany({ guid: company.guid }));
        history.push('/superadmin/company');
    };

    const authorized = ability.can('actAs', 'superAdmin');

    if (!authorized) {
        return <Redirect to={{ pathname: '/error', state: { type: 401 } }} />;
    }

    return (
        <>
            {authorized && (
                <>
                    <TopNav />a
                    <Container className="main-container">
                        <h4 className="mb-5 text-danger">Super Administration</h4>

                        <PkCard
                            className="w-100"
                            title={
                                <>
                                    <FaBuilding /> Companies
                                </>
                            }
                            link={
                                <Link to="superadmin/company/new">
                                    <>
                                        <FaPlus /> Add a New Company
                                    </>
                                </Link>
                            }>
                            <Card.Body>
                                {companies.length > 0 ? (
                                    <DynamicTable
                                        data={companies}
                                        action={selectCompany}
                                        icon={<FaEdit />}
                                        useHeaders={true}
                                        selectedColumns={[
                                            'name',
                                            'domain',
                                            'seats',
                                            'userCount',
                                            'status'
                                        ]}
                                    />
                                ) : (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <ScaleLoader />
                                    </div>
                                )}
                            </Card.Body>
                        </PkCard>
                    </Container>
                </>
            )}
        </>
    );
}

export default CompanyList;
