import axios from 'axios';
import { actions as systemActions } from '../reducers/systemReducer';

export const getAllSystemServiceProviders = () => async (dispatch) => {
    try {
        const response = await axios.get('serviceProviders/');
        dispatch(systemActions.getAllServiceProviders(response.data));
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
