import React from 'react';
import PkCard from '../../components/Cards/PKCard';
import { Container } from 'react-bootstrap';
const Terms = () => {
    return (
        <Container className="main-container">
            <PkCard>
                <div className="terms-document" id="terms-document">
                    <h1>Privakey SSO Terms of Service</h1>
                    <p>
                        Thank you for signing up to use Privakey Single Sign-On, applications and
                        associated software (collectively, “Privakey SSO” or SSO). By accessing or
                        using our SSO, you are agreeing to the terms below. If there is a conflict
                        between these terms and additional terms applicable to a given service, the
                        additional terms will control for that conflict. Collectively, we refer to
                        the terms below, any additional terms, terms within the accompanying
                        documentation, and any applicable policies and guidelines as the “Terms.”
                        You agree to comply with the Terms and that the Terms control your
                        relationship with us. So please read all the Terms carefully.
                    </p>
                    <p>
                        Under the Terms, “Privakey” refers to Privakey, Inc., with offices at 630
                        Freedom Business Center, Suite 300 King of Prussia, PA , United States,
                        unless set forth otherwise in additional terms applicable for a given
                        capability. We may refer to “Privakey” as “we”, “our”, or “us” in the Terms.
                    </p>
                    <h2>Beta Phase</h2>
                    <p>
                        The Beta version of the Privakey Passwordless SSO is a functionally complete
                        Passwordless SSO that can be used by companies to enable Passwordless,
                        secure access to commonly used cloud services. During the Beta you can:
                    </p>
                    <ul className="ms-4">
                        <li>Connect Key Services</li>
                        <li>Configuration Configure Locations</li>
                        <li>Add and Manage Users</li>
                    </ul>
                    <h3>Beta LIMITATIONS </h3>
                    <p>
                        During the Beta Period the number of Services available will be initially
                        limited. The SSO is designed to be easy to use, configure and maintain. As
                        such all connected Service Providers are set-up to require minimal
                        configuration and tested before being released. We will be regularly adding
                        new service providers. Also - during the Beta Period, ancillary services
                        have not been implemented. These include self-sign-up and billing - the
                        service is free.
                    </p>
                    <h3>Beta Participation</h3>
                    <p>
                        Requirements We ask that Beta Participants actively provide feedback and
                        feature suggestions.
                    </p>
                    <h2>Account and Registration</h2>
                    <h3>Accepting the Terms</h3>
                    <p>
                        You may not use SSO and may not accept the Terms if (a) you are not of legal
                        age to form a binding contract with Privakey, or (b) you are a person barred
                        from using the SSO under the applicable laws of the United States or other
                        countries including the country in which you are resident or from which you
                        use SSO.
                    </p>
                    <h3>Entity Level Acceptance</h3>
                    <p>
                        If you are using SSO on behalf of an entity, you represent and warrant that
                        you have authority to bind that entity to the Terms and by accepting the
                        Terms, you are doing so on behalf of that entity (and all references to
                        “you” in the Terms refer to that entity).
                    </p>
                    <h3>Registration</h3>
                    <p>
                        In order to access SSO you may be required to provide certain information
                        (such as identification or contact details) as part of the registration
                        process or as part of your continued use of the SOS. Any registration
                        information you give to Privakey will always be accurate and up to date and
                        you’ll inform us promptly of any updates.
                    </p>
                    <h2>Using The SSO</h2>
                    <h3>End Users</h3>
                    <p>
                        You will require your end users to comply with (and not knowingly enable
                        violation of) applicable law, regulation, and the Terms.{' '}
                    </p>
                    <h3>
                        Compliance with Law, Third Party Rights, and Other Affiliated Terms of
                        Service
                    </h3>
                    <p>
                        You will comply with all applicable law, regulation, and third party rights
                        (including without limitation laws regarding the import or export of data or
                        software, privacy, and local laws). You will not use the SSO to encourage or
                        promote illegal activity or violation of third party rights. You will not
                        violate any other terms of service with Privakey (or its affiliates).
                    </p>
                    <h3> Permitted Access</h3>
                    <p>
                        You will only access (or attempt to access) SOS by the means described in
                        the SOS documentation.
                    </p>
                    <h3>Privakey SOS Limitations </h3>
                    <p>
                        Privakey sets and enforces limits on your use of the SOS (e.g. limiting the
                        number of service providers, end-user seat licenses) during the Beta and
                        beta phases. You agree to, and will not attempt to circumvent, such
                        limitations. If you would like to use Privakey beyond these limits, you must
                        obtain our express consent.{' '}
                    </p>
                    <h3> Open Source Software</h3>
                    <p>
                        Some of the software required by or included in SOS may be offered under an
                        open source license. Open source software licenses constitute separate
                        written agreements. To the limited extent the open source software license
                        expressly supersedes the Terms, the open source license instead sets forth
                        your agreement for the use of the applicable open source software.{' '}
                    </p>
                    <h3>Communication with Privakey</h3>
                    <p>
                        We may send you certain communications in connection with your use of SOS.
                        Please review the applicable SOS documentation for information about opting
                        out of certain types of communication. Feedback If you provide feedback or
                        suggestions about Privakey Authentication Services, then we (and those we
                        allow) may use such information without obligation to you.
                    </p>
                    <h3>Non-Exclusivity</h3>
                    <p>
                        The Terms are non-exclusive. You acknowledge that Privakey may develop
                        products or services that may compete with the Clients or any other products
                        or services.
                    </p>
                    <h3>Monitoring</h3>
                    <p>
                        YOU AGREE THAT PRIVAKEY MAY MONITOR USE OF SOS TO ENSURE QUALITY, IMPROVE
                        OUR PRODUCTS AND SERVICES, AND VERIFY YOUR COMPLIANCE WITH THE TERMS. You
                        will not interfere with this monitoring. Privakey may use any technical
                        means to overcome such interference. Privakey may suspend access to the SOS
                        by you or your end-users without notice if we reasonably believe that you
                        are in violation of the Terms.
                    </p>
                    <h2>Termination</h2>
                    <h3>Termination</h3>
                    <p>
                        {' '}
                        You may stop using SOS at any time with or without notice. Further, if you
                        want to terminate the Terms, you must provide Privakey with prior written
                        notice and upon termination, cease your use of the SOS. Privakey reserves
                        the right to terminate the Terms with you or discontinue your access to the
                        SOS or any portion or feature or your access thereto for any reason and at
                        any time without liability or other obligation to you.
                    </p>{' '}
                    <h3>Your Obligations Post-Termination</h3>
                    <p>
                        Upon any termination of the Terms or discontinuation of your access to SOS,
                        you will immediately stop using the SOS and cease all use of the Privakey
                        Brand Features. Privakey may independently communicate with any Privakey
                        end-user whose account(s) are associated with your subscription to provide
                        notice of the termination of your right to use SOS.
                    </p>
                    <h3>Surviving Provisions</h3>{' '}
                    <p>
                        When the Terms come to an end, those terms that by their nature are intended
                        to continue indefinitely will continue to apply.{' '}
                    </p>
                    <h2>Liability</h2>
                    <h3> WARRANTIES</h3>
                    <p>
                        EXCEPT AS EXPRESSLY SET OUT IN THE TERMS, NEITHER PRIVAKEY NOR ITS SUPPLIERS
                        OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SOS. FOR EXAMPLE, WE
                        DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT ACCESSED THROUGH THE SOS, THE
                        SPECIFIC FUNCTIONS OF THE SOS, OR THEIR RELIABILITY, AVAILABILITY, OR
                        ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SOS ”AS IS”.
                    </p>
                    <p>
                        SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                        EXCEPT AS EXPRESSLY PROVIDED FOR IN THE TERMS, TO THE EXTENT PERMITTED BY
                        LAW, WE EXCLUDE ALL WARRANTIES, GUARANTEES, CONDITIONS, REPRESENTATIONS, AND
                        UNDERTAKINGS.
                    </p>
                    <h3>LIMITATION OF LIABILITY</h3>
                    <p>
                        WHEN PERMITTED BY LAW, PRIVAKEY, AND PRIVAKEY’S SUPPLIERS AND DISTRIBUTORS,
                        WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA; FINANCIAL
                        LOSSES; OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.
                        TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF PRIVAKEY, AND ITS
                        SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIM UNDER THE TERMS, INCLUDING FOR ANY
                        IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SOS
                        DURING THE SIX MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY. IN
                        ALL CASES, PRIVAKEY, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE
                        FOR ANY EXPENSE, LOSS, OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
                    </p>
                    <h3>Indemnification</h3>
                    <p>
                        Unless prohibited by applicable law, if you are a business, you will defend
                        and indemnify Privakey, and its affiliates, directors, officers, employees,
                        and users, against all liabilities, damages, losses, costs, fees (including
                        legal fees), and expenses relating to any allegation or third-party legal
                        proceeding to the extent arising from:
                    </p>
                    <ul className="ms-4">
                        <li>your misuse or your end user’s misuse of the SOS; or –</li>
                        <li>your violation or your end user’s violation of the Terms.</li>
                    </ul>
                    <h2>General Provision </h2>
                    <h3>Modification</h3>
                    <p>
                        We may modify the Terms or any portion to, for example, reflect changes to
                        the law or changes to our SOS. You should look at the Terms regularly. We’ll
                        post notice of modifications to the Terms within the documentation of the
                        SOS, to this website, and/or in the Privakey SSO Admin console. Changes will
                        not apply retroactively and will become effective no sooner than 30 days
                        after they are posted. But changes addressing new functions for the SOS or
                        changes made for legal reasons will be effective immediately. If you do not
                        agree to the modified Terms for the SOS, you should discontinue your use of
                        the SOS. Your continued use of the SOS constitutes your acceptance of the
                        modified Terms.{' '}
                    </p>
                    <h3>U.S. Federal Agency Entities</h3>
                    <p>
                        The SOS was developed solely at private expense and is commercial computer
                        software within the meaning of the applicable U.S. Federal Acquisition
                        Regulation and agency supplements thereto.
                    </p>
                    <h3>General Legal Terms</h3>
                    <p>
                        We each agree to contract in the English language. If we provide a
                        translation of the Terms, we do so for your convenience only and the English
                        Terms will solely govern our relationship. The Terms do not create any third
                        party beneficiary rights or any agency, partnership, or joint venture.
                        Nothing in the Terms will limit either party’s ability to seek injunctive
                        relief. We are not liable for failure or delay in performance to the extent
                        caused by circumstances beyond our reasonable control. If you do not comply
                        with the Terms, and Privakey does not take action right away, this does not
                        mean that Privakey is giving up any rights that it may have (such as taking
                        action in the future). If it turns out that a particular term is not
                        enforceable, this will not affect any other terms. The Terms are the entire
                        agreement between you and Privakey relating to its subject and supersede any
                        prior or contemporaneous agreements on that subject. For information about
                        how to contact Privakey, please visit our contact page.
                    </p>
                    <p>
                        {' '}
                        Except as set forth below: (i) the laws of Pennsylvania, U.S.A. will apply
                        to any disputes arising out of or related to the Terms or the SOS and (ii)
                        ALL CLAIMS ARISING OUT OF OR RELATING TO THE TERMS OR THE SOS WILL BE
                        LITIGATED EXCLUSIVELY IN THE FEDERAL OR COMMONWEALTH COURTS OF PHILADELPHIA,
                        PENNSYLVANIA, USA, AND YOU AND PRIVAKEY CONSENT TO PERSONAL JURISDICTION IN
                        THOSE COURTS.
                    </p>
                    <p>
                        {' '}
                        If you are accepting the Terms on behalf of a United States federal
                        government entity, then the following applies instead of the paragraph
                        above: the laws of the United States of America, excluding its conflict of
                        laws rules, will apply to any disputes arising out of or related to the
                        Terms or the SOS. Solely to the extent permitted by United States Federal
                        law: (i) the laws of the COMMONWEALTH OF PENNSYLVANIA will apply in the
                        absence of applicable federal law; and (ii) FOR ALL CLAIMS ARISING OUT OF OR
                        RELATING TO THE TERMS OR THE SOS, THE PARTIES CONSENT TO PERSONAL
                        JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE COURTS IN PHILADELPHIA,
                        PENNSYLVANIA.
                    </p>
                    <p>
                        {' '}
                        If you are accepting the Terms on behalf of a United States city, county, or
                        state government entity, then the following applies instead of the paragraph
                        above: the parties agree to remain silent regarding governing law and venue.
                    </p>
                </div>
            </PkCard>
        </Container>
    );
};

export default Terms;
