import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Alert, Row, Button, Col, Card } from 'react-bootstrap';
import QRComponent from './QRComponent';
import Passkey from '../Cards/Passkey';
import { createNewAuthenticator, checkBindStatus } from '../../actions/authActions';
import { ScaleLoader } from 'react-spinners';
import { actions as userActions } from '../../reducers/userReducer';
import { supported } from '@github/webauthn-json/browser-ponyfill';

function AddAuthenticatorModal({ show, handleCloseAddAuthenticator }) {
    const dispatch = useDispatch();
    const [sessionToken, setSessionToken] = useState(null);
    const [appSpaceGuid, setAppSpaceGuid] = useState(null);
    const [appSpaceName, setAppSpaceName] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertVisible, setAlertVisible] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [location, setLocation] = useState(null);
    const [st, setSt] = useState(null);
    const [checkBindCompleted, setCheckBindCompleted] = useState(false);
    const [checkBindCallComplete, setCheckBindCallComplete] = useState(false);
    const [hasShown, setHasShown] = useState(false);
    const [qrLoading, setQrLoading] = useState(true);
    const [authenticatorType, setAuthenticatorType] = useState(null);
    const [passkeyConfig, setPasskeyConfig] = useState(null);
    const [passkeySupported, setPasskeySupported] = useState(false);

    const viewingUser = useSelector((state) => state.users.viewingUser);

    const checkBind = useCallback(
        async (location, st) => {
            const res = await checkBindStatus(`${location}?st=${st}`);
            if (res?.privakeyDeviceGuid) {
                dispatch(userActions.createNewAuthenticator(res));
                setAlertMessage(`Successfully Enabled Privakey on ${res.name}`);
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                    setAuthenticatorType(null);
                    handleCloseAddAuthenticator();
                }, 2000);
                setCheckBindCompleted(true);
            }
            setCheckBindCallComplete(true);
        },
        [dispatch, handleCloseAddAuthenticator]
    );
    useEffect(() => {
        if (show) {
            //whenenver show state changes, we want to clear things out to be safe
            setAuthenticatorType(null);
            setPasskeyConfig(null);
            setConfirmClose(false);
            setAlertMessage(null);
            setAlertVisible(false);
            setSessionToken(null);
            setAppSpaceGuid(null);
            setAppSpaceName(null);
            setHasShown(false);
            setQrLoading(true);
        }
    }, [show]);

    useEffect(() => {
        if (
            !checkBindCompleted &&
            checkBindCallComplete &&
            hasShown &&
            authenticatorType === 'pkauth'
        ) {
            setCheckBindCallComplete(false);
            setTimeout(() => {
                checkBind(location, st);
            }, 1000);
        }
    }, [
        location,
        st,
        hasShown,
        checkBindCallComplete,
        checkBindCompleted,
        authenticatorType,
        checkBind
    ]);

    useEffect(() => {
        if (show && !hasShown && authenticatorType === 'pkauth') {
            setHasShown(true);
            setQrLoading(true);
            setCheckBindCompleted(false);
            setCheckBindCallComplete(false);
            createNewAuthenticator(
                viewingUser.guid,
                viewingUser.companyGuid,
                authenticatorType
            ).then((res) => {
                setSessionToken(res.sessionToken);
                setAppSpaceGuid(res.appSpaceGuid);
                setAppSpaceName(res.appSpaceName);
                setQrLoading(false);
                setLocation(res.location);
                setSt(res.st);
                checkBind(res.location, res.st);
            });
        }
    }, [show, viewingUser, hasShown, authenticatorType, checkBind]);

    useEffect(() => {
        if (show && !hasShown && authenticatorType === 'passkey') {
            setHasShown(true);
            setQrLoading(true);
            setPasskeyConfig(null);
            createNewAuthenticator(
                viewingUser.guid,
                viewingUser.companyGuid,
                authenticatorType
            ).then((res) => {
                setQrLoading(false);
                setPasskeyConfig(res.registrationOptions);
                setLocation(res.location);
                setSt(res.st);
            });
        }
    }, [show, viewingUser, hasShown, authenticatorType]);

    const doClose = () => {
        handleCloseAddAuthenticator();
        // setAuthenticatorType(null);
        // setPasskeyConfig(null);
        // setConfirmClose(false);
    };

    const handleClose = () => {
        if (confirmClose) {
            doClose();
        } else {
            setConfirmClose(true);
        }
    };

    const registrationSuccess = async (res) => {
        const authenticator = await checkBindStatus(`${res.location}?st=${st}`);
        dispatch(userActions.createNewAuthenticator(authenticator));
        setAlertMessage(`Successfully added a a new authenticator.`);
        setAlertVisible(true);
        setTimeout(() => {
            doClose();
        }, 2000);
    };

    const registrationFailure = (res) => {
        setAlertMessage(`Passkey registration canceled`);
        setAlertVisible(true);
        setAuthenticatorType(null);
        setHasShown(false);
    };

    const selectAuthenticator = (type) => {
        setAlertMessage(null);
        setAlertVisible(false);
        setAuthenticatorType(type);
    };

    useEffect(() => {
        const isSupported = supported();
        console.log(`Passkey is${!isSupported ? ' not' : ''} supported`);
        setPasskeySupported(isSupported);
    }, []);

    return (
        <Modal
            centered
            size="lg"
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => setConfirmClose(true)}>
            <Modal.Header closeButton={!confirmClose}>
                <Modal.Title className="w-100 text-center">Add New Authenticator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {authenticatorType ? (
                    <>
                        {authenticatorType === 'pkauth' ? (
                            <>
                                {qrLoading || !sessionToken ? (
                                    <ScaleLoader />
                                ) : (
                                    <>
                                        {!confirmClose && sessionToken && (
                                            <>
                                                <QRComponent
                                                    sessionToken={sessionToken}
                                                    appSpaceGuid={appSpaceGuid}
                                                    appSpaceName={appSpaceName}
                                                />
                                                {alertVisible && alertMessage && (
                                                    <Alert variant="success">{alertMessage}</Alert>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {qrLoading ? (
                                    <ScaleLoader />
                                ) : (
                                    !confirmClose && (
                                        <>
                                            <Passkey
                                                descriptionText={`Creating a Passkey on this device for ${viewingUser.email}.`}
                                                descriptionTextComplete={`A Passkey was successfully created for ${viewingUser.email}.`}
                                                passkeyConfig={passkeyConfig}
                                                token={st}
                                                user={viewingUser}
                                                successCallback={registrationSuccess}
                                                failureCallback={registrationFailure}
                                            />
                                            {alertVisible && alertMessage && (
                                                <Alert variant="success">{alertMessage}</Alert>
                                            )}
                                        </>
                                    )
                                )}
                            </>
                        )}
                    </>
                ) : (
                    !confirmClose && (
                        <>
                            <Row className=" d-flex xl={2} g-4 justify-content-center">
                                {passkeySupported ? (
                                    <>
                                        <Col>
                                            <Card className="h-100">
                                                <div className="d-flex mt-2 justify-content-center">
                                                    <Button
                                                        className="passkey-btn-solid"
                                                        onClick={() => {
                                                            selectAuthenticator('passkey');
                                                        }}>
                                                        Add Passkey
                                                    </Button>
                                                </div>
                                                <div className="m-2">
                                                    <small>
                                                        Passkeys are native platform authenticators.
                                                        They are great for your daily workstation.
                                                        You'll use a PIN or Biometric on your device
                                                        to access the Passkey stored on your
                                                        computer.
                                                    </small>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="h-100">
                                                <div className="d-flex mt-2 justify-content-center">
                                                    <Button
                                                        className="privakey-btn-solid"
                                                        onClick={() => {
                                                            selectAuthenticator('pkauth');
                                                        }}>
                                                        Add Privakey
                                                    </Button>
                                                </div>
                                                <div className="m-2">
                                                    <small>
                                                        Privakey is a mobile authenticator that uses
                                                        biometrics or a PIN. You can use it anywhere
                                                        to access the SSO. It's great for day-to-day
                                                        use <strong>AND</strong> you can use on
                                                        infrequently used computers. It is also
                                                        perfect for account access recovery. Learn
                                                        more:{' '}
                                                        <a
                                                            href="https://help.privakey.com/usingthesso/theprivakeyApp/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="pk-link click-here">
                                                            The Privakey App
                                                        </a>
                                                    </small>
                                                </div>
                                            </Card>
                                        </Col>
                                    </>
                                ) : (
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <Card className="h-100">
                                            <div className="d-flex mt-2 justify-content-center">
                                                <Button
                                                    className="privakey-btn-solid"
                                                    onClick={() => {
                                                        selectAuthenticator('pkauth');
                                                    }}>
                                                    Add Privakey
                                                </Button>
                                            </div>
                                            <div className="m-2">
                                                <small>
                                                    Privakey is a mobile authenticator that uses
                                                    biometrics or a PIN. You can use it anywhere to
                                                    access the SSO. It's great for day-to-day use{' '}
                                                    <strong>AND</strong> you can use on infrequently
                                                    used computers. It is also perfect for account
                                                    access recovery. Learn more:{' '}
                                                    <a
                                                        href="https://help.privakey.com/usingthesso/theprivakeyApp/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="pk-link click-here">
                                                        The Privakey App
                                                    </a>
                                                </small>
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                            {alertVisible && alertMessage && (
                                <Alert variant="warning">{alertMessage}</Alert>
                            )}
                            {passkeySupported && (
                                <Row className="d-flex mt-4 justify-content-center align-items-center">
                                    <Col>
                                        <Card
                                            className="d-flex align-items-center justify-content-center"
                                            style={{ height: '100%' }}>
                                            <span className="m-2">
                                                <strong>Pro Tip:</strong> Create both Authenticators
                                                and never get locked out.
                                            </span>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )
                )}
                {confirmClose && (
                    <Row>
                        <Col className="ml-2">
                            <Row>
                                <p>
                                    You haven't completed adding a new authenticator. Are you sure
                                    you want to exit?
                                </p>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            {confirmClose && (
                <Modal.Footer>
                    <Button onClick={handleClose} variant="danger">
                        Exit
                    </Button>
                    {'  '}
                    <Button onClick={() => setConfirmClose(false)} variant="outline-primary">
                        Continue Add
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default AddAuthenticatorModal;
