import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

const AdminAddAuthenticatorModal = ({
    show,
    handleCloseAdminAddAuthenticatorModal,
    handleAddAdminAuthenticator
}) => {
    const viewingUser = useSelector((state) => state.users.viewingUser);

    return (
        <>
            {viewingUser && (
                <Modal
                    centered
                    show={show}
                    backdrop="static"
                    keyboard={false}
                    onHide={handleCloseAdminAddAuthenticatorModal}>
                    <Modal.Header>
                        <Modal.Title>
                            Add New Authenticator for {viewingUser.firstName} {viewingUser.lastName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <p>
                                Clicking Add Authenticator below will start the process of securely
                                adding a new authenticator for {viewingUser.firstName}{' '}
                                {viewingUser.lastName}.
                            </p>
                            <p>
                                The will receive an email at {viewingUser.email}{' '}
                                {viewingUser.profile?.alternateEmail && (
                                    <> and {viewingUser.profile.alternateEmail}</>
                                )}{' '}
                                with instructions on how to set up their new Privakey Passwordless
                                Authenticator.
                            </p>
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseAdminAddAuthenticatorModal} variant="danger">
                            Cancel
                        </Button>
                        {'  '}
                        <Button onClick={handleAddAdminAuthenticator} variant="outline-primary">
                            Add Authenticator
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default AdminAddAuthenticatorModal;
