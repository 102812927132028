import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Col, Container, Row, Table, Tooltip } from 'react-bootstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaCheck, FaMinusSquare } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { Link, useLocation } from 'react-router-dom';

function splitCamelCaseString(input) {
    // Use a regular expression to split the camel case string
    const words = input.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words with a space and return the result
    return capitalizedWords.join(' ');
}

function formatDateValue(value) {
    let dateValue = '';
    if (value) {
        const date = new Date(value);
        const options = { timeZoneName: 'short' };

        dateValue = date.toLocaleString(undefined, options);
    }
    return dateValue;
}

function DynamicTable({
    data,
    linkTo,
    action,
    icon,
    color = 'primary',
    useHeaders = false,
    selectedColumns = null,
    columnHeaderMap = {},
    columnValueMap = {},
    hasNext = null,
    getNext = null,
    hasPrevious = null,
    getPrevious = null
}) {
    const canPaginate = hasNext && getNext && hasPrevious && getPrevious;
    const columns =
        selectedColumns && typeof selectedColumns === 'object'
            ? selectedColumns
            : Object.keys(data[0]).filter(
                  (column) => !column.toLowerCase().endsWith('guid') && column !== 'id'
              );
    let headers = [];
    if (useHeaders) {
        for (const column of columns) {
            headers.push(
                splitCamelCaseString(columnHeaderMap[column] ? columnHeaderMap[column] : column)
            );
        }
    }
    const hasLinkTo = linkTo && linkTo.length > 0;
    const hasAction = action && (typeof action === 'object' || action.length > 0);
    const linkClassName = `text-${color}`;
    const location = useLocation();
    const currentPath = location.pathname;

    const renderTooltip = (content) => <Tooltip>{formatValue(content)}</Tooltip>;
    // Helper function to convert array values to comma-delimited strings
    const formatValue = (value, column) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        } else if (typeof value === 'object' && value.data) {
            const val = formatValue(value.data, column);
            if (value.tooltip) {
                return (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <OverlayTrigger placement="right" overlay={renderTooltip(value.tooltip)}>
                            <div>{val}</div>
                        </OverlayTrigger>
                    </div>
                );
            } else {
                return val;
            }
        } else if (typeof value === 'object' && value.date) {
            let formattedDate = formatDateValue(value.date);
            if (value.prefix) {
                formattedDate = `${value.prefix} ${formattedDate}`;
            }
            return formattedDate;
        } else if (column?.toLowerCase().indexOf('date') > -1) {
            //this is date
            return formatDateValue(value);
        } else if (typeof value === 'boolean') {
            return value ? (
                <FaCheck style={{ color: 'green' }} />
            ) : (
                <FaMinusSquare style={{ color: 'red' }} />
            );
        } else if (columnValueMap[column]) {
            return columnValueMap[column][value] || value;
        }
        return value;
    };
    const PaginationNavigation = () => {
        return (
            <Row
                className="justify-content-between align-items-center"
                style={{ backgroundColor: '#e0e0e0' }}>
                <Col xs="auto" className="ms-3">
                    <Link
                        onClick={getPrevious}
                        style={{
                            color: !hasPrevious() ? 'gray' : '#d38b5f', // Change color based on disabled state
                            cursor: !hasPrevious() ? 'not-allowed' : 'pointer', // Change cursor style based on disabled state
                            textDecoration: 'none',
                            fontSize: '1.25rem', // Adjust the font size similar to h5
                            fontWeight: '500'
                        }}>
                        <FaAngleDoubleLeft /> Prev
                    </Link>
                </Col>

                <Col xs="auto" className="me-3">
                    <Link
                        onClick={getNext}
                        style={{
                            color: !hasNext() ? 'gray' : '#d38b5f', // Change color based on disabled state
                            cursor: !hasNext() ? 'not-allowed' : 'pointer', // Change cursor style based on disabled state
                            textDecoration: 'none',
                            fontSize: '1.25rem', // Adjust the font size similar to h5
                            fontWeight: '500'
                        }}>
                        Next <FaAngleDoubleRight />
                    </Link>
                </Col>
            </Row>
        );
    };
    return (
        <Container>
            {data && canPaginate && <PaginationNavigation />}
            <Table className="table">
                <thead>
                    {headers.length > 0 && (
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                            {(hasLinkTo || hasAction) && <th key="action"> </th>}
                        </tr>
                    )}
                </thead>
                <tbody>
                    {data?.map((row) => (
                        <tr key={row.id || row.guid}>
                            {columns.map((column) => (
                                <td key={column}>
                                    {formatValue(row[column], column)}{' '}
                                    {/* Use the formatValue function */}
                                </td>
                            ))}
                            {hasLinkTo ||
                            (hasAction &&
                                (typeof action === 'function' ||
                                    (typeof action === 'object' && action.check(row)))) ? (
                                <td className="text-center">
                                    <Link
                                        className={linkClassName}
                                        onClick={
                                            hasAction
                                                ? (evt) => {
                                                      return typeof action === 'function'
                                                          ? action(row, evt)
                                                          : action.action(row, evt);
                                                  }
                                                : null
                                        }
                                        to={
                                            hasLinkTo
                                                ? {
                                                      pathname: linkTo,
                                                      state: {
                                                          id: row.guid,
                                                          returnPath: currentPath
                                                      }
                                                  }
                                                : '#'
                                        }>
                                        {icon}
                                    </Link>
                                </td>
                            ) : (
                                <td></td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {data && canPaginate && <PaginationNavigation />}
        </Container>
    );
}

DynamicTable.propTypes = {
    data: PropTypes.array,
    linkTo: PropTypes.string,
    action: PropTypes.any,
    icon: PropTypes.element,
    color: PropTypes.string,
    useHeaders: PropTypes.bool,
    selectedColumns: PropTypes.array,
    columnHeaderMap: PropTypes.object,
    columnValueMap: PropTypes.object,
    hasNext: PropTypes.func,
    getNext: PropTypes.func,
    hasPrevious: PropTypes.func,
    getPrevious: PropTypes.func
};

export default DynamicTable;
