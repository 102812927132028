import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, Button, Navbar, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import logo from '../../images/securedByPrivakeyNoKeyhole.png';
import { ScaleLoader } from 'react-spinners';
import { FaExclamationCircle, FaHourglassEnd, FaCheckCircle } from 'react-icons/fa';
import {
    refreshUser,
    clearLoginRequest,
    checkLoginRequest,
    cancelLoginRequest,
    validatePasskey
} from '../../actions/authActions';

function PendingAuth() {
    // Get pending user login information

    const history = useHistory();
    const [statusDisplay, setStatusDisplay] = useState('');
    const requestGuid = useSelector((state) => state.user.loginRequest.requestGuid);
    const emailRef = useRef(useSelector((state) => state.user.loginRequest.email));
    const email = emailRef.current;
    const loginTypeRef = useRef(useSelector((state) => state.user.loginRequest.type));
    const loginType = loginTypeRef.current;
    const passkeyAssertionOptions = useSelector(
        (state) => state.user.loginRequest.assertionOptions
    );
    const samlRequestRef = useRef(useSelector((state) => state.user.samlRequest));
    const samlRequest = samlRequestRef.current;
    const zendeskRequyestRef = useRef(useSelector((state) => state.user.zendeskRequest));
    const zendeskRequest = zendeskRequyestRef.current;
    const dispatch = useDispatch();
    const companyName = samlRequest?.companyName;
    const resource = samlRequest?.resource || zendeskRequest?.resource || 'Access Manager';
    const [statusTimer, setStatusTimer] = useState(null);
    const [cancelDisabled, setCancelDisabled] = useState(false);
    const nextStepFunc = useRef(null);
    const formRef = useRef(null);

    const pending = useMemo(() => {
        return (
            <>
                <p>Pending Response</p>
                <ScaleLoader />
            </>
        );
    }, []);

    const failed = useMemo(() => {
        return (
            <>
                <p>Authentication Failed</p>
                <h1>
                    <FaExclamationCircle style={{ color: 'red' }} />
                </h1>
            </>
        );
    }, []);

    const rejected = useMemo(() => {
        return (
            <>
                <p>Authentication rejected on Authenticator</p>
                <h1>
                    <FaExclamationCircle style={{ color: 'yellow' }} />
                </h1>
            </>
        );
    }, []);

    const canceled = useMemo(() => {
        return (
            <>
                <p>Authentication canceled</p>
                <h1>
                    <FaExclamationCircle style={{ color: 'yellow' }} />
                </h1>
            </>
        );
    }, []);

    const timedout = useMemo(() => {
        return (
            <>
                <p>Authentication Timed Out</p>
                <h1>
                    <FaHourglassEnd style={{ color: 'red' }} />
                </h1>
            </>
        );
    }, []);
    const approved = useMemo(() => {
        return (
            <>
                <p>Authentication Approved!</p>
                <h1>
                    <FaCheckCircle style={{ color: 'green' }} />
                </h1>
            </>
        );
    }, []);

    const determineLoginStep = useCallback(() => {
        if (!document.hidden) {
            setStatusDisplay(approved);
            if (samlRequest) {
                if (samlRequest.samlType === 'get') {
                    console.log('Redirecting back to IDP');
                    //if there's a saml request, we want to redirect the browser back to the login
                    window.location.href = `/idp/${
                        samlRequest.companyGuid
                    }/login?SAMLRequest=${encodeURIComponent(samlRequest.samlRequest)}${
                        samlRequest.relayState
                            ? `&RelayState=${encodeURIComponent(samlRequest.relayState)}`
                            : ''
                    }`;
                } else if (samlRequest.samlType === 'post') {
                    const formElement = formRef.current;
                    if (formElement) {
                        formElement.submit(); // Submit the form programmatically
                    }
                }
            } else if (zendeskRequest) {
                window.location.href = `/api/auth/zendesk${
                    zendeskRequest.returnTo && zendeskRequest.returnTo !== 'none'
                        ? `?returnTo=${zendeskRequest.returnTo}`
                        : ''
                }`;
            } else {
                dispatch(refreshUser()).then(() => {
                    history.push('/accessmanager');
                });
            }
        } else {
            nextStepFunc.current = determineLoginStep;
        }
    }, [approved, history, samlRequest, zendeskRequest, dispatch]);

    useEffect(() => {
        // Define the event handlers
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Tab lost focus, take necessary actions
                console.log('Tab lost focus');
                // Perform your actions here
            } else {
                // Tab gained focus, take necessary actions
                console.log('Tab gained focus');
                if (nextStepFunc.current) {
                    nextStepFunc.current.call();
                }
            }
        };

        // Add event listeners
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Clean up the event listeners when component unmounts
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const backToLogin = useCallback(() => {
        if (!document.hidden) {
            dispatch(clearLoginRequest());
            setTimeout(() => {
                history.push('/');
            }, 2000);
        } else {
            nextStepFunc.current = backToLogin;
        }
    }, [dispatch, history]);

    const cancelLogin = async (evt) => {
        evt.preventDefault();
        clearTimeout(statusTimer);
        setCancelDisabled(true);
        await cancelLoginRequest(requestGuid);
        // nothing else to do right now. The web socket will trigger the next step
    };

    const checkLoginStatus = useCallback(async () => {
        try {
            const response = await checkLoginRequest(requestGuid);
            const { status } = response;

            console.log(status);
            switch (status) {
                case 'pending':
                    setStatusDisplay(pending);
                    setTimeout(() => {
                        checkLoginStatus();
                    }, 1000);
                    break;
                case 'approved':
                    determineLoginStep();
                    break;
                case 'timedout':
                    setStatusDisplay(timedout);
                    backToLogin();
                    break;
                case 'rejected':
                    setStatusDisplay(rejected);
                    backToLogin();
                    break;
                case 'canceled':
                    setStatusDisplay(canceled);
                    backToLogin();
                    break;
                default:
                    setStatusDisplay(failed);
                    backToLogin();
                    break;
            }
        } catch (error) {
            console.log(error);
            setStatusDisplay(failed);
        }
    }, [
        canceled,
        failed,
        pending,
        rejected,
        timedout,
        requestGuid,
        determineLoginStep,
        backToLogin
    ]);

    const openSocket = useCallback(
        (requestGuid) => {
            console.log(`request guid: ${requestGuid}`);
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
            const socket = new WebSocket(
                `${protocol}://${
                    process.env.NODE_ENV === 'production'
                        ? window.location.host
                        : process.env.REACT_APP_SERVER_LOCATION
                }/auth/checkRequest`
            );

            // WebSocket event handlers
            let statusTimer = null;
            let receivedMessage = false;
            socket.onopen = () => {
                console.log('WebSocket connection established');
                setStatusDisplay(pending);
                let waitTime;
                if (process.env.REACT_APP_SHOULD_POLL === 'true') {
                    waitTime = 1000;
                } else {
                    waitTime = 55 * 1000;
                }
                statusTimer = setTimeout(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(
                            JSON.stringify({
                                type: 'checkRequest',
                                requestGuid
                            })
                        );
                    }
                }, waitTime);
                setStatusTimer(statusTimer);
            };

            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                console.log('Received message:', message);
                const { status } = message;

                console.log(status);
                if (status === 'pending') {
                    setStatusDisplay(pending);
                    setTimeout(() => {
                        if (socket.readyState === WebSocket.OPEN) {
                            socket.send(
                                JSON.stringify({
                                    type: 'checkRequest',
                                    requestGuid
                                })
                            );
                        }
                    }, 1000);
                } else {
                    //only want to call this once
                    if (!receivedMessage) {
                        receivedMessage = true;
                        clearTimeout(statusTimer);
                        checkLoginStatus();
                    }
                }
                // Handle incoming WebSocket messages
            };

            socket.onclose = () => {
                console.log('WebSocket connection closed');
                if (!receivedMessage) {
                    //something went wrong, fall back to polling
                    clearTimeout(statusTimer);
                    checkLoginStatus();
                }
                // Handle WebSocket disconnection
            };
            return socket;
        },
        [checkLoginStatus, pending]
    );

    useEffect(() => {
        if (requestGuid) {
            if (loginType === 'pkauth') {
                const socket = openSocket(requestGuid);

                // Clean up the WebSocket connection on component unmount
                return () => {
                    socket.close();
                };
            } else {
                setStatusDisplay(pending);
                //handle passkey
                validatePasskey(requestGuid, passkeyAssertionOptions).then((response) => {
                    if (response?.result) {
                        let storageObj = window.localStorage.getItem(email);
                        if (storageObj) {
                            storageObj = JSON.parse(storageObj);
                        } else {
                            storageObj = { authenticators: [] };
                        }
                        if (!storageObj.authenticators) {
                            storageObj.authenticators = [];
                        }
                        if (
                            !storageObj.authenticators.some((authenticator) => {
                                return authenticator.guid === response.authenticatorGuid;
                            })
                        ) {
                            storageObj.authenticators.push({
                                guid: response.authenticatorGuid,
                                companyGuid: response.companyGuid
                            });
                        }

                        window.localStorage.setItem(email, JSON.stringify(storageObj));
                        determineLoginStep();
                    } else if (response?.error) {
                        setStatusDisplay(canceled);
                        cancelLoginRequest(requestGuid).then(() => {
                            backToLogin();
                        });
                    } else {
                        setStatusDisplay(failed);
                        backToLogin();
                    }
                });
            }
        }
    }, [
        requestGuid,
        loginType,
        passkeyAssertionOptions,
        pending,
        canceled,
        failed,
        email,
        openSocket,
        determineLoginStep,
        backToLogin
    ]);

    useEffect(() => {
        document.title = 'Pending Authentication';
    }, []);

    return (
        <>
            <Container>
                <Navbar>{}</Navbar>
                <div className="jumbotron flex text-center">
                    <h5>
                        Privakey Passwordless Single Sign On{' '}
                        {companyName && <strong> for {companyName} </strong>}
                    </h5>
                    <hr className="mb-8" />
                    <Row className="row-cols-1 row-cols-lg-2 d-flex justify-content-center g-4">
                        <Col>
                            <Card
                                className="mx-auto w-100"
                                style={{
                                    backgroundColor: 'white'
                                }}>
                                <Card.Body>
                                    {statusDisplay}
                                    <p></p>
                                    {resource && (
                                        <Row className="tight-row">
                                            <Col className="d-flex justify-content-end">
                                                <p className="font-weight-bold">
                                                    Resource Requested:
                                                </p>
                                            </Col>
                                            <Col className="d-flex justify-content-start">
                                                <p className="font-weight-bold">{resource}</p>
                                            </Col>
                                        </Row>
                                    )}
                                    {email && (
                                        <Row>
                                            <Col className="d-flex justify-content-end">
                                                <p className="font-weight-bold">
                                                    User Authenticating:
                                                </p>
                                            </Col>
                                            <Col className="d-flex justify-content-start">
                                                <p className="font-weight-bold">{email}</p>
                                            </Col>
                                        </Row>
                                    )}

                                    <hr />
                                    {loginType === 'pkauth' ? (
                                        <>
                                            <p className="text-left">
                                                You have been sent a challenge to your
                                                authenticator(s). Review the challenge on your
                                                device to approve the authentication. After
                                                approving the message you will be redirected to{' '}
                                                {resource}.
                                            </p>
                                            <hr />
                                            <Button
                                                variant="outline-secondary"
                                                className="btn-block"
                                                onClick={cancelLogin}
                                                disabled={cancelDisabled}>
                                                Cancel Authentication
                                            </Button>
                                        </>
                                    ) : (
                                        <p className="text-left">
                                            You are logging in with a passkey. After approving the
                                            authentication you will be redirected to {resource}.
                                        </p>
                                    )}

                                    <hr />
                                    <img src={logo} height="60" alt="Secured by Privakey" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
            {samlRequest && (
                <Form action={`/idp/${samlRequest.companyGuid}/login`} ref={formRef} method="post">
                    <Form.Control
                        type="hidden"
                        name="SAMLRequest"
                        value={samlRequest.samlRequest}
                    />
                    {samlRequest.relayState && (
                        <Form.Control
                            type="hidden"
                            name="RelayState"
                            value={samlRequest.relayState}
                        />
                    )}
                </Form>
            )}
        </>
    );
}

export default PendingAuth;
