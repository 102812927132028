import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, updateUser, updateUserStatus } from '../../actions/userActions';
import * as yup from 'yup';

import {
    Card,
    Row,
    Col,
    Form,
    FormGroup,
    FormLabel,
    Button,
    FormControl,
    InputGroup,
    FormCheck,
    Alert
} from 'react-bootstrap';

import { FaUser, FaEdit, FaSave, FaEyeSlash, FaEye, FaPause, FaPlay } from 'react-icons/fa';
import { Can } from '../../ability-context';
import { ScaleLoader } from 'react-spinners';

const CustomForm = ({ profileFields, disabled, changeHandler, fieldNames, errors }) => {
    const { alternateEmail, ...filteredProfileFields } = profileFields;
    const filteredProfileFieldKeys = Object.keys(filteredProfileFields).sort((a, b) => {
        const keyA = fieldNames.findIndex((item) => item.key === a);
        const keyB = fieldNames.findIndex((item) => item.key === b);
        return keyA - keyB;
    });
    return (
        <div>
            {filteredProfileFieldKeys.map((key) => {
                const fieldInfo = fieldNames.find((field) => field.key === key);
                const displayName = fieldInfo?.displayName || key;
                const errorKey = `profile.${key}`;
                const error = errors[errorKey];
                return (
                    <CustomFormField
                        key={key}
                        fieldKey={key}
                        displayName={displayName}
                        value={profileFields[key]}
                        onChange={changeHandler}
                        disabled={disabled}
                        error={error}
                    />
                );
            })}
        </div>
    );
};

const CustomFormField = ({ fieldKey, value, displayName, onChange, error, disabled }) => {
    return (
        <>
            <InputGroup className="mt-3">
                <InputGroup.Text>{displayName}:</InputGroup.Text>
                <FormControl
                    type="text"
                    name={'profile.' + fieldKey}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                />
            </InputGroup>
            {error && <div className="text-danger">{error}</div>} {/* Render error message */}
        </>
    );
};

const ViewUserCard = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.users.viewingUser);
    const currentUser = useSelector((state) => state.user.currentUser);
    const company = useSelector((state) => state.companies.currentCompany);

    const [loading, setLoading] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState(null);
    const [viewMore, setViewMore] = useState(false);
    const [customProfileFields, setCustomProfileFields] = useState([]);

    const [updatedUser, setUpdatedUser] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        companyDomain: '',
        profile: {},
        roles: []
    });

    useEffect(() => {
        if (user) {
            if (!user.email) {
                setLoading(true);
                dispatch(getUser(user.companyGuid, user.guid))
                    // .then(() => {
                    //     // The rest of your code to set roles, updateProfile, etc.
                    //     // setLoading(false);
                    // })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        } else {
            setLoading(true);
        }
    }, [dispatch, user]);

    useEffect(() => {
        //automatically clear out loading after a user is dispatched
        if (user && user.email) {
            setLoading(false);
        }
    }, [user]);

    const initializeUser = useCallback(() => {
        const roleNames = user?.userRoles?.map((userRole) => userRole.name) || [];
        setRoles(roleNames);

        const filteredProfileFields = company.userProfileInfo.filter(
            (item) => item.key !== 'alternateEmail'
        );
        setCustomProfileFields(filteredProfileFields);
        const updatedProfile = {}; // Define updatedProfile here

        filteredProfileFields.forEach((keyObj) => {
            const { key } = keyObj;
            console.log('Key ', key);

            if (!user.profile || !(key in user.profile)) {
                updatedProfile[key] = '';
            }
        });

        setUpdatedUser({
            firstName: user.firstName,
            lastName: user.lastName,
            emailId: user.email.split('@')[0],
            companyDomain: user.email.split('@')[1],
            profile: {
                ...user.profile, // Initialize profile with existing values
                ...updatedProfile // Include any additional profile fields as initialized in updatedProfile
            },
            roles: roleNames
        });
        setLoading(false);
        setErrors({});
    }, [user, company]);
    // Use a separate useEffect to handle user-related updates
    useEffect(() => {
        if (user.email) {
            initializeUser();
        }
    }, [user, initializeUser]);

    useEffect(() => {
        if (alert?.variant === 'success') {
            const timeout = setTimeout(() => {
                setAlert(null);
            }, 5000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [alert]);

    const staticSchema = yup.object().shape({
        firstName: yup.string().required('First Name is Required'),
        lastName: yup.string().required('Last Name is Required'),
        emailId: yup
            .string()
            .required('Email ID is required')
            .test('email', 'Invalid email format', (value) => {
                const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                const testValue = value + '@' + updatedUser.companyDomain;
                return emailRegex.test(testValue);
            }),
        profile: yup.object().shape({
            alternateEmail: yup.string().email('Invalid Email.')
        })
    });

    const dynamicSchemaObject = {
        profile: yup.object().shape({})
    };

    customProfileFields.forEach((field) => {
        const { displayName, key, required } = field;

        const fieldSchema = required
            ? yup.string().required(`${displayName} is Required`)
            : yup.string();

        dynamicSchemaObject.profile = dynamicSchemaObject.profile.shape({
            ...dynamicSchemaObject.profile.fields,
            [key]: fieldSchema
        });
    });
    const validationSchema = staticSchema.concat(yup.object().shape(dynamicSchemaObject));

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Check if the name attribute includes 'profile.'
        if (name.startsWith('profile.')) {
            const profileKey = name.split('.')[1]; // Extract the profile key
            setUpdatedUser((prevUser) => ({
                ...prevUser,
                profile: {
                    ...prevUser.profile,
                    [profileKey]: value // Update the nested profile key
                }
            }));
        } else {
            // Handle non-profile fields
            setUpdatedUser((prevUser) => ({
                ...prevUser,
                [name]: value
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined // Clear the error for the changed field
        }));
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setUpdatedUser((prevUser) => ({
            ...prevUser,
            roles: checked
                ? [...prevUser.roles, value]
                : prevUser.roles.filter((role) => role !== value)
        }));
    };

    const handleUpdateUserStatus = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(
            updateUserStatus(
                user.guid,
                user.status === 'active' ? 'suspended' : 'active',
                user.companyGuid
            )
        )
            .then(() => {
                setAlert({ variant: 'success', message: 'User status updated successfully.' });

                // dispatch(getUser(user.companyGuid, user.guid)).then(() => {
                //     setLoading(false);
                // });
            })
            .catch((error) => {
                setAlert({ variant: 'danger', message: error.response.data.message });
                console.log(error);
                setLoading(false);
            });
    };

    const handleSubmit = (e) => {
        setErrors({});
        const email = updatedUser.emailId + '@' + updatedUser.companyDomain;
        e.preventDefault();
        const rolesAdded = [];
        const rolesRemoved = [];
        updatedUser.roles.forEach((role) => {
            if (!roles.includes(role)) {
                rolesAdded.push(role);
            }
        });
        roles.forEach((role) => {
            if (!updatedUser.roles.includes(role)) {
                rolesRemoved.push(role);
            }
        });

        validationSchema
            .validate(updatedUser, { abortEarly: false })
            .then(() => {
                setLoading(true);
                dispatch(
                    updateUser(
                        user.guid,
                        updatedUser.firstName,
                        updatedUser.lastName,
                        email,
                        updatedUser.profile,
                        rolesRemoved,
                        rolesAdded,
                        user.companyGuid
                    )
                )
                    .then(() => {
                        setAlert({ variant: 'success', message: 'User updated successfully.' });

                        // dispatch(getUser(user.companyGuid, user.guid)).then(() => {
                        //     setLoading(false);
                        // });
                        setShowEdit(false);
                    })
                    .catch((error) => {
                        setAlert({ variant: 'danger', message: error.response.data.message });
                        console.log(error);
                        setLoading(false);
                    });
            })
            .catch((validationErrors) => {
                // Validation failed, update the errors state
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    const path = error.path; // Get the error path
                    if (path.startsWith('profile.')) {
                        // If the error path starts with 'profile.', it's for the profile object
                        newErrors[path] = error.message;
                    } else {
                        // Otherwise, it's for the top-level fields
                        newErrors[path] = error.message;
                    }
                });
                setErrors(newErrors);
            });
    };

    if (loading) {
        return (
            <Card className="pk-card mt-3 h-100">
                <Card.Body className="d-flex align-items-center justify-content-center">
                    <ScaleLoader />
                </Card.Body>
            </Card>
        );
    }
    return (
        <Card className="pk-card mt-3 h-100">
            <Card.Body className="px-5">
                <Row className="justify-content-between mb-4">
                    <Col className="col-auto">
                        <p className="fs-6 fw-bold card-title">
                            <FaUser /> {showEdit && <>Edit </>} {user.firstName} {user.lastName}
                        </p>
                    </Col>
                    <Col className="col-auto text-center">
                        {alert && <Alert variant={alert.variant}>{alert?.message}</Alert>}
                    </Col>
                    <Col className="col-auto">
                        {user.guid !== currentUser.guid && (
                            <>
                                <Button
                                    variant={
                                        user?.status === 'active'
                                            ? 'outline-danger'
                                            : 'outline-success'
                                    }
                                    onClick={handleUpdateUserStatus}>
                                    {user?.status === 'active' ? 'Suspend' : 'Enable'}{' '}
                                    {user?.status === 'active' ? <FaPause /> : <FaPlay />}
                                </Button>
                            </>
                        )}

                        {!showEdit && (
                            <Button
                                className="ms-4"
                                variant="light"
                                onClick={() => setShowEdit(true)}>
                                Edit <FaEdit />
                            </Button>
                        )}
                    </Col>
                </Row>
                <Row></Row>

                <Row>
                    <Form>
                        <InputGroup>
                            <InputGroup.Text>First and Last Name:</InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={updatedUser.firstName}
                                onChange={handleInputChange}
                                isInvalid={!!errors.firstName}
                                autoComplete="off"
                                data-lpignore="true"
                                disabled={!showEdit}
                            />
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={updatedUser.lastName}
                                onChange={handleInputChange}
                                isInvalid={!!errors.lastName}
                                autoComplete="off"
                                disabled={!showEdit}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.firstName} {errors.lastName}
                            </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-3">
                            <InputGroup.Text>Email:</InputGroup.Text>
                            <FormControl
                                type="emailId"
                                name="emailId"
                                value={updatedUser.emailId}
                                onChange={handleInputChange}
                                isInvalid={!!errors.emailId}
                                autoComplete="off"
                                data-lpignore="true"
                                disabled={!showEdit}
                            />
                            <InputGroup.Text>@{updatedUser.companyDomain}</InputGroup.Text>

                            <Form.Control.Feedback type="invalid">
                                {errors.emailId}
                            </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-3">
                            <InputGroup.Text>Alternate Email:</InputGroup.Text>
                            <FormControl
                                type="email"
                                name="profile.alternateEmail"
                                value={updatedUser.profile.alternateEmail}
                                onChange={handleInputChange}
                                isInvalid={!!errors['profile.alternateEmail']}
                                autoComplete="off"
                                data-lpignore="true"
                                disabled={!showEdit}
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors['profile.alternateEmail']}
                            </Form.Control.Feedback>
                        </InputGroup>

                        {customProfileFields[1] && (
                            <>
                                <hr></hr>
                                {!showEdit && (
                                    <Button
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => setViewMore(!viewMore)}
                                        style={{ cursor: 'pointer' }} // Explicitly set cursor to pointer
                                    >
                                        {viewMore ? (
                                            <>
                                                <FaEyeSlash /> Hide Custom Fields
                                            </>
                                        ) : (
                                            <>
                                                <FaEye /> View Custom Fields
                                            </>
                                        )}
                                    </Button>
                                )}
                                {(viewMore || showEdit) && (
                                    <>
                                        <h6 className="mt-2">Custom Profile Fields</h6>
                                        <CustomForm
                                            profileFields={updatedUser?.profile}
                                            disabled={!showEdit}
                                            changeHandler={handleInputChange}
                                            fieldNames={customProfileFields}
                                            errors={errors || {}}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        <hr></hr>
                        <FormGroup className="bg-light  mt-3 p-3" controlId="roles">
                            <FormLabel>Roles:</FormLabel>
                            <FormCheck
                                type="checkbox"
                                id="adminCheckbox"
                                label="Admin"
                                value="Admin"
                                checked={updatedUser.roles?.includes('Admin')}
                                onChange={handleCheckboxChange}
                                disabled={!showEdit}
                            />
                            <Can I="actAs" a="superAdmin">
                                <FormCheck
                                    type="checkbox"
                                    id="superAdminCheckbox"
                                    label="Super Admin"
                                    value="SuperAdmin"
                                    checked={updatedUser.roles?.includes('SuperAdmin')}
                                    onChange={handleCheckboxChange}
                                    disabled={!showEdit}
                                />
                            </Can>
                        </FormGroup>
                        <Row className="mt-3 justify-content-end">
                            {showEdit && (
                                <Col className=" col-auto">
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            setAlert(null);
                                            initializeUser();
                                            setErrors({});
                                            setShowEdit(false);
                                        }}>
                                        Cancel
                                    </Button>{' '}
                                    <Button variant="primary" onClick={handleSubmit}>
                                        Update <FaSave />
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ViewUserCard;
