import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { createNewAuthenticatorViaEmail, checkBindStatus } from '../../actions/authActions';
import QRComponent from '../../components/User/QRComponent';
import Passkey from '../../components/Cards/Passkey';
import axios from 'axios';
import { supported } from '@github/webauthn-json/browser-ponyfill';

function Bind() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const st = searchParams.get('st');
    const history = useHistory();
    const [bindData, setBindData] = useState();
    const [loading, setLoading] = useState(false);
    const [authenticatorType, setAuthenticatorType] = useState(null);
    const [passkeySupported, setPasskeySupported] = useState(false);
    const [sessionToken, setSessionToken] = useState(st);
    const [createCalled, setCreateCalled] = useState(false);
    const [alert, setAlert] = useState(null);

    const checkStatus = useCallback(
        async (st, location) => {
            const res = await checkBindStatus(`${location}?st=${st}`);
            if (res?.privakeyDeviceGuid) {
                const alertMessage =
                    'Successfully Enabled Privakey. Enter your email to login securely, without a password.';
                window.localStorage?.setItem('lastEmailUsed', bindData.user.email);
                setTimeout(() => {
                    history.push('/', { alertMessage });
                }, 2000);
                return;
            }
            setTimeout(() => {
                checkStatus(st, location);
            }, 1000);
        },
        [history, bindData]
    );

    useEffect(() => {
        if (typeof sessionToken === 'undefined') {
            history.push('/');
        }
        if (authenticatorType && !createCalled) {
            const getCsrfToken = () => {
                axios
                    .get('/', { baseURL: '/api' })
                    .then(() => {
                        console.log('CSRF token fetched');
                        setCreateCalled(true);
                        setAlert(null);
                        createNewAuthenticatorViaEmail(sessionToken, authenticatorType)
                            .then((res) => {
                                console.log('bindData from action', res);
                                if (res) {
                                    setSessionToken(res.st);
                                    setBindData(res);
                                    setLoading(false);
                                    if (authenticatorType === 'pkauth') {
                                        setTimeout(() => {
                                            checkStatus(res.st, res.location);
                                        }, 1000);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                setAlert({
                                    variant: 'danger',
                                    message: 'Token has either expired or already been used'
                                });
                            });
                    })
                    .catch((error) => console.log(error));
            };
            getCsrfToken();
        }
    }, [sessionToken, createCalled, history, authenticatorType, checkStatus]);

    const registrationSuccess = async (res) => {
        const alertMessage =
            'Successfully Enabled Privakey. Enter your email to login securely, without a password.';
        window.localStorage?.setItem('lastEmailUsed', bindData.user.email);
        setTimeout(() => {
            history.push('/', { alertMessage });
        }, 2000);
        return;
    };

    const registrationFailure = async (res) => {
        setAuthenticatorType(null);
        setCreateCalled(false);
        setLoading(false);
        setAlert({
            variant: 'warning',
            message: 'Registration failed/canceled'
        });
    };

    useEffect(() => {
        const isSupported = supported();
        console.log(`Passkey is${!isSupported ? ' not' : ''} supported`);
        setPasskeySupported(isSupported);
    }, []);

    return (
        <Container className="min-vh-100">
            <Row>
                <div className="jumbotron">
                    <h4 className="text-center flex-xl-row">
                        Privakey Passwordless Single Sign On{' '}
                    </h4>
                    <hr className="mb-8" />

                    <Row className="d-flex justify-content-center g-4">
                        <Col className="order-lg-1">
                            <Card
                                className=" h-100 w-100"
                                style={{
                                    backgroundColor: 'white',
                                    minWidth: '350px'
                                }}>
                                <Card.Header>
                                    <h5 className="text-center">Add New Authenticator</h5>
                                </Card.Header>
                                <Card.Body>
                                    {alert && (
                                        <Col className="col-auto text-center">
                                            <Alert variant={alert.variant}>{alert?.message}</Alert>
                                        </Col>
                                    )}
                                    {authenticatorType && !loading ? (
                                        <>
                                            {authenticatorType === 'pkauth' ? (
                                                <>
                                                    {bindData.sessionToken && (
                                                        <QRComponent
                                                            sessionToken={bindData.sessionToken}
                                                            appSpaceGuid={bindData.appSpaceGuid}
                                                            appSpaceName={bindData.appSpaceName}
                                                        />
                                                    )}
                                                    <Button
                                                        variant="warning"
                                                        onClick={() => {
                                                            registrationFailure();
                                                        }}>
                                                        Cancel
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    {bindData.registrationOptions && (
                                                        <Passkey
                                                            descriptionText={`Creating a Passkey authenticator on this device.`}
                                                            descriptionTextComplete={`A Passkey authenticator was successfully created on this device`}
                                                            passkeyConfig={
                                                                bindData.registrationOptions
                                                            }
                                                            token={bindData.st}
                                                            user={bindData.user}
                                                            successCallback={registrationSuccess}
                                                            failureCallback={registrationFailure}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <Row className=" d-flex g-4 justify-content-center">
                                            {passkeySupported ? (
                                                <>
                                                    <Col md={6}>
                                                        <Card className="h-100">
                                                            <div className="d-flex mt-2 justify-content-center">
                                                                <Button
                                                                    className="passkey-btn-solid"
                                                                    disabled={createCalled}
                                                                    onClick={() => {
                                                                        setLoading(true);
                                                                        setAuthenticatorType(
                                                                            'passkey'
                                                                        );
                                                                    }}>
                                                                    Add Passkey
                                                                </Button>
                                                            </div>
                                                            <div className="m-2">
                                                                <small>
                                                                    Passkeys are native platform
                                                                    authenticators. They are great
                                                                    for your daily workstation.
                                                                    You'll use a PIN or Biometric on
                                                                    your device to access the
                                                                    Passkey stored on your computer.
                                                                </small>
                                                            </div>
                                                        </Card>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Card className="h-100">
                                                            <div className="d-flex mt-2 justify-content-center">
                                                                <Button
                                                                    className="privakey-btn-solid"
                                                                    disabled={createCalled}
                                                                    onClick={() => {
                                                                        setLoading(true);
                                                                        setAuthenticatorType(
                                                                            'pkauth'
                                                                        );
                                                                    }}>
                                                                    Add Privakey
                                                                </Button>
                                                            </div>
                                                            <div className="m-2">
                                                                <small>
                                                                    Privakey is a mobile
                                                                    authenticator that uses
                                                                    biometrics or a PIN. You can use
                                                                    it anywhere to access the SSO.
                                                                    It's great for day-to-day use{' '}
                                                                    <strong>AND</strong> you can use
                                                                    on infrequently used computers.
                                                                    It is also perfect for account
                                                                    access recovery. Learn more:{' '}
                                                                    <a
                                                                        href="https://help.privakey.com/usingthesso/theprivakeyApp/"
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="pk-link click-here">
                                                                        The Privakey App
                                                                    </a>
                                                                </small>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </>
                                            ) : (
                                                <Col sm={6}>
                                                    <Card className="h-100">
                                                        <div className="d-flex mt-2 justify-content-center">
                                                            <Button
                                                                className="privakey-btn-solid"
                                                                disabled={createCalled}
                                                                onClick={() => {
                                                                    setLoading(true);
                                                                    setAuthenticatorType('pkauth');
                                                                }}>
                                                                Add Privakey
                                                            </Button>
                                                        </div>
                                                        <div className="m-2">
                                                            <small>
                                                                Privakey is a mobile authenticator
                                                                that uses biometrics or a PIN. You
                                                                can use it anywhere to access the
                                                                SSO. It's great for day-to-day use{' '}
                                                                <strong>AND</strong> you can use on
                                                                infrequently used computers. It is
                                                                also perfect for account access
                                                                recovery. Learn more:{' '}
                                                                <a
                                                                    href="https://help.privakey.com/usingthesso/theprivakeyApp/"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="pk-link click-here">
                                                                    The Privakey App
                                                                </a>
                                                            </small>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Row>
        </Container>
    );
}

export default Bind;
