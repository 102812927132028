import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    serviceProviders: []
};

const systemSlice = createSlice({
    name: 'system',
    initialState: initialState,
    reducers: {
        getAllServiceProviders(state, action) {
            state.serviceProviders = action.payload;
        }
    }
});

export const actions = systemSlice.actions;
export default systemSlice.reducer;
