import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FaHeart, FaLifeRing, FaQuestionCircle } from 'react-icons/fa';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import logo from '../../images/securedByPrivakeyNoKeyhole.png';
import { logoutUser, getRapidRJWT } from '../../actions/authActions';
import ability from '../../ability';

function TopNav() {
    const title = 'Passwordless SSO';
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.currentUser);
    const handleLogout = async () => {
        await dispatch(logoutUser());
        history.push('/');
    };

    const handleFeedback = async () => {
        const jwt = await getRapidRJWT();
        let url = 'https://privakey.rapidr.io';
        if (jwt) {
            url = `https://privakey.rapidr.io/auth/sso?token=${jwt}&redirect=/`;
        }

        window.open(url, '_blank');
    };

    const handleSupport = async () => {
        window.open('/api/auth/zendesk', '_blank');
    };

    const showAdmin = ability.can('actAs', 'admin');
    const showSuperAdmin = ability.can('actAs', 'superAdmin');
    const showAccessManager = user.companyStatus === 'active' && (showAdmin || showSuperAdmin);

    return (
        <Navbar className="pk-navbar" expand="lg" fixed="top">
            <Container
                fluid
                className="pk-navbar-container d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <Navbar.Toggle aria-controls="navbar-nav" className="me-2" />
                    <Navbar.Brand as={Link} to="/" className="ms-0 me-auto me-md-0">
                        {title}
                    </Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <img src={logo} height="40" alt="Secured by Privakey" />
                    </div>
                </div>

                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto">
                        {showAccessManager && (
                            <Nav.Item>
                                <Nav.Link className="text-nowrap" as={Link} to="/accessmanager">
                                    Access Manager
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {showAdmin && (
                            <Nav.Item>
                                <Nav.Link className="text-nowrap" as={Link} to="/admin">
                                    Admin
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {showSuperAdmin && (
                            <Nav.Item>
                                <Nav.Link className="text-nowrap" as={Link} to="/superadmin">
                                    Super Admin
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </Nav>

                    <Nav className="me-4">
                        <Nav.Item className="d-none d-lg-flex">
                            {/* <Nav.Link
                                className="text-nowrap text-primary me-2" // added margin to separate the icons
                                target="_blank"
                                onClick={handleFeedback}>
                                <FaHeart /> Feedback
                            </Nav.Link> */}

                            <Nav.Link
                                className="text-nowrap text-primary"
                                target="_blank"
                                href="https://help.privakey.com/">
                                <FaQuestionCircle /> Help
                            </Nav.Link>
                            <Nav.Link
                                className="text-nowrap text-primary"
                                target="_blank"
                                onClick={handleSupport}
                                href="#">
                                <FaLifeRing /> Support
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="d-lg-none">
                            {/* <Nav.Link
                                className="text-nowrap text-primary me-2" // added margin to separate the icons
                                target="_blank"
                                onClick={handleFeedback}>
                                <FaHeart /> Feedback
                            </Nav.Link> */}

                            <Nav.Link
                                className="text-nowrap text-primary"
                                target="_blank"
                                href="https://help.privakey.com/">
                                <FaQuestionCircle /> Help
                            </Nav.Link>
                            <Nav.Link
                                className="text-nowrap text-primary"
                                target="_blank"
                                onClick={handleSupport}
                                href="#">
                                <FaLifeRing /> Support
                            </Nav.Link>
                        </Nav.Item>
                        <NavDropdown title={user.email} id="userDropdown">
                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNav;
