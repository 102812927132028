import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
    name: 'users',
    initialState: {},
    reducers: {
        select(state, action) {
            state.viewingUser = action.payload;
        },
        getSessions(state, action) {
            if (state.viewingUser) {
                state.viewingUser.sessions = action.payload;
            }
        },
        getValidAuthenticators(state, action) {
            if (state.viewingUser) {
                state.viewingUser.authenticators = action.payload;
            }
        },
        update(state, action) {
            state.viewingUser.firstName = action.payload.firstName;
            state.viewingUser.lastName = action.payload.lastName;
            state.viewingUser.email = action.payload.email;
            state.viewingUser.roles = action.payload.roles;
        },
        clear(state) {
            delete state.viewingUser;
        },
        createNewAuthenticator(state, action) {
            if (!state.viewingUser.authenticators) {
                state.viewingUser.authenticators = [];
            }
            state.viewingUser.authenticators.push(action.payload);
        },
        deleteAuthenticator(state, action) {
            state.viewingUser.authenticators = state.viewingUser.authenticators.filter(
                (authenticator) => authenticator.guid !== action.payload
            );
        }
    }
});
export const actions = usersSlice.actions;
export default usersSlice.reducer;
