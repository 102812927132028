import React, { useEffect, useState } from 'react';
import { getMetaData } from '../../actions/companyActions';
import { useSelector } from 'react-redux';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { FaCheck, FaCopy, FaDownload } from 'react-icons/fa';
import { XMLParser } from 'fast-xml-parser';

function MetaData() {
    const companyGuid = useSelector((state) => state.companies.currentCompany.guid);

    const [xmlContent, setXmlContent] = useState('');
    const [link, setLink] = useState('');
    const [metadata, setMetadata] = useState(null);

    const currentDomain = window.location.origin;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getMetaData(companyGuid);
                setLink(currentDomain + res.url);
                const parser = new XMLParser({
                    ignoreAttributes: false,
                    removeNSPrefix: true,
                    attributeNamePrefix: ''
                });
                if (res.xml) {
                    const xml = parser.parse(res.xml);
                    setMetadata(xml);

                    const xmlWithLineBreaks = res.xml.replace(/></g, '>\n<');
                    setXmlContent(xmlWithLineBreaks);
                }
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [companyGuid, currentDomain]);

    const copyToClipboard = (content) => (evt) => {
        evt?.preventDefault();
        navigator.clipboard.writeText(content);
    };
    const [clickedIconIndex, setClickedIconIndex] = useState('');
    useEffect(() => {
        setTimeout(() => {
            setClickedIconIndex('');
        }, 1000);
    }, [clickedIconIndex]);

    const handleDownload = (filename, data, type) => (evt) => {
        evt.preventDefault();
        const blob = new Blob([data], { type });

        // Generate a URL for the blob
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // Append the link to the document and click it
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    console.log('link', link);
    return (
        <Accordion className="mb-4">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Show Privakey SAML Configuration Data</Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col>
                            When configuring Privakey Passwordless Single Sign-on at the Service
                            Provider, you will need to provide your SAML metadata. You may need to
                            manually enter the data, upload a file, or provide a link.
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {metadata?.EntityDescriptor && (
                                <>
                                    <Row>
                                        <Col lg={12}>
                                            <h6>Privakey SSO SAML Metadata Link</h6>
                                        </Col>
                                        <Col
                                            xs={12}
                                            lg={3}
                                            className="d-flex justify-content-start justify-content-lg-end">
                                            <div className="d-flex align-items-top small">
                                                Metadata URL:
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={7}>
                                            <div className="d-flex align-items-start small">
                                                {link}
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={1}>
                                            <div className="d-flex align-items-center">
                                                <a
                                                    href="/#"
                                                    className={`copy-link ${
                                                        clickedIconIndex === 4
                                                            ? 'clicked'
                                                            : 'hoverable'
                                                    }`}
                                                    onClick={(evt) => {
                                                        copyToClipboard(link)(evt);
                                                        setClickedIconIndex(4);
                                                    }}>
                                                    {clickedIconIndex === 4 ? (
                                                        <FaCheck />
                                                    ) : (
                                                        <FaCopy />
                                                    )}
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={12}>
                                            <h6>Privakey SSO SAML Metadata Elements</h6>
                                        </Col>
                                        <Col
                                            xs={12}
                                            lg={3}
                                            className="d-flex justify-content-start justify-content-lg-end mt-2 mt-lg-0">
                                            <div className="d-flex align-items-top small">
                                                Privakey SSO Entity ID:
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={7}>
                                            <div className="d-flex align-items-start small">
                                                {metadata.EntityDescriptor.entityID}
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={1}>
                                            <div className="d-flex align-items-top">
                                                <a
                                                    href="/#"
                                                    className={`copy-link ${
                                                        clickedIconIndex === 0
                                                            ? 'clicked'
                                                            : 'hoverable'
                                                    }`}
                                                    onClick={(evt) => {
                                                        copyToClipboard(
                                                            metadata.EntityDescriptor.entityID
                                                        )(evt);
                                                        setClickedIconIndex(0);
                                                    }}>
                                                    {clickedIconIndex === 0 ? (
                                                        <FaCheck />
                                                    ) : (
                                                        <FaCopy />
                                                    )}
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            lg={3}
                                            className="d-flex justify-content-start justify-content-lg-end mt-2 mt-lg-0">
                                            <div className="d-flex align-items-top small">
                                                Privakey SSO Login URL:
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={7}>
                                            <div className="d-flex align-items-top small">
                                                {
                                                    metadata.EntityDescriptor.IDPSSODescriptor
                                                        .SingleSignOnService[0].Location
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={1}>
                                            <div className="d-flex align-items-top">
                                                <a
                                                    href="/#"
                                                    className={`copy-link ${
                                                        clickedIconIndex === 1
                                                            ? 'clicked'
                                                            : 'hoverable'
                                                    }`}
                                                    onClick={(evt) => {
                                                        copyToClipboard(
                                                            metadata.EntityDescriptor
                                                                .IDPSSODescriptor
                                                                .SingleSignOnService[0].Location
                                                        )(evt);
                                                        setClickedIconIndex(1);
                                                    }}>
                                                    {clickedIconIndex === 1 ? (
                                                        <FaCheck />
                                                    ) : (
                                                        <FaCopy />
                                                    )}
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            lg={3}
                                            className="d-flex justify-content-start justify-content-lg-end mt-2 mt-lg-0">
                                            <div className="d-flex align-items-top small">
                                                Privakey SSO Logout URL:
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={7}>
                                            <div className="d-flex align-items-top small">
                                                {
                                                    metadata.EntityDescriptor.IDPSSODescriptor
                                                        .SingleLogoutService.Location
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={1}>
                                            <div className="d-flex align-items-top">
                                                <a
                                                    href="/#"
                                                    className={`copy-link ${
                                                        clickedIconIndex === 2
                                                            ? 'clicked'
                                                            : 'hoverable'
                                                    }`}
                                                    onClick={(evt) => {
                                                        copyToClipboard(
                                                            metadata.EntityDescriptor
                                                                .IDPSSODescriptor
                                                                .SingleLogoutService.Location
                                                        )(evt);
                                                        setClickedIconIndex(2);
                                                    }}>
                                                    {clickedIconIndex === 2 ? (
                                                        <FaCheck />
                                                    ) : (
                                                        <FaCopy />
                                                    )}
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={12}>
                                            <h6>Privakey SSO Certificate - With Headers</h6>{' '}
                                        </Col>
                                        <Col md={12}>
                                            <Button
                                                size="sm"
                                                onClick={handleDownload(
                                                    'PrivakeyCertificate.cer',
                                                    '-----BEGIN CERTIFICATE-----\n' +
                                                        metadata.EntityDescriptor.IDPSSODescriptor
                                                            .KeyDescriptor.KeyInfo.X509Data
                                                            .X509Certificate +
                                                        '\n-----END CERTIFICATE-----',
                                                    'text/plain'
                                                )}>
                                                <FaDownload /> Download Certificate
                                            </Button>
                                            {'  '}
                                            <Button
                                                onClick={copyToClipboard(
                                                    '-----BEGIN CERTIFICATE-----\n' +
                                                        metadata.EntityDescriptor.IDPSSODescriptor
                                                            .KeyDescriptor.KeyInfo.X509Data
                                                            .X509Certificate +
                                                        '\n-----END CERTIFICATE-----'
                                                )}
                                                size="sm">
                                                <FaCopy /> Copy Certificate
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={12}>
                                            <h6>Privakey SSO Certificate - No Headers</h6>{' '}
                                        </Col>
                                        <Col md={12}>
                                            <Button
                                                size="sm"
                                                onClick={handleDownload(
                                                    'PrivakeyCertificateNoHeaders.cer',
                                                    metadata.EntityDescriptor.IDPSSODescriptor
                                                        .KeyDescriptor.KeyInfo.X509Data
                                                        .X509Certificate,
                                                    'text/plain'
                                                )}>
                                                <FaDownload /> Download Certificate (NH)
                                            </Button>
                                            {'  '}
                                            <Button
                                                onClick={copyToClipboard(
                                                    metadata.EntityDescriptor.IDPSSODescriptor
                                                        .KeyDescriptor.KeyInfo.X509Data
                                                        .X509Certificate
                                                )}
                                                size="sm">
                                                <FaCopy /> Copy Certificate (NH)
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row className="mt-4">
                                <Col md={12}>
                                    <h6>Privakey SSO SAML Metadata File</h6>{' '}
                                </Col>
                                <Col md={12}>
                                    <Button href={link} size="sm" download="PrivakeySAMLConfig.xml">
                                        <FaDownload /> Download XML
                                    </Button>
                                    {'  '}
                                    <Button onClick={copyToClipboard(xmlContent)} size="sm">
                                        <FaCopy /> Copy XML
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default MetaData;
