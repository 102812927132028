import { Accordion } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Beta = () => {
    return (
        <Accordion className="custom-accordion">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="align-center">
                    <FaExclamationCircle className="larger-icon me-2 warning" /> The Privakey SSO is
                    currently offered as a Beta. The service is a work in progress.
                </Accordion.Header>
                <Accordion.Body>
                    <p>
                        If you have any questions or feedback, you're encouraged to contact{' '}
                        <a href="mailto:support@privakey.com?subject=SSO Support Request">
                            support@privakey.com
                        </a>{' '}
                        or reach out directly to contacts you have at Privakey.
                    </p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default Beta;
