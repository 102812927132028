// SuperAdmin Actions/Reducers

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const CLEAR_LOGIN_REQUEST = 'CLEAR_LOGIN_REQUEST';
export const FETCH_COMPANY_USERS = 'FETCH_COMPANY_USERS';
export const GET_ALL_SERVICE_PROVIDERS = 'GET_ALL_SERVICE_PROVIDERS';
export const GET_COMPANY_SERVICE_PROVIDERS = 'GET_COMPANY_SERVICE_PROVIDERS';
export const CREATE_COMPANY_SERVICE_PROVIDER = 'CREATE_COMPANY_SERVICE_PROVIDER';

// Admin Actions/Reducers
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const GET_USERS = 'GET_USERS';
export const GET_USER_SESSIONS = 'GET_USER_SESSIONS';
export const SELECT_USER = 'SELECT_USER';
export const GET_VALID_USER_AUTHENTICATORS = 'GET_VALID_USER_AUTHENTICATORS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_COMPANY_LOCATIONS = 'GET_COMPANY_LOCATIONS';
export const CREATE_COMPANY_LOCATION = 'CREATE_COMPANY_LOCATION';
export const UPDATE_COMPANY_LOCATION = 'UPDATE_COMPANY_LOCATION';
export const DELETE_COMPANY_LOCATION = 'DELETE_COMPANY_LOCATION';
export const CLEAR_USER = 'CLEAR_USER';
export const DELETE_USER_AUTHENTICATOR = 'DELETE_USER_AUTHENTICATOR';

//Auth & Base Actions/Reucers
export const INITIATE_SAML_REQUEST = 'INITIATE_SAML_REQUEST';
export const INITIATE_LOGIN_REQUEST = 'INITIATE_LOGIN_REQUEST';
export const CHECK_LOGIN_REQUIEST = 'CHECK_LOGIN_REQUIEST';
export const CANCEL_LOGIN_REQUEST = 'CANCEL_LOGIN_REQUEST';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOG_OUT = 'LOG_OUT';
export const SET_DASHBOARD = 'SET_DASHBOARD';

//Authenticator Actions
export const CREATE_NEW_AUTHENTICATOR = 'CREATE_NEW_AUTHENTICATOR';
export const SERVER_SHUTDOWN = 'SERVER_SHUTDOWN';
export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const GET_USER_AUTHENTICATORS = 'GET_USER_AUTHENTICATORS';
