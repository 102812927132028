import PkCard from '../Cards/PKCard';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Row, Col, Button } from 'react-bootstrap';
import { FaEye, FaPlus, FaUserPlus, FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const UsersComponent = () => {
    const currentCompany = useSelector((state) => state.companies.currentCompany);
    const users = currentCompany.userCount;
    const seats = currentCompany.seats;

    const location = useLocation();
    const currentPath = location.pathname;
    const state = { returnPath: currentPath };
    const paymentOnFile = currentCompany?.paymentInfo?.stripeInfo?.hasPaymentMethod ? true : false;
    const canAddUser = users < 6 || paymentOnFile;

    return (
        <PkCard
            title={
                <>
                    <FaUsers /> Users{' '}
                </>
            }
            body={
                <div>
                    <Row className="row-cols-1 row-cols-md-3">
                        <Row className="row-cols-1 g-2">
                            <Col>
                                <p>
                                    Number of Users: <strong>{users}</strong>{' '}
                                </p>
                            </Col>
                        </Row>
                        <Row className="row-cols-1 g-2">
                            <Col>
                                <p>
                                    Number of Seats: <strong>{seats}</strong>
                                </p>
                            </Col>
                        </Row>
                        <Row className="row-cols-1 g-2">
                            <Col>
                                <p>
                                    Seats Remaining:{' '}
                                    {seats - users < 1 ? (
                                        <strong> -</strong>
                                    ) : (
                                        <strong>{seats - users}</strong>
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Row>
                </div>
            }
            footer={
                <>
                    <div className="d-flex justify-content-start">
                        <div className="ms-4">
                            <Link
                                to={{
                                    pathname: '/admin/users',
                                    state: state
                                }}>
                                <Button variant="primary" size="sm">
                                    <FaEye /> Manage Users
                                </Button>
                            </Link>
                        </div>
                        <div className="ms-4">
                            <Link
                                to={{
                                    pathname: `/admin/users/new`,
                                    state: state
                                }}
                                onClick={(e) => !canAddUser && e.preventDefault()}>
                                <>
                                    <Button variant="success" size="sm" disabled={!canAddUser}>
                                        {' '}
                                        <FaUserPlus /> Add A User
                                    </Button>
                                </>
                            </Link>
                        </div>
                        <div className="ms-4 me-4">
                            <Link
                                to={{
                                    pathname: '/admin/users/bulk',
                                    state: state
                                }}
                                onClick={(e) => !paymentOnFile && e.preventDefault()}>
                                <>
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        disabled={!paymentOnFile}>
                                        {' '}
                                        <FaUsers />
                                        <FaPlus size="6px" /> Bulk Add Users
                                    </Button>
                                </>
                            </Link>
                        </div>
                    </div>
                    {(!paymentOnFile || !canAddUser) && (
                        <div className="ms-4 pt-2">
                            <p className="p-1 border border-danger">
                                <strong className="text-danger">Note:</strong>{' '}
                                {!paymentOnFile && 'Bulk Add Users'}{' '}
                                {!paymentOnFile && !canAddUser && 'and'}{' '}
                                {!canAddUser && 'Add a User'} functionality is disabled until a
                                Payment Method is entered.{' '}
                                <Link to="/admin#payment">Enter Payment Info</Link>
                            </p>
                        </div>
                    )}
                </>
            }
        />
    );
};

export default UsersComponent;
