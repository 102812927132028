import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ScaleLoader } from 'react-spinners';
import Card from 'react-bootstrap/Card';

import './LoadingCard.css';

export default function LoadingCard(props) {
    return (
        <Card className={props.className} border={props.border}>
            <LoadingOverlay
                active={props.isLoading}
                spinner={<ScaleLoader color={props.spinnerColor || 'orange'} />}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: '#383838'
                    })
                }}>
                {props.children}
            </LoadingOverlay>
        </Card>
    );
}
