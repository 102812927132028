import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PkCard from '../Cards/PKCard';
import { FaCopy, FaCheck, FaInfoCircle } from 'react-icons/fa';

import { Button, Col, Container, Row, Alert, Table } from 'react-bootstrap';
import { validateDomain, getCompany } from '../../actions/companyActions';
import { ScaleLoader } from 'react-spinners';
function DomainVerficationComponent() {
    const dispatch = useDispatch();
    const currentCompany = useSelector((state) => state.companies.currentCompany);
    const [isChecking, setIsChecking] = useState(false);
    const [statusTimer, setStatusTimer] = useState(null);

    const [showHelpContent, setShowHelpContent] = useState(false);
    const helpContent = (
        <>
            <PkCard
                title={<div className="col text-left">Domain Ownership Verification Help</div>}
                link={<span onClick={() => handleHelpClose()}>X</span>}>
                <p>
                    {' '}
                    Before the SSO can be configured, a Company Administrator must add a DNS TXT
                    record to verify possession of the Domain. This is a basic protection to ensure
                    only an appropriate party can configure SSO for a domain.
                </p>
                {/* <p>
                    {' '}
                    For more information, visit{' '}
                    <a
                        target="blank"
                        href="https://support.privakey.com/usingthesso/3accessManager">
                        Privakey SSO Support - Access Manager
                    </a>
                </p> */}
            </PkCard>
        </>
    );

    const [clickedIconIndex, setClickedIconIndex] = useState();
    const copyToClipboard = (content) => (evt) => {
        evt?.preventDefault();
        navigator.clipboard.writeText(content);
    };

    useEffect(() => {
        setTimeout(() => setClickedIconIndex(null), 1000);
    }, [clickedIconIndex]);
    const handleHelpClose = () => {
        setShowHelpContent(false);
    };

    const doValidateDomain = useCallback(async () => {
        setIsChecking(true);
        const isVerified = await validateDomain(currentCompany.guid);
        if (!isVerified) {
            setStatusTimer(setTimeout(() => doValidateDomain(), 30000));
        } else {
            dispatch(getCompany(currentCompany.guid));
        }
    }, [currentCompany.guid, dispatch]);

    useEffect(() => {
        if (!isChecking) {
            if (
                currentCompany.status !== 'active' &&
                currentCompany.domainVerificationInitiatedTime
            ) {
                doValidateDomain();
            }
        }
    }, [isChecking, currentCompany, doValidateDomain]);

    useEffect(() => {
        return () => {
            clearTimeout(statusTimer);
        };
    }, [statusTimer]);

    return (
        <>
            {showHelpContent ? (
                helpContent
            ) : (
                <PkCard
                    bg="warning"
                    className="h-100"
                    link={
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowHelpContent(true)}>
                            <FaInfoCircle />
                        </span>
                    }
                    footer={
                        <Container>
                            <Row>
                                <Col className="d-flex align-items-center">
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={doValidateDomain}
                                        disabled={isChecking}
                                        className="me-2">
                                        Validate Domain Ownership
                                    </Button>{' '}
                                    {isChecking && (
                                        <>
                                            <ScaleLoader />{' '}
                                            <h4 className="ms-2">Verifying domain ownership</h4>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    }>
                    <div className="ml-10">
                        <Alert variant="dark">
                            <h3>Action Required: Verify Domain Ownership</h3>
                            <p>
                                You must verify demonstrate control of your domain,{' '}
                                <strong>{currentCompany.domain}</strong>, before continuing.
                            </p>
                            <p>
                                Go to your domain's registrar or DNS hosting provider, go to DNS
                                management page for <code>{currentCompany.domain}</code>, and add a{' '}
                                <code>text</code> record that uses the value provided below.
                            </p>

                            <p>
                                After adding the text entry, click the Validate Domain Ownership
                                button below.{' '}
                            </p>
                            <p>
                                DNS changes can take some time to propagate. It could take a few
                                minutes or a few hours. When you click Validate Domain Ownership we
                                will check periodically.
                            </p>
                        </Alert>

                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>TXT Name</th>
                                        <th>TXT Value</th>
                                        <th>TTL</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>@ or blank</td>
                                        <td>
                                            <small>
                                                {currentCompany.guid
                                                    ? currentCompany.domainTXTRecord
                                                    : ''}
                                            </small>{' '}
                                            <a
                                                href="/#"
                                                className={`copy-link ${
                                                    clickedIconIndex === 1 ? 'clicked' : 'hoverable'
                                                }`}
                                                onClick={(evt) => {
                                                    copyToClipboard(currentCompany.domainTXTRecord)(
                                                        evt
                                                    );
                                                    setClickedIconIndex(1);
                                                }}>
                                                {clickedIconIndex === 1 ? <FaCheck /> : <FaCopy />}
                                            </a>
                                        </td>
                                        <td>
                                            <code>3600</code> (or your provider default)
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </PkCard>
            )}
        </>
    );
}

export default DomainVerficationComponent;
