import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editCompanyUserProfileInfo } from '../../../actions/companyActions';

import ability from '../../../ability';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import TopNav from '../../../components/Navigation/TopNav';
import { FaUser } from 'react-icons/fa';

const UserSettings = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const state = location.state;
    console.log('Location State, ', state);
    const company = useSelector((state) => state.companies.currentCompany);
    const [alternateEmailInfo, setAlternateEmailInfo] = useState(null);

    console.log('Company, ', company);
    const authorized = ability.can('actAs', 'superAdmin') || ability.can('actAs', 'admin');

    const [profileFields, setProfileFields] = useState([]);
    useEffect(() => {
        const filteredProfileFields = company.userProfileInfo.filter(
            (item) => item.key !== 'alternateEmail'
        );
        setProfileFields(filteredProfileFields);
        setAlternateEmailInfo(
            company.userProfileInfo.find((item) => item.key === 'alternateEmail')
        );
    }, [company.userProfileInfo]);

    const [newField, setNewField] = useState({});
    const [error, setError] = useState('');
    const [alert, setAlert] = useState({ message: '', variant: '' });
    const [isLoading, setIsLoading] = useState(false);

    const requiredFields = [
        'First Name',
        'firstName',
        'Last Name',
        'lastName',
        'Company Email Address',
        'email',
        'Alternate Email Address',
        'alternateEmail'
    ];

    const handleAddButtonClick = () => {
        if (
            profileFields.some((obj) => obj.displayName === newField.displayName) ||
            profileFields.some((obj) => obj.key === newField.key) ||
            requiredFields.includes(newField.key) ||
            requiredFields.includes(newField.displayName)
        ) {
            setError('Profile Field already exists.');
        } else {
            setProfileFields([...profileFields, newField]);
            setNewField({ displayName: '', key: '', required: false });
        }
    };

    const handleDisplayNameChange = (e) => {
        e.preventDefault();
        error && setError('');
        let key = newField.key;

        let string = e.target.value.trim();
        let words = string.split(/[\s_]+/);
        for (let i = 0; i < words.length; i++) {
            if (i === 0) {
                words[i] = words[i].toLowerCase();
            } else {
                words[i] = words[i][0].toUpperCase() + words[i].slice(1).toLowerCase();
            }
        }
        key = words.join('');

        const updatedObject = { ...newField, displayName: e.target.value, key: key };
        setNewField(updatedObject);
    };

    const handleKeyChange = (e) => {
        e.preventDefault();
        error && setError('');
        const input = e.target.value;
        const key = input.replace(/\s+/g, '');
        console.log(key);
        const updatedObject = { ...newField, key: key };
        setNewField(updatedObject);
    };

    const handleRequiredClick = (e) => {
        setNewField({ ...newField, required: !newField.required });
    };

    const handleRequiredChange = (key) => {
        setProfileFields((existingItems) =>
            existingItems.map((item) =>
                item.key === key ? { ...item, required: !item.required } : item
            )
        );
    };

    const handleSave = () => {
        setIsLoading(true);
        const profileFieldsToSave = profileFields.map((item) => item);
        profileFieldsToSave.unshift(alternateEmailInfo);
        dispatch(editCompanyUserProfileInfo(company.guid, profileFieldsToSave))
            .then(() => {
                setAlert({ message: 'Successfully added user profile fields', variant: 'success' });
            })
            .catch((error) => {
                setAlert({ message: 'Ooops', variant: 'Danger' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const FieldEntry2 = ({ label, keyValue, required, index }) => {
        const isStriped = index % 2 !== 1;
        return (
            <Row className={isStriped ? 'striped-row' : ''}>
                <Col>{label}</Col>
                <Col>{keyValue}</Col>
                <Col md={2} className="d-flex justify-content-center">
                    <Form.Check
                        checked={required}
                        onChange={() => handleRequiredChange(keyValue)}
                        aria-label="Checkbox"
                    />
                </Col>
            </Row>
        );
    };
    return (
        <>
            {authorized && (
                <>
                    <TopNav />
                    <Container className="main-container">
                        <h4 className="mt-5 mb-5">
                            <FaUser /> Define User Profile Fields
                        </h4>
                        <Alert variant="info">
                            By default User Profiles include the following fields: First Name, Last
                            Name, Email, and Alternate Email. You can define and include any other
                            fields here.
                        </Alert>
                        <Row className="mt-4">
                            <div className="border border-danger">
                                <h5 className="mt-4">
                                    <strong>Predefined Fields</strong>
                                </h5>
                                <p className="mb-4">
                                    These Profile Fields are defined by the Privakey SSO and cannot
                                    be altered. To add additional fields scroll down to{' '}
                                    <strong>Custom Fields</strong>
                                </p>
                                <div className="ms-4">
                                    <Row>
                                        <Form.Group
                                            as={Col}
                                            className="mb-3"
                                            controlId="formBasicEmail">
                                            <Form.Label size="sm">First Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                disabled
                                                type="string"
                                                placeholder="First Name"
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            className="mb-3"
                                            controlId="formBasicEmail">
                                            <Form.Label size="sm">Last Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                required
                                                disabled
                                                type="string"
                                                placeholder="Last Name"
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label size="sm">Company Email Address</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            disabled
                                            type="email"
                                            placeholder="Enter Company Email"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label size="sm">Alternate Email Address</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            disabled
                                            type="email"
                                            placeholder="Enter email"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <Row className="mt-4">
                                <Row className="mb-2">
                                    <Col xs="4">
                                        <h5>
                                            <strong>Custom Fields</strong>
                                        </h5>
                                    </Col>
                                    <Col xs="5">
                                        {alert.message && (
                                            <Alert variant={alert.variant}>{alert.message}</Alert>
                                        )}
                                    </Col>
                                    <Col className="text-end" xs="3">
                                        <Button
                                            size="sm"
                                            onClick={handleSave}
                                            disabled={isLoading}
                                            variant="success">
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row className="fw-bold">
                                    <Col>Add a Custom Field</Col>
                                </Row>

                                <Form className=" mt-2 mb-2">
                                    <Row className="align-items-center">
                                        <Col md={4}>
                                            <Form.Control
                                                aria-label="Profile Field"
                                                aria-describedby="basic-addon2"
                                                value={newField.displayName}
                                                onChange={handleDisplayNameChange}
                                                placeholder="Display Name"
                                            />
                                        </Col>

                                        <Col md={3}>
                                            <Form.Control
                                                aria-label="Profile Field"
                                                aria-describedby="basic-addon2"
                                                value={newField.key}
                                                onChange={handleKeyChange}
                                                placeholder="Key"
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Form.Check
                                                value={newField.required}
                                                label="Required?"
                                                onChange={handleRequiredClick}
                                                aria-label="Checkbox"
                                            />
                                        </Col>
                                        <Col md={1}>
                                            <Button
                                                size="sm"
                                                variant="success"
                                                onClick={handleAddButtonClick}>
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2" style={{ minHeight: '40px' }}>
                                        <p className="text-danger">{error}</p>
                                    </Row>
                                </Form>

                                <Row>
                                    <Row className="fw-bold">
                                        <Col>Display Name</Col>
                                        <Col>Key</Col>
                                        <Col md={2} className="d-flex justify-content-center">
                                            Required?
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    {profileFields &&
                                        profileFields.map((item, index) => (
                                            <FieldEntry2
                                                key={item.key}
                                                label={item.displayName}
                                                keyValue={item.key}
                                                required={item.required}
                                                index={index}
                                            />
                                        ))}
                                </Row>
                            </Row>
                        </Row>
                    </Container>
                </>
            )}
        </>
    );
};

export default UserSettings;
