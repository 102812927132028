import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const PkCard = (props) => {
    return (
        <Card id={props.id} className="pk-card mt-3 h-100">
            <Card.Body className="px-5">
                <Row className="justify-content-between mb-4">
                    <Col className="col-auto">
                        <p className="fs-6 fw-bold card-title">{props.title}</p>
                    </Col>

                    <Col className=" col-auto text-right">
                        {props.link ?? <div className="fs-6 fw-light">{props.link}</div>}
                    </Col>
                </Row>

                <Row>
                    {props.body}
                    {props.children}
                </Row>
            </Card.Body>
            {props.footer && <Card.Footer className="px-5">{props.footer}</Card.Footer>}
        </Card>
    );
};

export default PkCard;
