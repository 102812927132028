import axios from 'axios';
import ability from '../ability';
import { LOG_OUT } from './types';
import { getLoggedInUser } from '../reducers';
import { actions as authActions } from '../reducers/authReducer';
import {
    supported,
    parseRequestOptionsFromJSON,
    get
} from '@github/webauthn-json/browser-ponyfill';

const map = (action, subject) => {
    return { subject, action };
};

const mapping = {
    0: map('actAs', 'superAdmin'),
    1: map('actAs', 'admin'),
    3: map('actAs', 'user')
};

const setupCaslPermissions = (permissionList) => {
    let rules = [];

    let companyGuids = Object.keys(permissionList);
    companyGuids.forEach((companyGuid) => {
        let permissions = permissionList[companyGuid].permissions;
        permissions.forEach((permissionId) => {
            rules.push({
                ...mapping[permissionId]
            });
        });
    });

    ability.update(rules);
};

const getStatusValidator = (expectedStatuses) => {
    if (!Array.isArray(expectedStatuses)) {
        expectedStatuses = [expectedStatuses];
    }
    let validateStatus = (status) => {
        let valid = false;
        for (let expectedStatus of expectedStatuses) {
            valid ||= status === expectedStatus;
        }
        if (!valid) {
            console.warn(`Bad response status: ${status}`);
        }
        return valid;
    };
    return validateStatus;
};

export const getLoginOptions = (email) => async (dispatch) => {
    try {
        const res = await axios.get(`/auth/loginOptions?email=${encodeURIComponent(email)}`, {
            validateStatus: getStatusValidator([200, 401])
        });
        if (res.status === 200) {
            dispatch(authActions.setLoginOptions({ ...res.data, email }));
            return res.data;
        }
    } catch (error) {
        console.error('Error occurred during getLoginOptions:', error);
    }
    dispatch(authActions.clearLoginOptions());
    return null;
};

export const startLogin = (email, loginType, requestId, resource) => async (dispatch) => {
    try {
        const res = await axios.post('/auth/login', { email, loginType, requestId, resource });
        if (res.status === 200) {
            dispatch(authActions.initiateLoginRequest({ ...res.data, email }));
        }
    } catch (error) {
        console.error('Error occurred during login:', error);
        throw error; // Rethrow the error to be caught in the component
    }
};

export const cancelLoginRequest = async (requestGuid) => {
    try {
        const res = await axios.patch('/auth/cancelRequest', { requestGuid });
        return res.status === 204;
    } catch (err) {
        console.error('Error occurred canceling a login request', err);
        throw err;
    }
};

export const checkLoginRequest = async (requestGuid) => {
    return await axios.get(`/auth/checkRequest?requestGuid=${requestGuid}`).then((res) => {
        return res.data;
    });
};

const completeLogIn = (res, dispatch) => {
    setupCaslPermissions(res.data.permissionList);

    let userData = { ...res.data };
    dispatch(authActions.setCurrentUser(userData));
    dispatch(authActions.clearLoginRequest());
};

export const refreshUser = (validateFunc) => async (dispatch) => {
    let options = {};
    if (validateFunc) {
        options.validateStatus = validateFunc;
    }
    const res = await axios.get('/auth/refreshLogin', options);
    if (res.status === 200) {
        completeLogIn(res, dispatch);
    }

    return res;
};

export const logoutUser =
    (shouldTellServer = true) =>
    async (dispatch) => {
        let shouldDispatch = true;
        if (shouldTellServer) {
            const res = await axios.post('/auth/logout', {});
            if (res.status !== 204) {
                shouldDispatch = false;
            }
        }

        if (shouldDispatch) {
            dispatch({ type: LOG_OUT });
        }
    };

export const sessionExpired = (value) => (dispatch) => {
    dispatch(authActions.setSessionExpired(value));
};

export const getCurrentUser = () => (_dispatch, getState) => {
    return getLoggedInUser(getState());
};

export const createNewAuthenticator = async (userGuid, companyGuid, type = 'pkauth') => {
    const postData = { name: 'Privakey Authenticator', type };
    try {
        const res = await axios.post(
            `/companies/${companyGuid}/users/${userGuid}/authenticators/new`,
            postData
        );
        const location = res.headers.location;
        const actionPayload = { ...res.data, location: location };

        return actionPayload;
    } catch (error) {
        console.error('Error creating authenticator:', error);
        throw error;
    }
};

export const registerPasskey = async (userGuid, companyGuid, body) => {
    try {
        const res = await axios.post(
            `/companies/${companyGuid}/users/${userGuid}/authenticators/register`,
            body
        );
        const location = res.headers.location;
        const actionPayload = { ...res.data, location: location };

        return actionPayload;
    } catch (error) {
        console.error('Error creating authenticator:', error);
        throw error;
    }
};

export const validatePasskey = async (requestGuid, assertionOptions) => {
    const options = parseRequestOptionsFromJSON({
        publicKey: assertionOptions
    });
    const respObj = {};
    try {
        const response = await get(options);
        let postBody = {
            credential: response,
            requestGuid
        };
        let config = {
            method: 'post',
            url: '/auth/validatePasskey',
            data: postBody
        };

        const res = await axios(config);
        if (res.status === 200 && res.data.status === 'approved') {
            respObj.result = true;
            respObj.authenticatorGuid = res.data.authenticatorGuid;
            respObj.companyGuid = res.data.companyGuid;
        } else {
            respObj.result = false;
        }
    } catch (err) {
        respObj.error = err;
    }
    return respObj;
};

export const createNewAuthenticatorViaEmail = async (st, type = 'pkauth') => {
    const postData = { st: st, name: 'Privakey Authenticator', type };
    try {
        const res = await axios.post('authenticators/new', postData);
        const location = res.headers.location;
        return { ...res.data, location: location };
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
        throw error;
    }
};

export const checkBindStatus = async (location) => {
    const options = {
        method: 'get',
        url: location,
        baseURL: ''
    };
    try {
        const res = await axios(options);
        return res.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            console.log('Response Data:', error.response.data);
            console.log('Response Status:', error.response.status);
            console.log('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }
        console.log('Error Config:', error.config);
    }
};

export const clearLoginRequest = () => (dispatch) => {
    dispatch({ type: LOG_OUT });
};

export const getRapidRJWT = async () => {
    const res = await axios.get('/auth/rapidrJWT');
    if (res.status === 200) {
        return res.data;
    }
};

export const passkeySupported = () => {
    return supported();
};
