import React, { useEffect, useState } from 'react';
import TopNav from '../../components/Navigation/TopNav';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import CompanyServiceProviders from '../../components/Company/CompanyServiceProviders';
import UserAuthenticators from '../../components/User/UserAuthenticators';
import UserSessions from '../../components/User/UserSessions';
import { getUser } from '../../actions/userActions';
import Beta from '../../components/Beta';
import { useHistory } from 'react-router-dom';

function AccessManager() {
    const history = useHistory();
    const user = useSelector((state) => state.user.currentUser);
    const viewingUser = useSelector((state) => state.users.viewingUser);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = 'Access Manager'; // Set the document title
    }, []);

    useEffect(() => {
        dispatch(getUser(user.companyGuid, user.guid));
        if (user.companyStatus !== 'active') {
            history.push('/admin');
        }
    }, [user, history, dispatch]);

    useEffect(() => {
        if (user.guid === viewingUser?.guid) {
            setLoading(false);
        }
    }, [user, viewingUser]);

    return (
        <div>
            <TopNav />
            <Container className="main-container">
                <Beta />
                <h4 className="mt-4 mb-3">Access Manager for {user?.companyName} SSO </h4>
                {!loading && user?.companyStatus === 'active' && (
                    <>
                        <CompanyServiceProviders />
                        <UserAuthenticators context={'accessmanager'} />
                        <UserSessions />
                    </>
                )}
            </Container>
        </div>
    );
}

export default AccessManager;
