import React, { useState, useEffect, useMemo } from 'react';
import TopNav from '../../components/Navigation/TopNav';
import { Container, Row, Col, Alert, Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import ServiceProvidersComponent from '../../components/Company/ServiceProvidersComponent';
import UsersComponent from '../../components/Company/UsersComponent';
import LocationsComponent from '../../components/Company/LocationsComponent';
// import AuthenticatorsComponent from '../../components/Company/AuthenticatorsComponent';

import ability from '../../ability';

import {
    getCompany,
    getCompanyServiceProviders,
    getCompanyLocations,
    getPaymentSecret,
    savePaymentInfo,
    deletePaymentInfo
} from '../../actions/companyActions';
import { getAllSystemServiceProviders } from '../../actions/systemActions';
import { refreshUser } from '../../actions/authActions';
import Beta from '../../components/Beta';
import { Redirect } from 'react-router-dom';
import DomainVerficationComponent from '../../components/Company/DomainVerificationComponent';
import {
    AddressElement,
    Elements,
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PkCard from '../../components/Cards/PKCard';
import { FaCreditCard } from 'react-icons/fa';
import PropTypes from 'prop-types';

const PaymentInfo = ({ setPaymentSecret, allowCancel, setShouldShowPaymentInfo }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const companyGuid = useSelector((state) => state.user.currentUser.companyGuid);
    const userGuid = useSelector((state) => state.user.currentUser.guid);
    const [submitted, setSubmitted] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setSubmitted(true);
        setAlertMessage(null);
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const result = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: 'https://sso.privakey.com/admin'
            },
            redirect: 'if_required'
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            setSubmitted(false);
            console.log(result.error.message);
            setAlertMessage(result.error.message);
        } else {
            setPaymentSecret(null);
            setShouldShowPaymentInfo(false);
            dispatch(savePaymentInfo(companyGuid, userGuid, result.setupIntent));
        }
    };

    const handleCancel = async (evt) => {
        evt.preventDefault();
        setShouldShowPaymentInfo(false);
    };

    const handleDelete = async (evt) => {
        evt.preventDefault();
        setShowDelete(true);
    };

    const handleCancelDelete = async (evt) => {
        evt.preventDefault();
        setShowDelete(false);
    };

    const handleConfirmDelete = async (evt) => {
        evt.preventDefault();
        setSubmitted(true);
        dispatch(deletePaymentInfo(companyGuid, userGuid)).then(() => {
            setShouldShowPaymentInfo(false);
            setSubmitted(false);
        });
    };

    return (
        <PkCard
            title={
                <>
                    <FaCreditCard /> Payment Information
                </>
            }>
            <>
                {alertMessage && (
                    <Row className="row-cols-1 justify-content-center g-4">
                        <Col>
                            <Alert variant="danger">{alertMessage}</Alert>
                        </Col>
                    </Row>
                )}
                <div className="ms-3">
                    <p>
                        While the Privakey SSO is free during the Beta period we require companies
                        provide a Payment method to:
                    </p>
                    <ul>
                        <li>Create more than 5 users.</li>
                        <li>Use Bulk User Upload.</li>
                    </ul>

                    <p>
                        <strong>
                            Note: You will not be billed until June 1, 2024 at the earliest and will
                            be notified at least 2 months before billing begins.
                        </strong>
                    </p>
                </div>
                <form className="mt-2 ms-4 bg-light" onSubmit={handleSubmit}>
                    <AddressElement id="address" options={{ mode: 'billing' }} />
                    <PaymentElement id="payment" />
                    <Button
                        className="mt-2"
                        size="sm"
                        disabled={!stripe || submitted}
                        type="submit">
                        Submit
                    </Button>
                    {allowCancel && (
                        <>
                            {' '}
                            <Button
                                className="mt-2"
                                variant="outline-danger"
                                size="sm"
                                onClick={handleCancel}
                                disabled={submitted}>
                                Cancel
                            </Button>{' '}
                            <Button
                                className="mt-2 ms-4"
                                variant="danger"
                                size="sm"
                                onClick={handleDelete}
                                disabled={submitted}>
                                Delete
                            </Button>
                            <Modal centered show={showDelete} onHide={handleCancelDelete}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirm Deletion</Modal.Title>{' '}
                                </Modal.Header>

                                <Modal.Body>
                                    Are you sure you want to delete your billing information ? If
                                    you click Delete you may lose functionality.
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={handleCancelDelete}
                                        disabled={submitted}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={handleConfirmDelete}
                                        disabled={submitted}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                </form>
            </>
        </PkCard>
    );
};
PaymentInfo.propTypes = {
    setPaymentSecret: PropTypes.func,
    allowCancel: PropTypes.bool,
    setShouldShowPaymentInfo: PropTypes.func
};

const StripeElements = () => {
    const currentCompany = useSelector((state) => state.companies.currentCompany);
    const userCompanyGuid = useSelector((state) => state.user.currentUser.companyGuid);
    const [stripePublicKey, setStripePublicKey] = useState(null);
    const [paymentSecret, setPaymentSecret] = useState(null);
    const [shouldFetchSecret, setShouldFetchSecret] = useState(false);
    const [fetchedSecret, setFetchedSecret] = useState(false);
    const [shouldShowPaymentInfo, setShouldShowPaymentInfo] = useState(false);
    const stripePromise = useMemo(() => {
        if (stripePublicKey) {
            return loadStripe(stripePublicKey);
        }
    }, [stripePublicKey]);

    useEffect(() => {
        if (currentCompany?.guid === userCompanyGuid) {
            setShouldFetchSecret(!currentCompany.paymentInfo?.stripeInfo?.hasPaymentMethod);
            setShouldShowPaymentInfo(!currentCompany.paymentInfo?.stripeInfo?.hasPaymentMethod);
        }
    }, [currentCompany, userCompanyGuid]);

    useEffect(() => {
        if (
            currentCompany?.guid === userCompanyGuid &&
            currentCompany?.status === 'active' &&
            shouldFetchSecret &&
            !paymentSecret &&
            !fetchedSecret
        ) {
            const fetchSecret = async () => {
                const res = await getPaymentSecret(currentCompany.guid);
                setPaymentSecret(res.secret);
                setStripePublicKey(res.public);
            };
            setFetchedSecret(true);
            fetchSecret();
        }
    }, [currentCompany, shouldFetchSecret, paymentSecret, fetchedSecret, userCompanyGuid]);

    const handleEdit = async (evt) => {
        evt.preventDefault();
        setFetchedSecret(false);
        setShouldFetchSecret(true);
        setShouldShowPaymentInfo(true);
    };
    return currentCompany.paymentInfo?.stripeInfo?.hasPaymentMethod &&
        !(shouldShowPaymentInfo && stripePromise && paymentSecret) ? (
        <PkCard
            title={
                <>
                    <FaCreditCard /> Payment Information
                </>
            }>
            <p className="text-success">
                <strong> Payment Method on File</strong>
            </p>
            <p>
                You have already entered payment information and now have full access to all of the
                capabilities of the Privakey SSO Beta.
            </p>

            <p>
                <a href="/admin" onClick={handleEdit}>
                    Click here
                </a>{' '}
                If you need to update or remove your payment information.
            </p>
        </PkCard>
    ) : (
        stripePromise && paymentSecret && shouldShowPaymentInfo && (
            <Elements stripe={stripePromise} options={{ clientSecret: paymentSecret }}>
                <PaymentInfo
                    setPaymentSecret={setPaymentSecret}
                    allowCancel={currentCompany.paymentInfo?.stripeInfo?.hasPaymentMethod && true}
                    setShouldShowPaymentInfo={setShouldShowPaymentInfo}
                />
            </Elements>
        )
    );
};
StripeElements.propTypes = {
    paymentSecret: PropTypes.string,
    setPaymentSecret: PropTypes.func,
    stripePublicKey: PropTypes.string
};

function AdminDashboard() {
    const dispatch = useDispatch();

    const companyGuid = useSelector((state) => state.user.currentUser.companyGuid);
    const currentCompany = useSelector((state) => state.companies.currentCompany);
    const [wasPending, setWasPending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertVisible, setAlertVisible] = useState(true);

    useEffect(() => {
        document.title = `Admin - ${currentCompany.name}`; // Set the document title
    }, [currentCompany]);

    useEffect(() => {
        setLoading(true);
        dispatch(getCompany(companyGuid));
    }, [dispatch, companyGuid]);

    useEffect(() => {
        if (currentCompany?.status === 'active' && loading) {
            dispatch(getCompanyServiceProviders(companyGuid));
            dispatch(getAllSystemServiceProviders());
            dispatch(getCompanyLocations(companyGuid));
            setLoading(false);
        } else if (currentCompany?.guid === companyGuid) {
            if (currentCompany?.status === 'pending') {
                setWasPending(true);
            }
            setLoading(false);
        }
    }, [dispatch, loading, companyGuid, currentCompany]);

    useEffect(() => {
        if (wasPending && currentCompany?.status === 'active') {
            const validateStatus = (status) => {
                return status < 400 || status === 404 || status === 401 || status === 500;
            };
            dispatch(refreshUser(validateStatus));
            setWasPending(false);
            setAlertMessage('Domain verified successfully!');
            setAlertVisible(true);
        }
    }, [wasPending, currentCompany, dispatch]);

    const authorized = ability.can('actAs', 'superAdmin') || ability.can('actAs', 'admin');

    if (!authorized) {
        return <Redirect to={{ pathname: '/unauthorized', state: { type: 401 } }} />;
    }

    return (
        <>
            {authorized && !loading && (
                <>
                    <TopNav />
                    <Container className="main-container">
                        <Beta />
                        <h4 className="mt-4 mb-3">Admin Console for {currentCompany.name}</h4>
                        {alertVisible && alertMessage && (
                            <Row className="row-cols-1 justify-content-center g-4">
                                <Col>
                                    <Alert variant="success">{alertMessage}</Alert>
                                </Col>
                            </Row>
                        )}
                        <Row xs={1} md={2} lg={3} className="g-4">
                            {currentCompany?.status === 'active' && (
                                <>
                                    <Col className="w-100">
                                        <ServiceProvidersComponent />
                                    </Col>
                                    <Col className="w-100">
                                        <UsersComponent />
                                    </Col>
                                    {/* <Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <AuthenticatorsComponent />
                            </Col> */}

                                    <Col className="w-100">
                                        <LocationsComponent />
                                    </Col>
                                </>
                            )}
                            {currentCompany?.status === 'pending' && (
                                <Col className="w-100">
                                    <DomainVerficationComponent />
                                </Col>
                            )}
                            {currentCompany.status === 'active' && (
                                <Col className="w-100">
                                    <StripeElements />
                                </Col>
                            )}
                        </Row>
                    </Container>
                </>
            )}
        </>
    );
}

export default AdminDashboard;
